
export const domains = () => {
  let parts = window.location.hostname.split(".");
  let _subdomain = "";
  let domain = window.location.hostname;
  if (parts.length === 3 || (parts.length === 2 && parts.at(-1) === "localhost")) {
    _subdomain = parts[0];
    parts.splice(0, 1);
    domain = parts.join(".");
  }

  return [domain, _subdomain];
}

export const url = (uri: string, subdomain:string = "unify"): string => {
  if (uri.charAt(0) !== "/")
    uri = "/" + uri;

  let result = uri;

  let currentSubdomain;
  let domain;
  [domain, currentSubdomain] = domains();

  if (currentSubdomain !== subdomain)
  {
    result = `${window.location.protocol}//`;
    if (subdomain)
      result += `${subdomain}.`;
    result += `${domain}`;
    if (window.location.port)
      result += `:${window.location.port}`;
    result += uri;
  }

  return result;
}