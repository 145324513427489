import { COLORS } from "UI/colors";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { url } from "../../Functions/url";

type MenuDownProps = {
  allUserDetails: any;
  ToggleDown: () => void;
  handleLearn: () => void;
};

const MenuDown = ({ allUserDetails, ToggleDown, handleLearn }: MenuDownProps) => {
  const [show, setShow] = useState({
    showWork: false,
    showJobs: false,
    showReports: false,
  });

  return (
    <div className="container mobile-nav-container">
      {allUserDetails?.active_role === "freelancer" ||
        allUserDetails?.active_role === "agency" ? (
        <>
          {allUserDetails?.active_role === "agency" ? null : (
            <MenuItemSingle className="w-100 no-border">
              <div onClick={() => handleLearn()} className="learnNavbar">
                <div>
                  Learn
                </div>
              </div>
            </MenuItemSingle>
          )}
          <MenuItemWrapper isShow={show.showWork}>
            <div className="item-main" onClick={() => setShow({
              showWork: !show.showWork,
              showJobs: false,
              showReports: false,
            })}> <div> Find Work </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showWork && <>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/project-search">Find Work</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/project-search/saved">Saved Jobs</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/all-proposals">Proposals</Link>
              </div>
            </>}
          </MenuItemWrapper>
          <MenuItemWrapper isShow={show.showJobs}>
            <div className="item-main" onClick={() => setShow({
              showWork: false,
              showJobs: !show.showJobs,
              showReports: false,
            })}> <div> Jobs </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showJobs && <> <div className="navabr-item" onClick={() => ToggleDown()}>
              <Link to="/freelancer/all-contracts">All Contracts</Link>
            </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/work-diary">Work Diary</Link>
              </div>
              <div className="navabr-item">
                <Link to="/freelancer/time-sheets">Time Sheets</Link>
              </div>
            </>}
          </MenuItemWrapper>
          <MenuItemWrapper isShow={show.showReports}>
            <div className="item-main" onClick={() => setShow({
              showWork: false,
              showJobs: false,
              showReports: !show.showReports,
            })}> <div> Reports </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showReports && <>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/overview">Overview</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/transaction-history">
                  Transaction History
                </Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/freelancer/unicoin-history">Unicoin History</Link>
              </div> </>}
          </MenuItemWrapper>
          <MenuItemSingle className="w-100 no-border">
            <a
              href={`https://support.useunify.com/?type=unify_support&token=${localStorage.getItem(
                "unify_token"
              )}`}
            >
              <div
                onClick={() => ToggleDown()}
              >
                Help Support
                <i className="bi bi-question-lg font-size-20px"></i>
              </div>
            </a>
          </MenuItemSingle>
          {!localStorage.getItem("unify_token") && (
            <>
              <div className="d-flex justify-content-between smbtn_logsignup">
                  <Link to="/login">
                  <button>Log In</button>
                </Link>
                <Link to="/signup/learn-and-earn">
                  <button className="active_mmnuckwu">Signup now</button>
                </Link>
              </div>
            </>
          )}
        </>
      ) : allUserDetails?.active_role === "client" ? (
        <>
          {allUserDetails?.active_role === "agency" ? null : (
            <MenuItemSingle className="w-100 no-border">
              <a
                href={url("learn")}
                target="_blank" rel="noreferrer"
              >
                <div >
                  Learn
                </div>
              </a>
            </MenuItemSingle>
          )}
          <MenuItemWrapper isShow={show.showJobs}>
            <div className="item-main" onClick={() => setShow({
              showWork: false,
              showJobs: !show.showJobs,
              showReports: false,
            })}> <div> Jobs </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showJobs && <>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/post-your-job">Post A Job</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/dashboard">Jobs</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/all-contracts">All Contracts</Link>
              </div>
              {/*<div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/time-sheets">Time sheets</Link>
              </div>*/}
            </>}
          </MenuItemWrapper>
          <MenuItemWrapper isShow={show.showWork}>
            <div className="item-main" onClick={() => setShow({
              showWork: !show.showWork,
              showJobs: false,
              showReports: false,
            })}> <div> Talent </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showWork && <>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/talent-discover">Discover</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/search">Find Talent</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/talent-recently-viewed">Recently Viewed</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/talent-saved">Saved talent</Link>
              </div>
            </>}
          </MenuItemWrapper>
          <MenuItemWrapper isShow={show.showReports}>
            <div className="item-main" onClick={() => setShow({
              showWork: false,
              showJobs: false,
              showReports: !show.showReports,
            })}> <div> Talent </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show.showReports && <>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/overview">Overview</Link>
              </div>
              <div className="navabr-item" onClick={() => ToggleDown()}>
                <Link to="/transaction-history">Transaction History</Link>
              </div>
            </>}
          </MenuItemWrapper>
          <MenuItemSingle className="w-100 no-border">
            <a
              href={`https://support.useunify.com/?type=unify_support&token=${localStorage.getItem(
                "unify_token"
              )}`}
            >
              <div
                onClick={() => ToggleDown()}
              >
                Help Support
                <i className="bi bi-question-lg font-size-20px"></i>
              </div>
            </a>
          </MenuItemSingle>
          {!localStorage.getItem("unify_token") && (
            <>
              <div className="d-flex justify-content-between smbtn_logsignup">
                  <Link to="/login">
                  <button onClick={() => ToggleDown()}>Log In</button>
                </Link>
                <Link to="/signup/hire-for-project">
                  <button
                    onClick={() => ToggleDown()}
                    className="active_mmnuckwu"
                  >
                    Signup now
                  </button>
                </Link>
              </div>
            </>
          )}
        </>
      ) : null
      }
    </div >
  );
};

export default MenuDown;

export const MenuItemWrapper = styled.div<{ isShow: boolean }>`
  width: 100%;
  color: ${COLORS.whitePrimary};
  margin-top: 15px;

  .navabr-item {
    width: 100%;
    color: ${COLORS.whitePrimary};
    margin-top: 12px;
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      color: ${COLORS.violetPrimary};
    }
  }

  .item-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      color: ${COLORS.whitePrimary};
      transform: ${({ isShow }) => isShow ? "rotate(180deg)" : "rotate(0deg)"};
      margin-right: 2px;
    }

    &:hover {
      color: ${COLORS.violetPrimary};
    }
  }
`;

export const MenuItemSingle = styled.div`
  width: 100%;
  color: ${COLORS.whitePrimary} !important;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    color: ${COLORS.violetPrimary};
  }

  a {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;