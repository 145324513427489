import {
  CHANGE_SUBSCRIPTION,
  SET_CANCEL_SUBSCRIPTION,
  SET_SUBSCRIPTION_LIST,
} from "../Types";

const initialState = {
  subscriptionList: [],
  cancelSubscription: {},
  changeSubscription: {},
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: action.payload,
      };
    case SET_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: action.payload,
      };
    case CHANGE_SUBSCRIPTION:
      return {
        ...state,
        changeSubscription: action.payload,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
