import React from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { domains, url } from "../Functions/url";

const AuthRouteGroup = () => {
  const auth = localStorage.getItem("unify_token");

  let [searchParams] = useSearchParams();

  if (auth) {
    //if (searchParams.get("target") === "lms") {
    //  window.location.href = url(`/?token=${auth}`, "lms");
    //}

    const userDetails = JSON.parse(localStorage.getItem("unify_user") || "");
    if (userDetails?.is_profile_complete === true)
      return <Navigate to="/dashboard"/>;
    else if (userDetails?.user_type === "freelancer") {
      if (userDetails.is_verified === "pending") {
        return <Navigate to="/verify-signup" />
      } else {
        return <Navigate to="/freelancer/profile-intro/question-one" />
      }
    }
    else
      return <Navigate to="/business-size"/>
  }

  return <Outlet/>;
};

export default AuthRouteGroup;