import { AgencyAction, AGENCY_ACTION_TYPES } from "../Types/agencyTypes";

type AgencyState = {
  getAgencyProfile: {
    agency_id: number;
    user_id: number;
    name: string;
    profile_image: string;
    address: string;
    category_id: number;
    category_name: string;
    title: string;
    overview: string;
    size: string;
    client_focus: string;
    founded_year: string;
    online_status: string;
    member_since: string;
    balance: number;
    stripe_account: string;
    stripe_country: string;
    stripe_connected: boolean;
    bank_added: boolean;
    stripe_verified: boolean;
    customer_account: string;
    teams: any[];
    complete_jobs: any;
    in_progress: any;
    withdrawal_requested: boolean;
    total_job: number;
    subscription_id: number;
  };
  agencyTitleEdit: any;
  agencyPortfolioEdit: any;
  agencyPortfolioDeleted: any;
  agencySkillEdit: any;
  agencyLanguageEdit: any;
  agencySizeEdit: any;
  editAgencyFoundedYear: any;
  editAgencyFocus: any;
  editAgencyServices: any;
  editAgencyDetails: any;
  agencyTeamAdded: any;
  agencyTeamList: any;
  agencyTeamMemberAdded: any;
  agencyTeamMemberDeleted: any;
  agencyTeamMembersList: any;
  agencySubTeamDeleted: any;
  getAgencyWorkHistory: {
    status: boolean;
    message: string;
    data: any;
    meta: any;
    link: any;
  } | any;
  portfolioError: any;
};

const initialState: AgencyState  = {
  getAgencyProfile: {
    agency_id: 0,
    user_id: 0,
    name: '',
    profile_image: '',
    address: '',
    category_id: 0,
    category_name: '',
    title: '',
    overview: '',
    size: '',
    client_focus: '',
    founded_year: '',
    online_status: '',
    member_since: '',
    balance: 0,
    stripe_account: '',
    stripe_country: '',
    stripe_connected: false,
    bank_added: false,
    stripe_verified: false,
    customer_account: '',
    teams: [],
    complete_jobs: [],
    in_progress: [],
    withdrawal_requested: false,
    total_job: 0,
    subscription_id: 0,
  },
  agencyTitleEdit: {},
  agencyPortfolioEdit: {},
  agencyPortfolioDeleted: {},
  agencySkillEdit: {},
  agencyLanguageEdit: {},
  agencySizeEdit: {},
  editAgencyFoundedYear: {},
  editAgencyFocus: {},
  editAgencyServices: {},
  editAgencyDetails: {},
  agencyTeamAdded: {},
  agencyTeamList: [],
  agencyTeamMemberAdded: {},
  agencyTeamMemberDeleted: {},
  agencyTeamMembersList: [],
  agencySubTeamDeleted: {},
  getAgencyWorkHistory: {},
  portfolioError: {},
};

const agencyReducer = (state = initialState, action: AgencyAction): AgencyState => {
  switch (action.type) {
    case AGENCY_ACTION_TYPES.SET_AGENCY_PROFILE:
      return {
        ...state,
        getAgencyProfile: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_TITLE_AND_OVERVIEW:
      return {
        ...state,
        agencyTitleEdit: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_PORTFOLIO:
      return {
        ...state,
        agencyPortfolioEdit: action.payload,
      };
    case AGENCY_ACTION_TYPES.DELETE_AGENCY_PORTFOLIO:
      return {
        ...state,
        agencyPortfolioDeleted: action.payload,
      };
    case AGENCY_ACTION_TYPES.SET_EDIT_AGENCY_SKILLS:
      return {
        ...state,
        agencySkillEdit: action.payload,
      };
    case AGENCY_ACTION_TYPES.SET_EDIT_AGENCY_LANGUAGE:
      return {
        ...state,
        agencyLanguageEdit: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_SIZE:
      return {
        ...state,
        agencySizeEdit: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_FOUNDED_YEAR:
      return {
        ...state,
        editAgencyFoundedYear: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_FOCUS:
      return {
        ...state,
        editAgencyFocus: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_SERVICES:
      return {
        ...state,
        editAgencyServices: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_DETAILS:
      return {
        ...state,
        editAgencyDetails: action.payload,
      };
    case AGENCY_ACTION_TYPES.EDIT_AGENCY_TEAM_DETAILS:
      return {
        ...state,
        agencyTeamAdded: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_TEAM_LIST:
      return {
        ...state,
        agencyTeamList: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBER_ADD:
      return {
        ...state,
        agencyTeamMemberAdded: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBER_DELETE:
      return {
        ...state,
        agencyTeamMemberDeleted: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBERS_LIST:
      return {
        ...state,
        agencyTeamMembersList: action.payload,
      };
    case AGENCY_ACTION_TYPES.AGENCY_SUB_TEAM_DELETE:
      return {
        ...state,
        agencySubTeamDeleted: action.payload,
      };
    case AGENCY_ACTION_TYPES.SET_AGENCY_WORK_HISTORY:
      return {
        ...state,
        getAgencyWorkHistory: action.payload,
      };
    case AGENCY_ACTION_TYPES.SET_PORTFOLIO_ERROR:
      return {
        ...state,
        portfolioError: action.payload,
      };

    default:
      return state;
  }
};

export default agencyReducer;
