import React from "react";
import { useNavigate } from "react-router-dom";
import "./popup.css";

const SuccessPopup = ({ Popup, message, afterSuccess, freelancerData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg_wrapper_popup_new">
        <div className="popup_box_bpn success_popup pb-4 rounded">
          <div className="popup_header pb-0">
            <div className="p_header_hding"></div>
          </div>
          <div className="success_heading ">
            <svg
              viewBox="0 0 256 256"
              width="100"
              height="100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect fill="none" height="256" width="256"/>
              <path
                d="M40,114.7V56a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7Z"
                fill="none"
                stroke="#6d2ef1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="8"
              />
              <polyline
                fill="none"
                points="172 104 113.3 160 84 132"
                stroke="#ff88fe"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="8"
              />
            </svg>
          </div>
          <div className="sucess_message">
            <div>{message}</div>
          </div>

          <div className="success_popup_btn ">
            {afterSuccess === "otpVerified" ? (
              <button
                onClick={() => {
                  navigate("/reset-password");
                  Popup();
                }}
              >
                OK
              </button>
            ) : afterSuccess === "verify-signup" ? (
              <button
                onClick={() => {
                  navigate("/login");
                  localStorage.removeItem("unify_email");
                  Popup();
                  window.location.reload();
                }}
              >
                Close
              </button>
            ) : afterSuccess === "passwordUpdated" ? (
              <button
                onClick={() => {
                  navigate("/login");
                  Popup();
                }}
              >
                Close
              </button>
            ) : afterSuccess === "stripeAccountCreated" ? (
              <button
                onClick={() => {
                  Popup();
                }}
              >
                Close
              </button>
            ) : afterSuccess === "successPayment" ? (
              <button
                onClick={() => {
                  navigate("/settings/billing-payment");
                }}
              >
                Close
              </button>
            ) : afterSuccess === "offerSent" ? (
              <button
                onClick={() => {
                  localStorage.removeItem("hire_freelancer");
                  localStorage.removeItem("freelancerData");
                  if (freelancerData.project_id) {
                    navigate(
                      `/view-job/${freelancerData.project_id}/job-details`
                    );
                  } else {
                    navigate(`/dashboard`);
                  }
                  Popup();
                }}
              >
                Close
              </button>
            ) : afterSuccess ? (
              <button onClick={() => afterSuccess()}>Close</button>
            ) : (
              <button onClick={() => Popup()}>Close</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPopup;
