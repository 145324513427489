import { LoadingType } from "Redux/Reducers/authReducer";

export enum JOB_ACTION_TYPES {
  JOBS_LIST = 'JOB::JOBS_LIST',
  SAVED_JOBS_LIST = 'JOB::SAVED_JOBS_LIST',
  MATCHED_JOBS_LIST = 'JOB::MATCHED_JOBS_LIST',
  SAVE_JOB_POST = 'JOB::SAVE_JOB_POST',
  UNSAVE_JOB_POST = 'JOB::UNSAVE_JOB_POST',
  JOB_POST_DETAILS = 'JOB::JOB_POST_DETAILS',
  SEND_JOB_PROPOSAL = 'JOB::SEND_JOB_PROPOSAL',
  SEND_PROPOSAL_DATA = 'JOB::SEND_PROPOSAL_DATA',
  DISLIKE_POST_REASONS = 'JOB::DISLIKE_POST_REASONS',
  ON_DISLIKE_JOB_POST = 'JOB::ON_DISLIKE_JOB_POST',
  SET_ALL_CLIENT_POSTING = 'JOB::SET_ALL_CLIENT_POSTING',
  SET_ALL_CLIENT_DRAFT_POSTING = 'JOB::SET_ALL_CLIENT_DRAFT_POSTING',
  SET_POST_YOUR_JOB_NOW = 'JOB::SET_POST_YOUR_JOB_NOW',
  SET_ALL_PROPOSALS = 'JOB::SET_ALL_PROPOSALS',
  SET_ALL_CONTRACTS = 'JOB::SET_ALL_CONTRACTS',
  SET_CLOSE_JOB_REASON_LIST = 'JOB::SET_CLOSE_JOB_REASON_LIST',
  SET_UPDATE_JOB_POST = 'JOB::SET_UPDATE_JOB_POST',
  SET_REMOVE_JOB_POST = 'JOB::SET_REMOVE_JOB_POST',
  SET_REMOVE_DRAFTED_JOB_POST = 'JOB::SET_REMOVE_DRAFTED_JOB_POST',
  SET_SINGLE_PROPOSAL_DETAILS = 'JOB::SET_SINGLE_PROPOSAL_DETAILS',
  DECLINE_REASONS_LIST = 'JOB::DECLINE_REASONS_LIST',
  PROPOSAL_TERMS_CHANGE = 'JOB::PROPOSAL_TERMS_CHANGE',
  SINGLE_CONTRACT_DATA = 'JOB::SINGLE_CONTRACT_DATA',
  ADD_TIMESHEET_TIME = 'JOB::ADD_TIMESHEET_TIME',
  GET_TIMESHEET_DATA = 'JOB::GET_TIMESHEET_DATA',
  ON_SUBMIT_WORK_FOR_PAYMENT = 'JOB::ON_SUBMIT_WORK_FOR_PAYMENT',
  ON_FREELANCER_REQUEST_MILESTONE = 'JOB::ON_FREELANCER_REQUEST_MILESTONE',
  GET_OVERVIEW_REPORT = 'JOB::GET_OVERVIEW_REPORT',
  ON_END_CONTRACT = 'JOB::ON_END_CONTRACT',
  ON_APPROVE_TIMESHEET = 'JOB::ON_APPROVE_TIMESHEET',
  ON_SEND_FEEDBACK = 'JOB::ON_SEND_FEEDBACK',
  REFUND_OR_DISPUTE = 'JOB::REFUND_OR_DISPUTE',
  FREELANCER_CLIENT_LIST = 'JOB::FREELANCER_CLIENT_LIST',
  INVOICE_DATA = 'JOB::INVOICE_DATA',
  SAVING_FILTERS = 'JOB::SAVING_FILTERS',
  UNICOINE_HISTORY = 'JOB::UNICOINE_HISTORY',
  SET_ALL_HOURLY_CONTRACTS = 'JOB::SET_ALL_HOURLY_CONTRACTS',
  GET_WORK_DIARY = 'JOB::GET_WORK_DIARY',
  SET_LOG_TIME_MANUAL_STATUS = 'JOB::SET_LOG_TIME_MANUAL_STATUS',
  SET_GET_WORK_DIARY_STATUS = 'JOB::SET_GET_WORK_DIARY_STATUS',
  SET_PROJECT_START_STATUS = 'JOB::SET_PROJECT_START_STATUS',
  SET_TIMER_STATUS = 'JOB::SET_TIMER_STATUS',
  GET_TIME_SHEET_DATA = 'JOB::GET_TIME_SHEET_DATA',
  SET_TIME_SHEET_DATA_STATUS = 'JOB::SET_TIME_SHEET_DATA_STATUS',
  GET_TIME_SHEET_DATA_HOURS = 'JOB::GET_TIME_SHEET_DATA_HOURS',
  GET_SINGLE_CONTRACT_TIME_SHEET_DATA = 'JOB::GET_SINGLE_CONTRACT_TIME_SHEET_DATA',
  SET_ALL_CLIENT_POSTING_STATUS = 'JOB::SET_ALL_CLIENT_POSTING_STATUS',
  SET_OVERVIEW_REPORT_STATUS = 'JOB::SET_OVERVIEW_REPORT_STATUS',
  GET_OVERVIEW_REPORT_COMPLETED = 'JOB::GET_OVERVIEW_REPORT_COMPLETED',
  GET_OVERVIEW_REPORT_REVIEW = 'JOB::GET_OVERVIEW_REPORT_REVIEW',
  GET_OVERVIEW_REPORT_AWAITING = 'JOB::GET_OVERVIEW_REPORT_AWAITING',
}

export type JobAction =
  | { type: JOB_ACTION_TYPES.JOBS_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.SAVED_JOBS_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.MATCHED_JOBS_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.SAVE_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.UNSAVE_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.JOB_POST_DETAILS; payload: any }
  | { type: JOB_ACTION_TYPES.SEND_JOB_PROPOSAL; payload: any }
  | { type: JOB_ACTION_TYPES.SEND_PROPOSAL_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.DISLIKE_POST_REASONS; payload: any }
  | { type: JOB_ACTION_TYPES.ON_DISLIKE_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_CLIENT_POSTING; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_CLIENT_DRAFT_POSTING; payload: any }
  | { type: JOB_ACTION_TYPES.SET_POST_YOUR_JOB_NOW; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_PROPOSALS; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_CONTRACTS; payload: any }
  | { type: JOB_ACTION_TYPES.SET_CLOSE_JOB_REASON_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.SET_UPDATE_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.SET_REMOVE_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.SET_REMOVE_DRAFTED_JOB_POST; payload: any }
  | { type: JOB_ACTION_TYPES.SET_SINGLE_PROPOSAL_DETAILS; payload: any }
  | { type: JOB_ACTION_TYPES.DECLINE_REASONS_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.PROPOSAL_TERMS_CHANGE; payload: any }
  | { type: JOB_ACTION_TYPES.SINGLE_CONTRACT_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.ADD_TIMESHEET_TIME; payload: any }
  | { type: JOB_ACTION_TYPES.GET_TIMESHEET_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.ON_SUBMIT_WORK_FOR_PAYMENT; payload: any }
  | { type: JOB_ACTION_TYPES.ON_FREELANCER_REQUEST_MILESTONE; payload: any }
  | { type: JOB_ACTION_TYPES.GET_OVERVIEW_REPORT; payload: any }
  | { type: JOB_ACTION_TYPES.ON_END_CONTRACT; payload: any }
  | { type: JOB_ACTION_TYPES.ON_APPROVE_TIMESHEET; payload: any }
  | { type: JOB_ACTION_TYPES.ON_SEND_FEEDBACK; payload: any }
  | { type: JOB_ACTION_TYPES.REFUND_OR_DISPUTE; payload: any }
  | { type: JOB_ACTION_TYPES.FREELANCER_CLIENT_LIST; payload: any }
  | { type: JOB_ACTION_TYPES.INVOICE_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.SAVING_FILTERS; payload: any }
  | { type: JOB_ACTION_TYPES.UNICOINE_HISTORY; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_HOURLY_CONTRACTS; payload: any }
  | { type: JOB_ACTION_TYPES.GET_WORK_DIARY; payload: any }
  | { type: JOB_ACTION_TYPES.SET_LOG_TIME_MANUAL_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.SET_GET_WORK_DIARY_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.SET_PROJECT_START_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.SET_TIMER_STATUS; payload: any }
  | { type: JOB_ACTION_TYPES.GET_TIME_SHEET_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.SET_TIME_SHEET_DATA_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.GET_TIME_SHEET_DATA_HOURS; payload: any }
  | { type: JOB_ACTION_TYPES.GET_SINGLE_CONTRACT_TIME_SHEET_DATA; payload: any }
  | { type: JOB_ACTION_TYPES.SET_ALL_CLIENT_POSTING_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.SET_OVERVIEW_REPORT_STATUS; payload: LoadingType }
  | { type: JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_COMPLETED; payload: any }
  | { type: JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_REVIEW; payload: any }
  | { type: JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_AWAITING; payload: any }