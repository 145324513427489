import { Axios } from "../Axios";
import { Dispatch } from "redux";
import { LoadingType } from "Redux/Reducers/authReducer";
import { NOTIFICATIONS_ACTION_TYPES } from "Redux/Types/notificationsTypes";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("unify_token")}`,
  },
};

export const notificationStatus = (status: LoadingType) => {
  return {
    type: NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_STATUS,
    payload: status,
  };
}

export const getSystemNotification = () => async (dispatch: Dispatch) => {
  await Axios.get(`/system-notification`, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: NOTIFICATIONS_ACTION_TYPES.SET_SYSTEM_NOTIFICATION,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteSystemNotification =
  (data: {
    notification_id: string;
  } | {}, afterSeen?: any ) => async (dispatch: Dispatch) => {
    dispatch(notificationStatus('loading'));
    await Axios.post(`/delete-notification`, data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.DELETE_SYSTEM_NOTIFICATION,
            payload: res.data,
          });
        }
        afterSeen && afterSeen();
        dispatch(notificationStatus('idle'));
      })
      .catch((err) => {
        dispatch(notificationStatus(err?.response?.data?.message));
      });
  };

export const seeSystemNotification =
  (data: any, afterSeen: any) => async (dispatch: Dispatch) => {
    dispatch(notificationStatus('loading'));
    await Axios.post(`/seen-notification`, data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.SEE_NOTIFICATION,
            payload: res.data,
          });
          if (afterSeen) {
            afterSeen();
          }
        }
        dispatch(notificationStatus('idle'));
      })
      .catch((err) => {
        dispatch(notificationStatus(err?.response?.data?.message));
      });
  };

export const freelancerNotificationCheck =
  (data: any) => async (dispatch: Dispatch) => {
    dispatch(notificationStatus('loading'));
    await Axios.post(`/freelancer-notification-check`, data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION_SETTING,
            payload: res.data,
          });
        }
        dispatch(notificationStatus('idle'));
      })
      .catch((err) => {
        dispatch(notificationStatus(err?.response?.data?.message));
      });
  };

export const getNotiSetting = () => async (dispatch: Dispatch) => {
  dispatch(notificationStatus('loading'));
  await Axios.get(`/freelancer-notification-settings`, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: NOTIFICATIONS_ACTION_TYPES.SET_NOTIFICATION_SETTING,
          payload: res.data.data,
        });
      }
      dispatch(notificationStatus('idle'));
    })
    .catch((err) => {
      dispatch(notificationStatus(err?.response?.data?.message));    
    });
};

export const clientNotificationCheck = (data: any) => async (dispatch: Dispatch) => {
  dispatch(notificationStatus('loading'));
  await Axios.post(`/client-notification-check`, data, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: NOTIFICATIONS_ACTION_TYPES.UPDATE_CLIENT_NOTIFICATION_SETTING,
          payload: res.data,
        });
      }
      dispatch(notificationStatus('idle'));
    })
    .catch((err) => {
      dispatch(notificationStatus(err?.response?.data?.message));
    });
};

export const getClientNotiSetting = () => async (dispatch: Dispatch) => {
  dispatch(notificationStatus('loading'));
  await Axios.get(`/client-notification-settings`, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: NOTIFICATIONS_ACTION_TYPES.SET_CLIENT_NOTIFICATION_SETTING,
          payload: res.data.data,
        });
      }
      dispatch(notificationStatus('idle'));
    })
    .catch((err) => {
      dispatch(notificationStatus(err?.response?.data?.message));
    });
};
