import { IDENTITY_ACTION_TYPES } from "Redux/Types/identityTypes";
import { LoadingType } from "./authReducer";


export type VerificationType = {
  status: 'none' | 'pending' | 'approved' | 'rejected',
  approved_at: string,
}


export type IdentityVerificationInfo = {
  document: VerificationType,
  visual: VerificationType
}

type IdentityReducerType = {
  hasIdentityVerification: boolean,
  hasDocumentVerification: boolean,
  hasVisuallyVerified: boolean,
  identityVerificationInfo: IdentityVerificationInfo | null,
  // loaders
  identityVerificationStatus: LoadingType,
  documentVerificationStatus: LoadingType,
  visualVerificationStatus: LoadingType,
  checkIdentityVerificationInfoStatus: LoadingType,
  sendIdentityVerificationPhotoStatus: LoadingType,
  sendIdentityVerificationUserPhotoStatus: LoadingType,
}

const initialState: IdentityReducerType = {
  hasIdentityVerification: false,
  hasDocumentVerification: false,
  hasVisuallyVerified: false,
  identityVerificationInfo: null,
  // loaders
  identityVerificationStatus: 'idle',
  documentVerificationStatus: 'idle',
  visualVerificationStatus: 'idle',
  checkIdentityVerificationInfoStatus: 'idle',
  sendIdentityVerificationPhotoStatus: 'idle',
  sendIdentityVerificationUserPhotoStatus: 'idle',
}


const identityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case IDENTITY_ACTION_TYPES.SET_HAS_IDENTITY_VERIFICATION: {
      return {
        ...state,
        hasIdentityVerification: action.payload,
      };
    }

    case IDENTITY_ACTION_TYPES.SET_HAS_DOCUMENT_VERIFICATION: {
      return {
        ...state,
        hasDocumentVerification: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_VISUAL_VERIFICATION: {
      return {
        ...state,
        hasVisuallyVerified: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_IDENTITY_VERIFICATION_STATUS: {
      return {
        ...state,
        identityVerificationStatus: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_DOCUMENT_VERIFICATION_STATUS: {
      return {
        ...state,
        documentVerificationStatus: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_VISUAL_VERIFICATION_STATUS: {
      return {
        ...state,
        visualVerificationStatus: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_IDENTITY_VERIFICATION_INFO: {
      return {
        ...state,
        identityVerificationInfo: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_CHECK_IDENTITY_VERIFICATION_INFO_STATUS: {
      return {
        ...state,
        checkIdentityVerificationInfoStatus: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_SEND_IDENTITY_VERIFICATION_PHOTO_STATUS: {
      return {
        ...state,
        sendIdentityVerificationPhotoStatus: action.payload
      };
    }

    case IDENTITY_ACTION_TYPES.SET_SEND_IDENTITY_VERIFICATION_USER_PHOTO_STATUS: {
      return {
        ...state,
        sendIdentityVerificationUserPhotoStatus: action.payload
      };
    }

    default:
      return state;
  }
};

export default identityReducer;