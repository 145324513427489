import { PROFILE_ACTION_TYPES } from "../Types/profileTypes";
import ErrorPopup from "../../Freelancer/Components/Popups/ErrorPopup";
import SuccessPopup from "../../Freelancer/Components/Popups/SuccessPopup";
import { Axios } from "../Axios";
import {
  SET_ADD_EXPERIENCE,
  SET_CLIENT_INFO_DETAILS,
  SET_CLOSE_ACCOUNT_REASON_LIST,
  SET_DELETE_EXPERIENCE,
  SET_EDIT_CLIENT_INFO,
  SET_FREELANCER_PROFILE,
  SET_FREELANCER_SKILLS,
  SET_INDUSTRIES_LIST,
  SET_TIMEZONE_LIST,
  SET_DEGREE_LIST,
  SET_DELETE_EDUCATION,
  SET_EDIT_FREELANCER_INFO,
  SET_EDIT_FREELANCER_LOCATION,
  SET_EDIT_LANGUAGE,
  SET_LANGUAGE_LIST,
  SET_HWP_LIST,
  SET_HOURS_PER_WEEK,
  SET_EDIT_SKILLS,
  SET_EDIT_CERTIFICATE,
  SET_DELETE_CERTIFICATE,
  SET_PROFILE_IMG_CHANGE,
  SET_EDIT_EDUCATION,
  SET_EDIT_DESIGNATION,
  SET_EDIT_PORTFOLIO,
  SET_EDIT_VIDEO,
  SET_VISIBILITY,
  SET_EXPERIENCE_LEVEL,
  SET_DELETE_PORTFOLIO,
  SET_ADDITIONAL_ACCOUNT,
  SET_SUBMIT_VERIF_DOCS,
  REQUEST_TESTIMONIAL,
  GET_TESTIMONIAL,
  SET_CATEGORY_LIST,
  SET_PORTFOLIO_ERROR,
  ADD_CATEGORY,
  // HOURLY_PRICE,
  ON_CREATE_TEAM,
  TEAM_LIST,
  ERROR_REQUEST_TESTIMONIAL,
  SET_CLIENT_EDIT_ERROR,
} from "../Types";
import { onUserRoleChange } from "./authActions";

//let userDetails = JSON.parse(localStorage.getItem("unify_user"));

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("unify_token")}`,
  },
};

// FREELANCER

export const getFreelancerProfile = (setLoading) => async (dispatch) => {
  await Axios.get(`/get-freelancer-profile`, config)
    .then((res) => {
      dispatch({
        type: SET_FREELANCER_PROFILE,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getFreelancerSkills = (data, setLoading) => async (dispatch) => {
  await Axios.post(`/skill-list`, data)
    .then((res) => {
      dispatch({
        type: SET_FREELANCER_SKILLS,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onAddEmployment =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post(`/edit-employment-info`, data, config)
        .then((res) => {
          dispatch({
            type: SET_ADD_EXPERIENCE,
            payload: res.data,
          });
          popup();
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch(() => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onDeleteEmployment =
  (data, setConfirmPopupOpen, setLoading) => async (dispatch) => {
    await Axios.post(`/delete-employment-info`, data, config)
      .then((res) => {
        dispatch({
          type: SET_DELETE_EXPERIENCE,
          payload: res.data,
        });
        setConfirmPopupOpen(false);
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

// CLIENT
export const getClientInfoDetails = (setLoading) => async (dispatch) => {
  await Axios.get(`/get-client-info`, config)
    .then((res) => {
      if (setLoading) {
        setLoading(false);
      }
      dispatch({
        type: SET_CLIENT_INFO_DETAILS,
        payload: res.data.data.client,
      });
    })
    .catch(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getIndustriesList = (data) => async (dispatch) => {
  await Axios.get(`/industries-list`)
    .then((res) => {
      dispatch({
        type: SET_INDUSTRIES_LIST,
        payload: res.data.data,
      });
    })
    .catch(() => {
    });
};

export const getTimezoneList = (setLoading) => async (dispatch) => {
  await Axios.get(`/timezone-list`)
    .then((res) => {
      dispatch({
        type: SET_TIMEZONE_LIST,
        payload: res.data.data,
      });
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const addEditClientInfo =
  (data, setOpen, setErrorPopup, setLoading) => async (dispatch) => {
    await Axios.post("/edit-client-info", data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: SET_EDIT_CLIENT_INFO,
            payload: res.data,
          });
          setOpen(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (setLoading) {
          setLoading(false);
        }
        dispatch({
          type: SET_CLIENT_EDIT_ERROR,
          payload: err?.response?.data?.message,
        });

        setErrorPopup(
          <ErrorPopup
            popup={setErrorPopup}
            message={err?.response?.data?.message}
          />
        );
      });
  };

export const onCreateTeam = (data, onCloseModal) => async (dispatch) => {
  await Axios.post("/create-team", data, config)
    .then((res) => {
      dispatch({
        type: ON_CREATE_TEAM,
        payload: res.data,
      });
      onCloseModal(false);
    })
    .catch(() => {
    });
};
export const getTeamList = () => async (dispatch) => {
  await Axios.get("/team-list", config)
    .then((res) => {
      dispatch({
        type: TEAM_LIST,
        payload: res.data.data,
      });
    })
    .catch((err) => {
    });
};

export const closeAccountReasonList = (setLoading) => async (dispatch) => {
  await Axios.get("/close-account-reason-list")
    .then((res) => {
      dispatch({
        type: SET_CLOSE_ACCOUNT_REASON_LIST,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onCloseAccount =
  (data, popup, successPopup, setSuccessPopup, afterSuccess, setLoading) =>
    async (dispatch) => {
      await Axios.post("/close-account", data, config)
        .then((res) => {
          popup();
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Account close successfully"
              afterSuccess={afterSuccess}
            />
          );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const getFreelancerDegreeList = (setLoading) => async (dispatch) => {
  await Axios.get("/degree-list")
    .then((res) => {
      dispatch({
        type: SET_DEGREE_LIST,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onAddEducation =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-education-info", data, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_EDUCATION,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          popup();
          // setSuccessPopup(
          //   <SuccessPopup
          //     Popup={() => setSuccessPopup(!successPopup)}
          //     message="Education saved successfully"
          //   />
          // );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onDeleteEducation =
  (data, setConfirmPopupOpen, setLoading) => async (dispatch) => {
    await Axios.post("/delete-education-info", data, config)
      .then((res) => {
        dispatch({
          type: SET_DELETE_EDUCATION,
          payload: res.data,
        });
        setConfirmPopupOpen(false);
        if (setLoading) setLoading(false);
      })
      .catch((err) => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onEditContactInfo =
  (data, setEditAccount, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-contact-info", data, config)
        .then((res) => {
          setEditAccount(false);
          dispatch({
            type: SET_EDIT_FREELANCER_INFO,
            payload: res.data,
          });

          if (setLoading) {
            setLoading(false);
          }
          // setSuccessPopup(
          //   <SuccessPopup
          //     Popup={() => setSuccessPopup(!successPopup)}
          //     message="Details saved successfully"
          //   />
          // );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onEditLocationInfo =
  (
    data,
    setEditLocation,
    successPopup,
    setSuccessPopup,
    afterSuccess,
    navigate,
    setCurrentTab,
    setLoading
  ) =>
    async (dispatch) => {
      await Axios.post("/edit-location", data, config)
        .then((res) => {
          if (setEditLocation) {
            setEditLocation(false);
          }
          dispatch({
            type: SET_EDIT_FREELANCER_LOCATION,
            payload: res.data,
          });

          if (setLoading) {
            setLoading(false);
          }
          // setSuccessPopup(
          //   <SuccessPopup
          //     Popup={() => setSuccessPopup(!successPopup)}
          //     message="Details saved successfully"
          //     afterSuccess={afterSuccess}
          //   />
          // );
          if (setCurrentTab) {
            setCurrentTab("preview-profile");
          }
          if (navigate) {
            navigate(`/freelancer/profile-intro/preview-profile`);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onEditVideo =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-video", data, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_VIDEO,
            payload: res.data,
          });
          popup();
          if (setLoading) {
            setLoading(false);
          }
          // setSuccessPopup(
          //   <SuccessPopup
          //     Popup={() => setSuccessPopup(!successPopup)}
          //     message="Introduction video saved Successfully"
          //   />
          // );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onEditDesignation =
  (
    data,
    popup,
    successPopup,
    setSuccessPopup,
    afterSuccess = false,
    setCurrentTab,
    navigate,
    currentSteps,
    setCurrentSteps,
    setLoading
  ) =>
    async (dispatch) => {
      await Axios.post("/edit-designation-info", data, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_DESIGNATION,
            payload: res.data,
          });
          if (popup) {
            popup();
          } else {
            setCurrentTab("experience");
            setCurrentSteps({ ...currentSteps, experience: "experience" });
            navigate(`/freelancer/profile-intro/experience`);
          }
          if (setLoading) {
            setLoading(false);
          }

          setSuccessPopup();
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onEditPortfolio =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-portfolio-info", data, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_PORTFOLIO,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }

          popup();
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Portfolio saved successfully"
            />
          );
        })
        .catch((err) => {
          dispatch({
            type: SET_PORTFOLIO_ERROR,
            payload: err?.response?.data?.message,
          });
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onEditLanguage =
  (
    data,
    popup,
    successPopup,
    setSuccessPopup,
    afterSuccess,
    setCurrentTab,
    navigate,
    setLoading
  ) =>
    async (dispatch) => {
      await Axios.post("/edit-language", data, config)
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: SET_EDIT_LANGUAGE,
              payload: res.data,
            });
          }
          if (setLoading) {
            setLoading(false);
          }
          if (popup) {
            popup();
          } else {
            setCurrentTab("skills");
            navigate(`/freelancer/profile-intro/skills`);
          }
          // setSuccessPopup(
          //   <SuccessPopup
          //     Popup={() => setSuccessPopup(!successPopup)}
          //     message="Languages saved successfully"
          //     afterSuccess={afterSuccess}
          //   />
          // );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }

          console.log(err);
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const getLanguageList = (setLoading, setPopup) => async (dispatch) => {
  await Axios.get("/languages-list")
    .then((res) => {
      dispatch({
        type: SET_LANGUAGE_LIST,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      if (setPopup) {
        setPopup(
          <ErrorPopup
            popup={setPopup}
            message={err.response.data.error.message}
          />
        );
      }
    });
};

export const getHoursPerWeekList = (setLoading) => async (dispatch) => {
  await Axios.get("/hours-per-week")
    .then((res) => {
      dispatch({
        type: SET_HWP_LIST,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onEditHourPerWeek =
  (
    data,
    popup,
    successPopup,
    setSuccessPopup,
    afterSuccess,
    setCurrentTab,
    navigate,
    setLoading
  ) =>
    async (dispatch) => {
      await Axios.post("/edit-hours-per-week", data, config)
        .then((res) => {
          dispatch({
            type: SET_HOURS_PER_WEEK,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          if (popup) {
            popup();
          } else {
            setCurrentTab("publish-profile");
            navigate(`/freelancer/profile-intro/publish-profile`);
          }
          setSuccessPopup();
          // <SuccessPopup
          //   Popup={() => setSuccessPopup(!successPopup)}
          //   message="Hourly price saved successfully"
          //   afterSuccess={afterSuccess}
          // />
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onEditSkills =
  (
    data,
    popup,
    successPopup,
    setSuccessPopup,
    afterSuccess,
    setCurrentTab,
    navigate,
    setLoading
  ) =>
    async (dispatch) => {
      await Axios.post("/edit-skills-info", data, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_SKILLS,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          if (popup) {
            popup();
          } else {
            setCurrentTab("services-offer");
            navigate(`/freelancer/profile-intro/services-offer`);
          }
          setSuccessPopup();
          // <SuccessPopup
          //   Popup={() => setSuccessPopup(!successPopup)}
          //   message="Skills saved successfully"
          //   afterSuccess={afterSuccess}
          // />
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onEditCertificate =
  (formData, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-certificate-info", formData, config)
        .then((res) => {
          dispatch({
            type: SET_EDIT_CERTIFICATE,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          popup();
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Certificate saved successfully"
            />
          );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onDeleteCertificate =
  (data, setConfirmPopup) => async (dispatch) => {
    await Axios.post("/delete-certificate-info", data, config)
      .then((res) => {
        dispatch({
          type: SET_DELETE_CERTIFICATE,
          payload: res.data,
        });
        setConfirmPopup();
      })
      .catch(() => {
      });
  };

export const editNameInfo =
  (data, successPopup, setSuccessPopup, afterSuccess, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-name-info", data, config)
        .then((res) => {
          dispatch({
            type: SET_PROFILE_IMG_CHANGE,
            payload: res.data,
          });

          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup();
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const editVisiblity =
  (data, popup, successPopup, setSuccessPopup) => async (dispatch) => {
    await Axios.post("/set-visibility", data, config)
      .then((res) => {
        dispatch({
          type: SET_VISIBILITY,
          payload: res.data,
        });
        popup(false);
        setSuccessPopup(
          <SuccessPopup
            Popup={() => setSuccessPopup(!successPopup)}
            message="Visibility saved successfully"
          />
        );
      })
      .catch((err) => {
        setSuccessPopup(
          <ErrorPopup
            popup={setSuccessPopup}
            message={err?.response?.data?.message}
          />
        );
      });
  };

export const editExperienceLev =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-experience-level", data, config)
        .then((res) => {
          dispatch({
            type: SET_EXPERIENCE_LEVEL,
            payload: res.data,
          });
          popup(false);
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Experience level saved successfully"
            />
          );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onDeletePortfolio =
  (data, setConfirmPopup, setLoading) => async (dispatch) => {
    await Axios.post("/delete-portfolio-info", data, config)
      .then((res) => {
        dispatch({
          type: SET_DELETE_PORTFOLIO,
          payload: res.data,
        });
        if (setLoading) {
          setLoading(false);
        }
        setConfirmPopup(false);
      })
      .catch(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onAdditionalAccount =
  (data, user_type, navigate, setLoading) => async (dispatch) => {
    await Axios.post("/additional-account", data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: SET_ADDITIONAL_ACCOUNT,
            payload: res.data,
          });

          dispatch(onUserRoleChange(user_type, setLoading, navigate));
        }

        if (setLoading) {
          setLoading(false);
        }
      })
      .catch(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onSubmitVerificationDocs =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/user-document-verify", data, config)
        .then((res) => {
          dispatch({
            type: SET_SUBMIT_VERIF_DOCS,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          if (popup) {
            popup();
          }
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Verification request sent successfully"
            />
          );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
            setSuccessPopup(
              <ErrorPopup
                popup={setSuccessPopup}
                message={err?.response?.data?.message}
              />
            );
          }
        });
    };

export const onRequestTestimonial =
  (data, popup, successPopup, setSuccessPopup, setLoading) =>
    async (dispatch) => {
      await Axios.post("/edit-testimonial-info", data, config)
        .then((res) => {
          dispatch({
            type: REQUEST_TESTIMONIAL,
            payload: res.data,
          });
          popup();
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Testimonial request sent successfully"
            />
          );
        })
        .catch((err) => {
          dispatch({
            type: ERROR_REQUEST_TESTIMONIAL,
            payload: err.response.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onSubmitTestimonial =
  (data, successPopup, setSuccessPopup, afterSuccess, setLoading) =>
    async (dispatch) => {
      await Axios.post("/client-testimonial", data, config)
        .then((res) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <SuccessPopup
              Popup={() => setSuccessPopup(!successPopup)}
              message="Testimonial submitted successfully"
              afterSuccess={afterSuccess}
            />
          );
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          setSuccessPopup(
            <ErrorPopup
              popup={setSuccessPopup}
              message={err?.response?.data?.message}
            />
          );
        });
    };

export const onGetTestmonial =
  (data, setValues, navigate) => async (dispatch) => {
    await Axios.post("/get-testimonial", data, config)
      .then((res) => {
        dispatch({
          type: GET_TESTIMONIAL,
          payload: res.data.data,
        });
        if (res.data.data.is_submit) {
          navigate("/");
        }
        setValues(res.data.data);
      })
      .catch(() => {
        navigate("/");
      });
  };
export const onAddCategory =
  (
    data,
    successPopup,
    setSuccessPopup,
    afterSuccess,
    setLoading,
    setCatEdit,
    setCurrentTab,
    navigate
  ) =>
    async (dispatch) => {
      await Axios.post("/add-category", data, config)
        .then((res) => {
          dispatch({
            type: ADD_CATEGORY,
            payload: res.data,
          });
          if (setLoading) {
            setLoading(false);
          }
          if (setCatEdit) {
            setCatEdit(false);
          }
          setCurrentTab("hourly-rate");
          navigate(`/freelancer/profile-intro/hourly-rate`);
        })
        .catch(() => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const getCertificationList =
  (setCertificateList, setLoading) => async (dispatch) => {
    await Axios.get("/certificate-list", config)
      .then((res) => {
        setCertificateList(res.data.data);
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

const categoryListStatus = (status) => {
  return {
    type: PROFILE_ACTION_TYPES.SET_CATEGORY_LIST_STATUS,
    payload: status,
  };
}

export const getCategoryList = () => async (dispatch) => {
  dispatch(categoryListStatus("loading"));
  await Axios.get("/category-list")
    .then((res) => {
      dispatch({
        type: SET_CATEGORY_LIST,
        payload: res.data.data,
      });
      dispatch(categoryListStatus("success"));
    })
    .catch((err) => {
      dispatch(categoryListStatus(err));
    });
};
