import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRouteGroup = () => {
  const auth = localStorage.getItem("unify_token");
  if (!auth)
    return <Navigate to="/login"/>;

  return <Outlet />
};

export default PrivateRouteGroup;