import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectAuthReducer = (state: RootState) => state.auth;

const selectHomePageData = createSelector(selectAuthReducer, (data) => data.homePageData);
const selectHomePageDataStatus = createSelector(selectAuthReducer, (data) => data.homePageDataStatus);
const selectCountryList = createSelector(selectAuthReducer, (data) => data.getCountryList);
const selectCountryListStatus = createSelector(selectAuthReducer, (data) => data.getCountryListStatus);
const selectStateList = createSelector(selectAuthReducer, (data) => data.getStateList);
const selectStateListStatus = createSelector(selectAuthReducer, (data) => data.getStateListStatus);
const selectTextPageData = createSelector(selectAuthReducer, (data) => data.textPageData);
const selectTextPageDataContent = createSelector(selectAuthReducer, (data) => data.textPageData.content);
const selectTextPageDataStatus = createSelector(selectAuthReducer, (data) => data.textPageDataStatus);
const selectOnLoginStatus = createSelector(selectAuthReducer, (data) => data.loginStatus)
const selectOnOnlineStatusChange = createSelector(selectAuthReducer, (data) => data.onlineStatusChange)
const selectOnOnlineStatus = createSelector(selectAuthReducer, (data) => data.onlineStatus)
const selectAllUserDetails = createSelector(selectAuthReducer, (data) => data.allUserDetails)
const selectAllUserDetailsStatus = createSelector(selectAuthReducer, (data) => data.allUserDetailsStatus)
const selectAllUserDetailsClient = createSelector(selectAuthReducer, (data) => data.allUserDetails.client)
const selectAllUserDetailsBasicInfo = createSelector(selectAuthReducer, (data) => data.allUserDetails.basicInfo)
const selectFooterData = createSelector(selectAuthReducer, (data) => data.footerData)
const selectHeaderData = createSelector(selectAuthReducer, (data) => data.headerData)
const selectGetLmsCertificate = createSelector(selectAuthReducer, (data) => data.getLmsCertificate)
const selectFreelancerProfile = createSelector(selectAuthReducer, (data) => data.allUserDetails.freelancer_profile)
const selectBasicInfo = createSelector(selectAuthReducer, (data) => data.allUserDetails.basic_info)
const selectUserChangeStatus = createSelector(selectAuthReducer, (data) => data.userChangeStatus)
const selectCategorySkillData = createSelector(selectAuthReducer, (data) => data.categorySkillData)

export {
  selectHeaderData,
  selectFooterData,
  selectHomePageData,
  selectHomePageDataStatus,
  selectCountryList,
  selectCountryListStatus,
  selectStateList,
  selectStateListStatus,
  selectTextPageData,
  selectTextPageDataContent,
  selectOnLoginStatus,
  selectTextPageDataStatus,
  selectOnOnlineStatusChange,
  selectOnOnlineStatus,
  selectAllUserDetails,
  selectAllUserDetailsStatus,
  selectAllUserDetailsClient,
  selectAllUserDetailsBasicInfo,
  selectGetLmsCertificate,
  selectFreelancerProfile,
  selectBasicInfo,
  selectUserChangeStatus,
  selectCategorySkillData
};