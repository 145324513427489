import { LoadingType } from "Redux/Reducers/authReducer";


export enum NOTIFICATIONS_ACTION_TYPES {
  NOTIFICATION_STATUS = 'NOTIFICATIONS::NOTIFICATION_STATUS',
  SET_SYSTEM_NOTIFICATION = 'NOTIFICATIONS::SET_SYSTEM_NOTIFICATION',
  DELETE_SYSTEM_NOTIFICATION = 'NOTIFICATIONS::DELETE_SYSTEM_NOTIFICATION',
  SEE_NOTIFICATION = 'NOTIFICATIONS::SEE_NOTIFICATION',
  SET_NOTIFICATION_SETTING = 'NOTIFICATIONS::SET_NOTIFICATION_SETTING',
  UPDATE_NOTIFICATION_SETTING = 'NOTIFICATIONS::UPDATE_NOTIFICATION_SETTING',
  SET_CLIENT_NOTIFICATION_SETTING = 'NOTIFICATIONS::SET_CLIENT_NOTIFICATION_SETTING',
  UPDATE_CLIENT_NOTIFICATION_SETTING = 'NOTIFICATIONS::UPDATE_CLIENT_NOTIFICATION_SETTING',
}

export type NotificationsAction =
  | { type: NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_STATUS; payload: LoadingType }
  | { type: NOTIFICATIONS_ACTION_TYPES.SET_SYSTEM_NOTIFICATION; payload:  any }
  | { type: NOTIFICATIONS_ACTION_TYPES.DELETE_SYSTEM_NOTIFICATION; payload: any }
  | { type: NOTIFICATIONS_ACTION_TYPES.SEE_NOTIFICATION; payload: any }
  | { type: NOTIFICATIONS_ACTION_TYPES.SET_NOTIFICATION_SETTING; payload: any }
  | { type: NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION_SETTING; payload: any }
  | { type: NOTIFICATIONS_ACTION_TYPES.SET_CLIENT_NOTIFICATION_SETTING; payload: any }
  | { type: NOTIFICATIONS_ACTION_TYPES.UPDATE_CLIENT_NOTIFICATION_SETTING; payload: any }