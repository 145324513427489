import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCategoryList } from "../Redux/Actions/profileAction";
import { getFooterData } from "../Redux/Actions/authActions";
import ShowTooltip from "./ShowTooltip";
import { selectFooterData } from "../Redux/Selectors/authSelectors";

const Footer = () => {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state?.profile?.categoryList);
  const allUserDetails = useSelector((state) => state?.auth?.allUserDetails);
  const showTooltip = useSelector((state) => state?.client?.showTooltip);
  const footerData = useSelector(selectFooterData);

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getFooterData());
  }, []);

  return (
    <div className="footer">
      <div className="container">
        <Row className="footer_border">
          <Col lg={3}>
            <div className="footer-head">
              <h3>Categories</h3>
            </div>
            <div>
              <ul className="footer_ul">
                {categoryList?.slice(0, 6)?.map((item, index) => (
                  <li key={index}>
                    <Link to={`/cat-skills/${item.id}`}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="footer-head">
              <h3>About</h3>
            </div>
            <div>
              <ul className="footer_ul">
                <li>
                  <Link to="/pages/career">Careers</Link>
                </li>
                {/* <li>
                  <Link to="/pages/press-news">Press & News</Link>
                </li> */}
                <li>
                  <Link to="/pages/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/pages/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/pages/term-conditions">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/pages/feedback">Feedback</Link>
                </li>
                {/* <li>
									<Link to="/pages/community">Community</Link>
								</li> */}
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="footer-head">
              <h3>SUPPORT</h3>
            </div>
            <div>
              <ul className="footer_ul">
                <li>
                  <a
                    href={`https://support.useunify.com/?type=unify_support&token=${localStorage.getItem("unify_token")}`}
                    target="_blank"
                  >Help & Support</a>
                  {allUserDetails?.is_tooltipon ? (
                    allUserDetails?.is_profile_complete ? (
                      allUserDetails?.active_role === "freelancer" ? (
                        <span className="no_verify_icon tooltip_hourly_div">
													<i className="bi bi-question-circle-fill" aria-hidden="true"></i>
                          {allUserDetails?.is_tooltipon && (
                            <ShowTooltip
                              message="If you need any additional help & support - you’ll find everything you need to know here"
                              showTooltip={showTooltip}
                              currentTooltip={7}
                            />
                          )}
                          {/*<span className="tooltipHourlytext">
                              <div>
                                Click here at any time when you’re on the website to return
                                to this page (your dashboard)
                              </div>
                            </span> */}
                        </span>
                      ) : null
                    ) : null
                  ) : null}
                </li>
                <li>
                  <Link to="/pages/trust-safety">Trust & Safety</Link>
                </li>
                {/*<li>
                  <Link to="/pages/selling-on-unify">Selling on Unify</Link>
                </li>
                <li>
                  <Link to="/pages/buying-on-unify">Buying on Unify</Link>
                </li> */}
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="footer-head">
              <h3>Community</h3>
            </div>
            <div>
              <ul className="footer_ul">
                {/*<li>
                  <Link to="/pages/blog">Blog</Link>
                </li> */}
                <li>
                  <Link to="/pages/community">Community Standards</Link>
                </li>
                {/* <li>
									<Link to="/pages/term-conditions">Terms of Services</Link>
								</li>
                <li>
                  <Link to="/pages/cookie-settings">Cookies Settings</Link>
                </li> */}
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="copy_right_row">
          <Col lg={6} md={6} className="col-12">
            <div className="copy_right_para">
              © Unify International Ltd. {new Date().getFullYear()}
            </div>
          </Col>
          <Col lg={6} md={6} className="col-12 right_align">
            <ul className="copy_ri_ul">
              <li>
                <Link to="/pages/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/pages/term-conditions">Terms and conditions</Link>
              </li>
            </ul>
            <div className="social_links">
              <a target="_blank" href={footerData?.socials?.twitter}>
                <i className="fa fa-twitter"></i>
              </a>
              <a target="_blank" href={footerData?.socials?.instagram}>
                <i className="fa fa-instagram"></i>
              </a>
              <a target="_blank" href={footerData?.socials?.facebook}>
                <i className="fa fa-facebook-square"></i>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Footer;
