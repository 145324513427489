import { AuthAction, AUTH_ACTION_TYPES } from "../Types/authTypes";

export type LoadingType = 'idle' | 'loading' | 'failed' | string;

export type CountryList = {
  id: number;
  name: string;
  country_code: string;
  short_name: string;
}[];

export type StateList = {
  id: number;
  name: string;
}[];

export type HomePageData = {
  hero: {
    title: string;
    description: string;
    button_text: string;
    button_link: string;
    button_text2: string;
    button_link2: string;
    image: string;
  };
  used_by: {
    used_by_section_image: string[];
  }
  trusted_brand_title: string;
  trusted_brand_description: string;
  trusted_brands: {
    brand_description: string;
    brand_name: string;
    designation: string;
    total_projects: string;
    launch_projects: string;
    logo: string;
  }[]
  for_client: {
    client_banner: string;
    client_title: string;
    client_description: string;
    title_1: string;
    description_1: string;
    title_2: string;
    description_2: string;
    title_3: string;
    description_3: string;
    popular_service: string;
  }
  popular_service: string;
  category: {
    category_id: number;
    category_name: string;
    category_image: string;
    jobs: number;
    freelancers: number;
  }[]
  socialLink: {
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
  }
};

export type HeaderData = {
  active: any;
  extra_accounts: any;
};

export type FooterData = {
  socials: {
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
  }
}

type AuthState = {
  homePageData: HomePageData | {},
  headerData: HeaderData | {},
  footerData: FooterData | {},
  categorySkillData: any,
  skillDevData: any,
  getCountryList: CountryList,
  getStateList: StateList,
  appleSignFailError: any,
  googleLoginFailError: any,
  textPageData: any,
  resendOtpSuccess: any,
  instantLoginEmail: any,
  postJob: any,
  clientBusinessSize: any,
  onlineStatus: {
    message: string,
    status: boolean
  } | {},
  allUserDetails: any,
  getLmsCertificate: any,
  emailDetails: any,
  //loaders
  homePageDataStatus: LoadingType,
  getCountryListStatus: LoadingType,
  getStateListStatus: LoadingType,
  loginStatus: LoadingType,
  textPageDataStatus: LoadingType,
  onlineStatusChange: LoadingType,
  allUserDetailsStatus: LoadingType,
  userChangeStatus: LoadingType,
};


const initialState: AuthState = {
  homePageData: {},
  headerData: {},
  footerData: {},
  categorySkillData: {},
  skillDevData: {},
  getCountryList: [],
  getStateList: [],
  appleSignFailError: {},
  googleLoginFailError: {},
  textPageData: {},
  resendOtpSuccess: {},
  instantLoginEmail: {},
  postJob: {},
  clientBusinessSize: {},
  onlineStatus: {},
  allUserDetails: {},
  getLmsCertificate: {},
  emailDetails: {},
  //loaders
  homePageDataStatus: 'idle',
  getCountryListStatus: 'idle',
  getStateListStatus: 'idle',
  loginStatus: 'idle',
  textPageDataStatus: 'idle',
  onlineStatusChange: 'idle',
  allUserDetailsStatus: 'idle',
  userChangeStatus: 'idle',
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.HOME_PAGE_DATA: {
      return {
        ...state,
        homePageData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.HEADER_DATA: {
      return {
        ...state,
        headerData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.FOOTER_DATA: {
      return {
        ...state,
        footerData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.CATEGORY_SKILL_DATA: {
      return {
        ...state,
        categorySkillData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SKILLS_DEVELOPER_DATA: {
      return {
        ...state,
        skillDevData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_COUNTRY: {
      return {
        ...state,
        getCountryList: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_STATE_LIST: {
      return {
        ...state,
        getStateList: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.APPLE_SIGN_IN_FAIL: {
      return {
        ...state,
        appleSignFailError: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.GOOGLE_SIGN_IN_FAIL: {
      return {
        ...state,
        googleLoginFailError: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.TEXT_PAGE_DATA: {
      return {
        ...state,
        textPageData: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.RESEND_OTP_SUCCESS: {
      return {
        ...state,
        resendOtpSuccess: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.INSTANT_LOGIN_EMAIL: {
      return {
        ...state,
        instantLoginEmail: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.POST_JOB_LIKE_THIS: {
      return {
        ...state,
        postJob: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.CLIENT_BUSINESS_SIZE: {
      return {
        ...state,
        clientBusinessSize: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_ONLINE_STATUS: {
      return {
        ...state,
        onlineStatus: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS: {
      return {
        ...state,
        allUserDetails: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_LMS_CERTIFICATE: {
      return {
        ...state,
        getLmsCertificate: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.SET_EMAIL_DETAILS: {
      return {
        ...state,
        emailDetails: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_HOME_PAGE_DATA_STATUS: {
      return {
        ...state,
        homePageDataStatus: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_COUNTRY_STATUS: {
      return {
        ...state,
        getCountryListStatus: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.SET_STATE_STATUS: {
      return {
        ...state,
        getStateListStatus: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.LOGIN_STATUS: {
      return {
        ...state,
        loginStatus: action.payload,
      };
    }
    case AUTH_ACTION_TYPES.TEXT_PAGE_DATA_STATUS: {
      return {
        ...state,
        textPageDataStatus: action.payload,
      }
    }

    case AUTH_ACTION_TYPES.SET_ONLINE_STATUS_CHANGE: {
      return {
        ...state,
        onlineStatusChange: action.payload,
      }
    }
    case AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS_STATUS: {
      return {
        ...state,
        allUserDetailsStatus: action.payload,
      }
    }
    case AUTH_ACTION_TYPES.USER_CHANGE_STATUS: {
      return {
        ...state,
        userChangeStatus: action.payload,
      }
    }
    default:
      return state;
  }
};

export default authReducer;
