import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectNotificationsReducer = (state: RootState) => state.notification;

const selectNotificationStatus = createSelector(selectNotificationsReducer, (data) => data.notificationStatus);
const selectSystemNotificationPing = createSelector(selectNotificationsReducer, (data) => data.systemNotificationPing);
const selectDeleteNotification = createSelector(selectNotificationsReducer, (data) => data.deleteNotification);
const selectSeenNotification = createSelector(selectNotificationsReducer, (data) => data.seenNotification);

export {
  selectNotificationStatus,
  selectSystemNotificationPing,
  selectDeleteNotification,
  selectSeenNotification
};