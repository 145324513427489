import styles from "styled-components";
import styled from "styled-components";

export const ProfileImageContainer = styles.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 240px;

   img {
      width: 60px;
      height: 60px;
      margin-top: 10px;
      border-radius: 50%;
   }
`;

export const ProfileNameContainer = styles.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-top: 10px;

   .pro_name_drop_u {
      font-size: 16px;
      font-weight: 600;
   }
`;

export const NavNodeWrapper = styled.div`
  .profile_dropdown {
    margin-right: 0px;
  }
  
  &:hover {
    .dropdown-menu {
      display: block !important;
    }
  }
`;