import { JobAction, JOB_ACTION_TYPES } from "../Types/jobTypes";
import { LoadingType } from "./authReducer";

export type JobListData = {
  id: number;
  is_feature: boolean;
  name: string;
  description: string;
  budget_type: string;
  min_price: number;
  price: number;
  location: string;
  project_duration: string;
  is_saved: boolean;
  is_proposal_send: boolean;
  isSendOffer: boolean;
  created_at: string;
  skills: {
    id: number;
    name: string;
  }[];
} ;

type JobState = {
  jobsList?: {
    data:  JobListData;
    meta: any;
  } | [];
  savedJobsList?: any;
  matchedJobsList?: any;
  saveJobsPost?: any;
  unSaveJobsPost?: any;
  singleJobDetails?: any;
  sendJobProposal?: any;
  proposalData?: any;
  dislikeJobReasons?: any;
  onDislikeJobPost?: any;
  allClientPosting?: any;
  allClientDraftPosting?: any;
  postYourJob?: any;
  getAllProposal?: any;
  getAllContracts?: {
    data:{
      contract_id: number;
      offer_id: number;
      client_name: string;
      start_time: string;
      end_time: string;
      contract_status: string;
      amount: number;
      fixed_Price_amount_agreed: number;
      upfront_payments: number;
      project_name: string;
      client_country: string,  
      contract_weekly_limit: number,
      contract_amount: number,
      in_escrow: number,
      contract_budget_type: string,
    }
    meta: any
  } | [];
  closeJobReasons?: any;
  updateJobPosted?: any;
  removeJobPosted?: any;
  removeDraftedJobPost?: any;
  singleProposalDetails?: any;
  reasonsList?: any;
  changeTerms?: any;
  singleContractData?: any;
  addTimesheetTime?: any;
  getTimeSheet?: any;
  onSubmitWorkForPayment?: any;
  onRequestForMilestone?: any;
  overviewReports?: any;
  overviewReportCompleted: any;
  overviewReportReview?: any;
  overviewReportAwaiting?: any;
  overviewReportsStatus?: LoadingType;
  whenEndContract?: any;
  approveTimesheet?: any;
  sendFeedBack?: any;
  refundOrDispute?: any;
  freelancerClientList?: any;
  invoiceData?: any;
  filters?: any;
  unicoinHistory?: any;
  allHourlyContracts?: any;
  workDiary?:  {
      contract_id: number;
      project_name: string;
      started_at: string;
      tracks:  {
          id: number;
          date: string;
          hours: string;
          comment: string;
        }[]
    }[] | [];
  timerStatus?: {
    id: number;
    contact_id: number;
    status: "working" | "stopped";
    date_from: string;
    hours: string;
    seconds	: number;
    comment: string;
  } | {};
  logTimeStatus?: LoadingType;
  workDiaryStatus?: LoadingType;
  projectStartStatus?: LoadingType;
  timeSheetData?: {
    id: number;
    contract_id: number;
    date: string;
    hours: string;
    comment: string;
  }[];
  timeSheetDataStatus?: LoadingType;
  timeSheetDataHours?: string;
  /*
        "since_start": "04:06",
        "last_week": "00:00",
        "yesterday": "00:00",
        "this_week": "00:00",
        "week_manual": "00:00",
        "over_limit": "00:00",
        "weekly_limit": 40,
        "freelancer_id": 800,
        "week_days": [
   */
  singleContractTimeSheet?: {
    since_start: string;
    last_week: string;
    yesterday: string;
    this_week: string;
    over_limit: string;
    weekly_limit: number;
    freelancer_id: number;
    week_days: {
      status: string;
      date: string;
      hours: string;
      progress: number;
    }[]
  } | {};
  allClientPostingStatus?: LoadingType;
};

const initialState: JobState = {
  jobsList: [],
  savedJobsList: [],
  matchedJobsList: [],
  saveJobsPost: {},
  unSaveJobsPost: {},
  singleJobDetails: {},
  sendJobProposal: {},
  proposalData: {},
  dislikeJobReasons: [],
  onDislikeJobPost: {},
  allClientPosting: [],
  allClientDraftPosting: [],
  postYourJob: {},
  getAllProposal: [],
  getAllContracts: [],
  closeJobReasons: [],
  updateJobPosted: {},
  removeJobPosted: {},
  removeDraftedJobPost: {},
  singleProposalDetails: {},
  reasonsList: [],
  changeTerms: {},
  singleContractData: {},
  addTimesheetTime: {},
  getTimeSheet: [],
  onSubmitWorkForPayment: {},
  onRequestForMilestone: {},
  overviewReports: {},
  overviewReportCompleted: {},
  overviewReportReview: {},
  overviewReportAwaiting: {},
  overviewReportsStatus: 'idle',
  whenEndContract: {},
  approveTimesheet: {},
  sendFeedBack: {},
  refundOrDispute: {},
  freelancerClientList: [],
  invoiceData: {},
  filters: {},
  unicoinHistory: {},
  allHourlyContracts: [],
  workDiary: [],
  timerStatus: {},
  logTimeStatus: 'idle',
  workDiaryStatus: 'idle',
  projectStartStatus: 'idle',
  timeSheetDataStatus: 'idle',
  timeSheetData: [],
  timeSheetDataHours: '',
  singleContractTimeSheet: {},
  allClientPostingStatus: 'idle',
};

const jobReducer = (state = initialState, action: JobAction) => {
  switch (action.type) {
    case JOB_ACTION_TYPES.JOBS_LIST:
      return {
        ...state,
        jobsList: action.payload,
      };
    case JOB_ACTION_TYPES.SAVED_JOBS_LIST:
      return {
        ...state,
        savedJobsList: action.payload,
      };
    case JOB_ACTION_TYPES.MATCHED_JOBS_LIST:
      return {
        ...state,
        matchedJobsList: action.payload,
      };
    case JOB_ACTION_TYPES.SAVE_JOB_POST:
      return {
        ...state,
        saveJobsPost: action.payload,
      };
    case JOB_ACTION_TYPES.UNSAVE_JOB_POST:
      return {
        ...state,
        unSaveJobsPost: action.payload,
      };
    case JOB_ACTION_TYPES.JOB_POST_DETAILS:
      return {
        ...state,
        singleJobDetails: action.payload,
      };
    case JOB_ACTION_TYPES.SEND_JOB_PROPOSAL:
      return {
        ...state,
        sendJobProposal: action.payload,
      };
    case JOB_ACTION_TYPES.SEND_PROPOSAL_DATA:
      return {
        ...state,
        proposalData: action.payload,
      };
    case JOB_ACTION_TYPES.DISLIKE_POST_REASONS:
      return {
        ...state,
        dislikeJobReasons: action.payload,
      };
    case JOB_ACTION_TYPES.ON_DISLIKE_JOB_POST:
      return {
        ...state,
        onDislikeJobPost: action.payload,
      };
    case JOB_ACTION_TYPES.SET_ALL_CLIENT_POSTING:
      return {
        ...state,
        allClientPosting: action.payload,
      };
    case JOB_ACTION_TYPES.SET_ALL_CLIENT_DRAFT_POSTING:
      return {
        ...state,
        allClientDraftPosting: action.payload,
      };
    case JOB_ACTION_TYPES.SET_POST_YOUR_JOB_NOW:
      return {
        ...state,
        postYourJob: action.payload,
      };
    case JOB_ACTION_TYPES.SET_ALL_PROPOSALS:
      return {
        ...state,
        getAllProposal: action.payload,
      };
    case JOB_ACTION_TYPES.SET_ALL_CONTRACTS:
      return {
        ...state,
        getAllContracts: action.payload,
      };
    case JOB_ACTION_TYPES.SET_CLOSE_JOB_REASON_LIST:
      return {
        ...state,
        closeJobReasons: action.payload,
      };
    case JOB_ACTION_TYPES.SET_UPDATE_JOB_POST:
      return {
        ...state,
        updateJobPosted: action.payload,
      };
    case JOB_ACTION_TYPES.SET_REMOVE_JOB_POST:
      return {
        ...state,
        removeJobPosted: action.payload,
      };
    case JOB_ACTION_TYPES.SET_REMOVE_DRAFTED_JOB_POST:
      return {
        ...state,
        removeDraftedJobPost: action.payload,
      };
    case JOB_ACTION_TYPES.SET_SINGLE_PROPOSAL_DETAILS:
      return {
        ...state,
        singleProposalDetails: action.payload,
      };
    case JOB_ACTION_TYPES.DECLINE_REASONS_LIST:
      return {
        ...state,
        reasonsList: action.payload,
      };
    case JOB_ACTION_TYPES.PROPOSAL_TERMS_CHANGE:
      return {
        ...state,
        changeTerms: action.payload,
      };
    case JOB_ACTION_TYPES.SINGLE_CONTRACT_DATA:
      return {
        ...state,
        singleContractData: action.payload,
      };
    case JOB_ACTION_TYPES.ADD_TIMESHEET_TIME:
      return {
        ...state,
        addTimesheetTime: action.payload,
      };
    case JOB_ACTION_TYPES.GET_TIMESHEET_DATA:
      return {
        ...state,
        getTimeSheet: action.payload,
      };
    case JOB_ACTION_TYPES.ON_SUBMIT_WORK_FOR_PAYMENT:
      return {
        ...state,
        onSubmitWorkForPayment: action.payload,
      };
    case JOB_ACTION_TYPES.ON_FREELANCER_REQUEST_MILESTONE:
      return {
        ...state,
        onRequestForMilestone: action.payload,
      };
    case JOB_ACTION_TYPES.GET_OVERVIEW_REPORT:
      return {
        ...state,
        overviewReports: action.payload,
      };
    case JOB_ACTION_TYPES.ON_END_CONTRACT:
      return {
        ...state,
        whenEndContract: action.payload,
      };
    case JOB_ACTION_TYPES.ON_APPROVE_TIMESHEET:
      return {
        ...state,
        approveTimesheet: action.payload,
      };
    case JOB_ACTION_TYPES.ON_SEND_FEEDBACK:
      return {
        ...state,
        sendFeedBack: action.payload,
      };
    case JOB_ACTION_TYPES.REFUND_OR_DISPUTE:
      return {
        ...state,
        refundOrDispute: action.payload,
      };
    case JOB_ACTION_TYPES.FREELANCER_CLIENT_LIST:
      return {
        ...state,
        freelancerClientList: action.payload,
      };
    case JOB_ACTION_TYPES.INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.payload,
      };
    case JOB_ACTION_TYPES.SAVING_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case JOB_ACTION_TYPES.UNICOINE_HISTORY:
      return {
        ...state,
        unicoinHistory: action.payload,
      };
    case JOB_ACTION_TYPES.SET_ALL_HOURLY_CONTRACTS:
      return {
        ...state,
        allHourlyContracts: action.payload,
      };
    
    case JOB_ACTION_TYPES.GET_WORK_DIARY:
      return {
        ...state,
        workDiary: action.payload,
      };
    case JOB_ACTION_TYPES.SET_LOG_TIME_MANUAL_STATUS:
      return {
        ...state,
        logTimeStatus: action.payload,
    };
    case JOB_ACTION_TYPES.SET_GET_WORK_DIARY_STATUS:
      return {
        ...state,
        workDiaryStatus: action.payload,
    };
    case JOB_ACTION_TYPES.SET_PROJECT_START_STATUS:
      return {
        ...state,
        projectStartStatus: action.payload,
      }
    case JOB_ACTION_TYPES.SET_TIMER_STATUS:
      return {
        ...state,
        timerStatus: action.payload,
      }
    case JOB_ACTION_TYPES.GET_TIME_SHEET_DATA:
      return {
        ...state,
        timeSheetData: action.payload,
      }
    case JOB_ACTION_TYPES.SET_TIME_SHEET_DATA_STATUS:
      return {
        ...state,
        timeSheetDataStatus: action.payload,
      }
    case JOB_ACTION_TYPES.GET_TIME_SHEET_DATA_HOURS:
      return {
        ...state,
        timeSheetDataHours: action.payload,
      }
    case JOB_ACTION_TYPES.GET_SINGLE_CONTRACT_TIME_SHEET_DATA:
      return {
        ...state,
        singleContractTimeSheet: action.payload,
      }
    case JOB_ACTION_TYPES.SET_ALL_CLIENT_POSTING_STATUS:
      return {
        ...state,
        allClientPostingStatus: action.payload,
      }
    case JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_COMPLETED:
      return {
        ...state,
        overviewReportCompleted: action.payload,
      }
    case JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_REVIEW:
      return {
        ...state,
        overviewReportReview: action.payload,
      }
    case JOB_ACTION_TYPES.GET_OVERVIEW_REPORT_AWAITING:
      return {
        ...state,
        overviewReportAwaiting: action.payload,
      }
    case JOB_ACTION_TYPES.SET_OVERVIEW_REPORT_STATUS: 
      return {
        ...state,
        overviewReportsStatus: action.payload,
      }
    default:
      return state;
  }
};

export default jobReducer;
