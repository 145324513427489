import { ChatAction, CHAT_ACTION_TYPES } from "../Types/chatTypes";
import { LoadingType } from "./authReducer";

type ChatState = {
  messagesOfUser: any;
  chatAttachmentsUploaded: any;
  chatRoomsList: any;
  chatHistoryForActiveUser: any;
  zoomLinkGenerated: any;
  filterdResultChat: any;
  searchMsgForAllUsrs: any;
  chatGroupUsersList: any;
  deleteGroupUsersList: any;
  addGroupUsersList: any;
  chatHistoryStatus?: LoadingType;
  chatRoomListStatus?: LoadingType;
  chatFilteredListStatus?: LoadingType;
};

const initialState: ChatState = {
  messagesOfUser: [],
  chatAttachmentsUploaded: [],
  chatRoomsList: [],
  chatHistoryForActiveUser: [],
  zoomLinkGenerated: "",
  filterdResultChat: [],
  searchMsgForAllUsrs: [],
  chatGroupUsersList: [],
  deleteGroupUsersList: [],
  addGroupUsersList: [],
  chatHistoryStatus: 'idle',
  chatRoomListStatus: 'idle',
  chatFilteredListStatus: 'idle',
};

const chatReducer = (state = initialState, action: ChatAction) => {
  switch (action.type) {
    case CHAT_ACTION_TYPES.MESSAGES:
      return {
        ...state,
        messagesOfUser: action.payload,
      };
    case CHAT_ACTION_TYPES.CHAT_ATTACHMENT_UPLOAD:
      return {
        ...state,
        chatAttachmentsUploaded: action.payload,
      };
    case CHAT_ACTION_TYPES.GET_ROOM_LIST:
      return {
        ...state,
        chatRoomsList: action.payload,
      };
    case CHAT_ACTION_TYPES.GET_CHAT_HISTORY_FOR_ACTIVE_USER:
      return {
        ...state,
        chatHistoryForActiveUser: action.payload,
      };
    case CHAT_ACTION_TYPES.GENERATE_ZOOM_LINK:
      return {
        ...state,
        zoomLinkGenerated: action.payload,
      };
    case CHAT_ACTION_TYPES.SEARCH_FILTER_SINGLE_CHAT:
      return {
        ...state,
        filterdResultChat: action.payload,
      };
    case CHAT_ACTION_TYPES.SEARCH_MSG_FOR_ALL_USERS:
      return {
        ...state,
        searchMsgForAllUsrs: action.payload,
      };
    case CHAT_ACTION_TYPES.SET_CHAT_GROUP_USER_LIST:
      return {
        ...state,
        chatGroupUsersList: action.payload,
      };
    case CHAT_ACTION_TYPES.SET_DELETE_GROUP_USER_LIST:
      return {
        ...state,
        deleteGroupUsersList: action.payload,
      };
    case CHAT_ACTION_TYPES.ADD_USER_GROUP_LIST:
      return {
        ...state,
        addGroupUsersList: action.payload,
      };
    case CHAT_ACTION_TYPES.SET_CHAT_HISTORY_STATUS:
      return {
        ...state,
        chatHistoryStatus: action.payload,
      };
    case CHAT_ACTION_TYPES.SET_ROOM_LIST_STATUS:
      return {
        ...state,
        chatRoomListStatus: action.payload,
      };
    case CHAT_ACTION_TYPES.SET_FILTERED_MESSAGES_STATUS:
      return {
        ...state,
        chatFilteredListStatus: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
