import { COLORS } from "UI/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div<{imageWidth?: number, imageHeight?: number, imageOpacity?: number}>`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 38px;
    cursor: pointer;
    transition: ease 0.2s;
    border-radius: 2px;
    border: 2px solid;
    padding: 10px 10px;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
    margin-bottom: -1px;
    transition: 0.2s;
  }

  button.round {
    border-radius: 50%;
  }

  .primary,
  .primary {
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.violetPrimary};
    border: 2px solid ${COLORS.violetPrimary};
    border-radius: 10px;
    color: ${COLORS.whitePrimary};
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 30px;

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      background: ${COLORS.violetDark};
      border: 2px solid ${COLORS.violetDark};
      color: ${COLORS.whitePrimary};
    }
  }

  .primary-rounded {
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.violetPrimary};
    border: 2px solid ${COLORS.violetPrimary};
    border-radius: 100px;
    color: ${COLORS.whitePrimary};
    width: fit-content;
    font-size: 13px;
    padding: 10px 30px;
    font-weight: 700;

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      background: ${COLORS.violetDark};
      border: 2px solid ${COLORS.violetDark};
      color: ${COLORS.whitePrimary};
    }
  }

  .icon-outline-primary {
    height: 50px;
    width: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    border: 2px solid ${COLORS.pinkPrimary};
    border-radius: 100px;
    color: ${COLORS.whitePrimary};
    font-size: 13px;

    img {
      width: 50px;
      height: 50px;
    }

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      border: 2px solid ${COLORS.whitePrimary};
    }
  }

  .primary-underline {
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    border: none;
    border-bottom: 5px solid #ffffff00;
    border-radius: 0;
    color: ${COLORS.blackPrimary};
    font-size: 16px;
    opacity: 0.5;
    padding: 25px 30px;

    img {
      width: 50px;
      height: 50px;
    }

    &.active_bvs {
      border-bottom: 5px solid ${COLORS.violetPrimary};
      opacity: 1;
      color: ${COLORS.violetPrimary};
      &:hover {
        color: ${COLORS.violetPrimary};
        opacity: 1;
        border-bottom: 5px solid ${COLORS.violetPrimary};
      }
    }

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      color: ${COLORS.blackPrimary};
      opacity: 0.5;
      border-bottom: 5px solid ${COLORS.grayPrimary};
    }
  }

  .primary-outline {
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.violetPrimary};
    border: 1px solid ${COLORS.violetPrimary};
    color: ${COLORS.whitePrimary};
    width: 100px;
    font-weight: 300;

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      background: ${COLORS.violetLight};
    }
  }

  .primary-outline-rounded {
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    border: 2px solid ${COLORS.violetPrimary};
    color: ${COLORS.violetPrimary};
    width: 100px;
    font-weight: 600;
    border-radius: 100px;
    transition: ease 0.2s;
    font-size: 13px;

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      border: 2px solid ${COLORS.violetPrimary};  
    }
  }

  .outline-transparent {
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    border: 1px solid ${COLORS.grayPrimary};
    color: ${COLORS.blackPrimary};
    width: 100px;
    font-weight: 300;

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      border: 1px solid ${COLORS.violetPrimary};
      color: ${COLORS.violetPrimary};
    }
  }

  .transparent-rounded {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid ${COLORS.whitePrimary};
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    color: ${COLORS.blackPrimary};
    font-weight: 300;

    img {
      opacity: ${({imageOpacity}) => imageOpacity || 0.4};
      width: ${({imageWidth}) => `${imageWidth || 15}px`};
      height: ${({imageHeight}) => `${imageHeight || 15}px`};
      transition: ease 0.2s;
    }

    &:disabled,
    [disabled] {
      opacity: 0.1;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }

  .pagination {
    height: 35px;
    width: 35px;
    text-align: center;
    display: flex;
    align-items: center;
    background: ${COLORS.whitePrimary};
    border: 2px solid ${COLORS.grayPrimary};
    color: ${COLORS.blackPrimary};
    font-weight: 600;
    border-radius: 4px;
    transition: ease 0.2s;

    &.active {
      border: 2px solid ${COLORS.violetPrimary};
      background-color: ${COLORS.violetPrimary};
      color: ${COLORS.whitePrimary};
    }

    &:hover {
      border: 2px solid ${COLORS.violetPrimary};
      background-color: ${COLORS.violetPrimary};
      color: ${COLORS.whitePrimary};
    }

    &:disabled,
    [disabled] {
      background-color: ${COLORS.grayPrimary};
      color: ${COLORS.whitePrimary};
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .outline-dark {
    background-color: transparent;
    opacity: 0.8;
    color: ${COLORS.blackPrimary};
    border-color: transparent;


    &:hover {
      opacity: 1;
    }
  }

 


  .spinner {
    width: 20px;
    height: 20px;
  }

`;