import {
  ADD_NEW_CARD,
  ADD_NEW_COLUMN,
  ADD_NEW_LABEL,
  COMMENT_ON_TASK,
  DELETE_COLUMN,
  DELETE_TASK,
  GET_LABEL_LIST,
  GET_TASKSHEET_BOARD_LIST,
  GET_TASK_DETAILS,
  ON_DELETE_TASK_ATTACHMENT,
  ON_DELETE_TASK_COMMENT,
  ON_TASK_MOVE,
} from "../Types";

type ManagementState = {
  tasksheetBoardList: {
    contract_id: number;
    id: number;
    title: string;
    task_list: {
      attachment_count: number
      comment_count: number;
      contract_id: number;
      description: string;
      due_date: string;
      id: number;
      name: string;
      tasksheet_board_id: number;
    }[]
  }[] | [];
  labelsList: any;
  newColumnAdded: any;
  newCardAdded: any;
  columnDeleted: any;
  taskDeleted: any;
  commentOnTask: any;
  getTasksDetails: any;
  onTaskAttachmentDeleted: any;
  onTaskCommentDeketed: any;
  onTaskMoved: any;
  onNewLabelCreated: any;
};

const initialState: ManagementState = {
  tasksheetBoardList: [],
  labelsList: {},
  newColumnAdded: {},
  newCardAdded: {},
  columnDeleted: {},
  taskDeleted: {},
  commentOnTask: {},
  getTasksDetails: {},
  onTaskAttachmentDeleted: {},
  onTaskCommentDeketed: {},
  onTaskMoved: {},
  onNewLabelCreated: {},
};

const managementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TASKSHEET_BOARD_LIST:
      return {
        ...state,
        tasksheetBoardList: action.payload,
      };
    case GET_LABEL_LIST:
      return {
        ...state,
        labelsList: action.payload,
      };
    case ADD_NEW_COLUMN:
      return {
        ...state,
        newColumnAdded: action.payload,
      };
    case ADD_NEW_CARD:
      return {
        ...state,
        newCardAdded: action.payload,
      };
    case DELETE_COLUMN:
      return {
        ...state,
        columnDeleted: action.payload,
      };
    case DELETE_TASK:
      return {
        ...state,
        taskDeleted: action.payload,
      };
    case COMMENT_ON_TASK:
      return {
        ...state,
        commentOnTask: action.payload,
      };
    case GET_TASK_DETAILS:
      return {
        ...state,
        getTasksDetails: action.payload,
      };
    case ON_DELETE_TASK_ATTACHMENT:
      return {
        ...state,
        onTaskAttachmentDeleted: action.payload,
      };
    case ON_DELETE_TASK_COMMENT:
      return {
        ...state,
        onTaskCommentDeketed: action.payload,
      };
    case ON_TASK_MOVE:
      return {
        ...state,
        onTaskMoved: action.payload,
      };
    case ADD_NEW_LABEL:
      return {
        ...state,
        onNewLabelCreated: action.payload,
      };
    default:
      return state;
  }
};

export default managementReducer;
