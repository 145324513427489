import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectProfileReducer = (state: RootState) => state.profile;

const selectProfileCategoryList = createSelector(selectProfileReducer, (data) => data?.categoryList);
const selectProfileSkillList = createSelector(selectProfileReducer, (data) => data?.getSkillList);
const selectCategoryListStatus = createSelector(selectProfileReducer, (data) => data?.categoryListStatus);
const selectEditClientProfileDetails = createSelector(selectProfileReducer, (data) => data?.editClientProfile);
const selectFreelancerProfileList = createSelector(selectProfileReducer, (data) => data?.freelancerProfileList);
const selectTimezoneList = createSelector(selectProfileReducer, (data) => data?.timezoneList);
const selectEditFreelancerInfo = createSelector(selectProfileReducer, (data) => data?.editFreelancerInfo);
const selectEditFreelancerLocation = createSelector(selectProfileReducer, (data) => data?.editFreelancerLocation);
const selectGetClientDetails = createSelector(selectProfileReducer, (data) => data?.getClientDetails);

export {
   selectProfileCategoryList,
   selectProfileSkillList,
   selectCategoryListStatus,
   selectEditClientProfileDetails,
   selectFreelancerProfileList,
   selectTimezoneList,
   selectEditFreelancerInfo,
   selectEditFreelancerLocation,
   selectGetClientDetails
};