import React from "react";
import { useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getRoomsList } from "../../Redux/Actions/chatAction";
import { useDispatch } from "react-redux";
import socket from "../../SocketConnection";
import { useRef } from "react";
import { useState } from "react";
import ShowTooltip from "../ShowTooltip";
import CheckPaymentBeforeJobPosted from "../../Popups/CheckPaymentBeforeJobPosted";

import { ReactComponent as DirectIcon } from "../../Assets/icons/direct.svg";
import styled from "styled-components";

const MenuItem = ({ allUserDetails, systemNotification, showTooltip }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginUserIdRefH = useRef(null);
  const allUserDetailsH = useSelector((state) => state.auth.allUserDetails);
  const [loginUserIdH, setLoginUserId] = useState(null);
  const [totalUnseenMsg, setTotalUnseenMsg] = useState(0);
  const [checkPaymentPopup, setCheckPaymentPopup] = useState(false);

  // set login details in state
  useEffect(() => {
    if (allUserDetailsH) {
      const userDetails =
        allUserDetailsH?.active_role === "freelancer"
          ? allUserDetailsH?.basicInfo
          : allUserDetailsH?.active_role === "client"
            ? allUserDetailsH?.client
            : allUserDetailsH?.active_role === "agency"
              ? allUserDetailsH?.agency
              : null;

      const sender_id =
        allUserDetailsH?.active_role === "agency"
          ? userDetails?.agency_id
          : userDetails?.id;
      setLoginUserId(Number(sender_id));
    }
  }, [allUserDetailsH]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoomsList({ page: 1, search: "", type: "users" }));
  }, []);

  const chatRoomsList = useSelector(
    (state) => state?.chat?.chatRoomsList?.data
  );

  let total = 0;
  const calculateUnseenMessages = () => {
    chatRoomsList?.forEach((element) => {
      total = total = +element?.unseen_messages;
    });
    setTotalUnseenMsg(total);
  };

  useEffect(() => {
    calculateUnseenMessages();
  }, [chatRoomsList]);

  useEffect(() => {
    loginUserIdRefH.current = loginUserIdH;
  }, [loginUserIdH]);

  useEffect(() => {
    socket?.on("newMsgNotification", (newMsgNotification) => {
      if (newMsgNotification?.receiver_id === loginUserIdRefH?.current) {
        setTotalUnseenMsg(Number(totalUnseenMsg) + 1);
      }
    });
    return () => {
      socket?.on("newMsgNotification", (newMsgNotification) => {
        if (newMsgNotification?.receiver_id === loginUserIdRefH?.current) {
          setTotalUnseenMsg(Number(totalUnseenMsg) + 1);
        }
      });
    };
  }, [totalUnseenMsg]);

  useEffect(() => {
    if (location?.search === "?activeTab=setting-billing") {
      setCheckPaymentPopup(false);
    }
  }, []);

  const checkPaymentBeforeJobPost = () => {
    if (allUserDetails?.client?.payment_verified === true) {
      navigate("/post-your-job");
    } else {
      setCheckPaymentPopup(true);
    }
  };

  return (
    <>
      <>
        {(allUserDetails?.active_role === "freelancer" ||
          allUserDetails?.active_role === "agency") && (
            <NavDropdownItem>
              <NavDropdown
                className="navbar_btn b_job_l_sx dropdown_menu_custom"
                title="Find Work"
                id="navbarScrollingDropdown"
              >
                <Link className="dropdown-item" to="/freelancer/project-search">
                  Find Work
                </Link>
                <Link className="dropdown-item" to="/freelancer/project-search/saved">
                  Saved Jobs
                </Link>
                <Link className="dropdown-item" to="/freelancer/all-proposals">
                  Proposals
                </Link>
              </NavDropdown>
              {allUserDetails?.is_tooltipon && allUserDetails?.active_role === "freelancer" && (
                <span className="no_verify_icon tooltip_hourly_div" style={{ marginLeft: "-15px" }}>
                  <i className="bi bi-question-circle-fill" aria-hidden="true"></i>
                  <ShowTooltip message="This section will allow you to find jobs, come back to saved
                                  jobs and also check the status of any jobs you’ve submitted
                                  proposals for."
                    showTooltip={showTooltip}
                    currentTooltip={3} />
                </span>
              )}

              <NavDropdown
                className="navbar_btn b_job_l_sx dropdown_menu_custom"
                title="Jobs"
                id="navbarScrollingDropdown"
              >
                <Link className="dropdown-item" to="/freelancer/all-contracts">All Contracts</Link>
                <Link className="dropdown-item" to="/freelancer/work-diary">Work Diary</Link>
                <Link className="dropdown-item" to="/freelancer/time-sheets">Time Sheets</Link>
              </NavDropdown>

              <NavDropdown
                className="navbar_btn b_job_l_sx dropdown_menu_custom"
                title="Reports"
                id="navbarScrollingDropdown"
              >
                <Link className="dropdown-item" to="/freelancer/overview">Overview</Link>
                <Link className="dropdown-item" to="/freelancer/transaction-history">Transaction History</Link>
                <Link className="dropdown-item" to="/freelancer/unicoin-history">Unicoin History</Link>
              </NavDropdown>
            </NavDropdownItem>
          )}
        {allUserDetails?.active_role === "client" && (
          <>
            <NavDropdown
              className="navbar_btn b_job_l_sx dropdown_menu_custom"
              title="Jobs"
              id="navbarScrollingDropdown"
            >
              <Link className="dropdown-item" to="/post-your-job" onClick={checkPaymentBeforeJobPost}>Post A Job</Link>
              <Link className="dropdown-item" to="/dashboard">Jobs</Link>
              <Link className="dropdown-item" to="/all-contracts">All Contracts</Link>
              {/*<Link className="dropdown-item" to="/time-sheets">Time sheets</Link>*/}
            </NavDropdown>
            <NavDropdown
              className="navbar_btn b_job_l_sx dropdown_menu_custom"
              title="Talent"
              id="navbarScrollingDropdown"
            >
              <Link className="dropdown-item" to="/talent-discover">Discover</Link>
              <Link className="dropdown-item" to="/search">Find Talent</Link>
              <Link className="dropdown-item" to="/talent-recently-viewed">Recently Viewed</Link>
              <Link className="dropdown-item" to="/talent-saved">Saved talent</Link>
              {/* TODO: will revert this then BYO will be done */}
              {/* <Link className="dropdown-item" to="/byo-talent">BYO talent</Link> */}
            </NavDropdown>
            <NavDropdown
              className="navbar_btn b_job_l_sx dropdown_menu_custom"
              title="Reports"
              id="navbarScrollingDropdown"
            >
              <Link className="dropdown-item" to="/overview">Overview</Link>
              <Link className="dropdown-item" to="/transaction-history">Transaction History</Link>
            </NavDropdown>
          </>
        )}
        <IconsWrapper>
        <Link
          to={`https://support.useunify.com/?type=unify_support&token=${localStorage.getItem(
            "unify_token"
          )}`}
          target="_blank"
          className="navbar_btn position-relative nav-icon" rel="noreferrer"
        >
          <i className="bi bi-question-lg font-size-20px"></i>
        </Link>

        {allUserDetails?.active_role === "freelancer" && (
          <Link
            to="/direct-contract"
            className="navbar_btn position-relative nav-icon"
          >
            <DirectIcon></DirectIcon>
          </Link>
        )}

        <Link
          to="/chat"
          className="navbar_btn position-relative nav-icon"
        >
          {totalUnseenMsg > 0 && (
            <span className="unseen_count message_unseen_count">
              {totalUnseenMsg}
            </span>
          )}
          <i className="bi bi-envelope font-size-20px"></i>
        </Link>
        <Link
          to="/notifications"
          className="navbar_btn position-relative nav-icon"
        >
          {systemNotification?.un_seen > 0 && (
            <span className="unseen_count message_unseen_count">
              {systemNotification?.un_seen}
            </span>
          )}
          <i className="bi bi-bell font-size-20px"></i>
        </Link>
        </IconsWrapper>
      </>
      {checkPaymentPopup && (
        <CheckPaymentBeforeJobPosted
          open={checkPaymentPopup}
          onCloseModal={() => setCheckPaymentPopup(false)}
        />
      )}
    </>
  );
};

export default MenuItem;


const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media (max-width: 992px) {
    .nav-icon {
      margin-right: 12px !important;
      margin-left: 5px !important;
    }
  }
`;

export const NavDropdownItem = styled.div`
  display: flex;

  @media (max-width: 992px) {
    .dropdown_menu_custom {
    .dropdown-toggle::after, .dropdown-toggle::before {
      top: 18px
    }
    }
  }
`;