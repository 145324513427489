export const SET_FREELANCER_PROFILE = "SET_FREELANCER_PROFILE";
export const SET_FREELANCER_SKILLS = "SET_FREELANCER_SKILLS";
export const SET_DELETE_EXPERIENCE = "SET_DELETE_EXPERIENCE";
export const SET_DELETE_EDUCATION = "SET_DELETE_EDUCATION";
export const SET_DEGREE_LIST = "SET_DEGREE_LIST";
export const SET_EDIT_FREELANCER_INFO = "SET_EDIT_FREELANCER_INFO";
export const SET_EDIT_LANGUAGE = "SET_EDIT_LANGUAGE";
export const SET_EDIT_FREELANCER_LOCATION = "SET_EDIT_FREELANCER_LOCATION";
export const SET_ADD_EXPERIENCE = "SET_ADD_EXPERIENCE";
export const SET_CLIENT_INFO_DETAILS = "SET_CLIENT_INFO_DETAILS";
export const SET_INDUSTRIES_LIST = "SET_INDUSTRIES_LIST";
export const SET_TIMEZONE_LIST = "SET_TIMEZONE_LIST";
export const SET_EDIT_CLIENT_INFO = "SET_EDIT_CLIENT_INFO";
export const SET_CLOSE_ACCOUNT_REASON_LIST = "SET_CLOSE_ACCOUNT_REASON_LIST";
export const SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST";
export const SET_HWP_LIST = "SET_HWP_LIST";
export const SET_HOURS_PER_WEEK = "SET_HOURS_PER_WEEK";
export const SET_EDIT_SKILLS = "SET_EDIT_SKILLS";
export const SET_EDIT_CERTIFICATE = "SET_EDIT_CERTIFICATE";
export const SET_DELETE_CERTIFICATE = "SET_DELETE_CERTIFICATE";
export const SET_PROFILE_IMG_CHANGE = "SET_PROFILE_IMG_CHANGE";
export const SET_EDIT_EDUCATION = "SET_EDIT_EDUCATION";
export const SET_EDIT_DESIGNATION = "SET_EDIT_DESIGNATION";
export const SET_EDIT_PORTFOLIO = "SET_EDIT_PORTFOLIO";
export const SET_EDIT_VIDEO = "SET_EDIT_VIDEO";
export const SET_VISIBILITY = "SET_VISIBILITY";
export const SET_EXPERIENCE_LEVEL = "SET_EXPERIENCE_LEVEL";
export const SET_DELETE_PORTFOLIO = "SET_DELETE_PORTFOLIO";
export const SET_ADDITIONAL_ACCOUNT = "SET_ADDITIONAL_ACCOUNT";
export const SET_SUBMIT_VERIF_DOCS = "SET_SUBMIT_VERIF_DOCS";
export const REQUEST_TESTIMONIAL = "REQUEST_TESTIMONIAL";
export const GET_TESTIMONIAL = "GET_TESTIMONIAL";
export const ERROR_REQUEST_TESTIMONIAL = "ERROR_REQUEST_TESTIMONIAL";
export const SET_JOB_DATA_LISTING = "SET_JOB_DATA_LISTING";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const ON_CREATE_TEAM = "ON_CREATE_TEAM";
export const TEAM_LIST = "TEAM_LIST";
export const SHOW_BACK_BTN = "SHOW_BACK_BTN";
export const SET_PORTFOLIO_ERROR = "SET_PORTFOLIO_ERROR";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const HOURLY_PRICE = "HOURLY_PRICE";

// SUBSCRIPTION TYPES
export const SET_SUBSCRIPTION_LIST = "SET_SUBSCRIPTION_LIST";
export const SET_JOB_BASED_FREELANCER_LIST = "SET_JOB_BASED_FREELANCER_LIST";
export const SET_SAVED_TALENT_LIST = "SET_SAVED_TALENT_LIST";
export const SET_SAVED_TALENT = "SET_SAVED_TALENT";
export const SET_REMOVE_SAVED_TALENT = "SET_REMOVE_SAVED_TALENT";
export const SET_INVITED_FREELANCER_LIST = "SET_INVITED_FREELANCER_LIST";
export const SET_FREELANCER_INVITED = "SET_FREELANCER_INVITED";
export const SET_MAKE_PRIVATE_JOB = "SET_MAKE_PRIVATE_JOB";
export const SET_ALL_JOB_PROPOSALS_LIST = "SET_ALL_JOB_PROPOSALS_LIST";
export const SET_SAVE_PROPOSAL_IN_SHORTLIST = "SET_SAVE_PROPOSAL_IN_SHORTLIST";
export const SET_REMOVE_PROPOSAL_IN_SHORTLIST = "SET_REMOVE_PROPOSAL_IN_SHORTLIST";
export const SET_PROPOSAL_SHORTLIST = "SET_PROPOSAL_SHORTLIST";
export const SET_ARCHIVED_LIST = "SET_ARCHIVED_LIST";
export const SET_SAVE_PROPOSAL_IN_ARCHIVED = "SET_SAVE_PROPOSAL_IN_ARCHIVED";
export const SET_REMOVE_PROPOSAL_IN_ARCHIVED = "SET_REMOVE_PROPOSAL_IN_ARCHIVED";
export const SET_SINGLE_FREELANCER = "SET_SINGLE_FREELANCER";
export const SET_PAYMENT_CARD_LIST = "SET_PAYMENT_CARD_LIST";
export const SET_ADD_CARD = "SET_ADD_CARD";
export const SET_DELETE_CARD = "SET_DELETE_CARD";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const SET_RECENT_FREELANCER_LIST = "SET_RECENT_FREELANCER_LIST";
export const SET_JOB_DRAFT_FREELANCER_LIST = "SET_JOB_DRAFT_FREELANCER_LIST";
export const SET_JOB_POST_FREELANCER_LIST = "SET_JOB_POST_FREELANCER_LIST";
export const SET_CLIENT_CONTRACTS_LIST = "SET_CLIENT_CONTRACTS_LIST";
export const SET_HIRED_FREELANCER_LIST = "SET_HIRED_FREELANCER_LIST";
export const SET_OFFERED_FREELANCER_LIST = "SET_OFFERED_FREELANCER_LIST";
export const SET_FREELANCER_LIST = "SET_FREELANCER_LIST";
export const SET_SELECTED_IDS = "SET_SELECTED_IDS";
export const SET_CHANGE_APPROVE_WORK = "SET_CHANGE_APPROVE_WORK";
export const SET_CLIENT_ADD_MILESTONE = "SET_CLIENT_ADD_MILESTONE";
export const SET_CLIENT_FUND_MILESTONE = "SET_CLIENT_FUND_MILESTONE";
export const SET_CLIENT_DECLINE_MILESTONE = "SET_CLIENT_DECLINE_MILESTONE";
export const SET_CLIENT_TRANSACTION_HISTORY = "SET_CLIENT_TRANSACTION_HISTORY";
export const SET_CLIENT_OVERVIEW = "SET_CLIENT_OVERVIEW";
export const GET_FREELANCER_TRANSACTION_HISTORY = "GET_FREELANCER_TRANSACTION_HISTORY";
export const SET_VERIFICATION_FILES = "SET_VERIFICATION_FILES";
export const SET_EMPTY_VERIFICATION_FILES = "SET_EMPTY_VERIFICATION_FILES";
export const SET_STRIPE_ACCOUNT_DATA = "SET_STRIPE_ACCOUNT_DATA";
export const GET_PAID_REQUEST = "GET_PAID_REQUEST";
export const GET_ALL_BANK_ACCOUNT_LIST = "GET_ALL_BANK_ACCOUNT_LIST";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const GET_TASKSHEET_BOARD_LIST = "GET_TASKSHEET_BOARD_LIST";
export const GET_LABEL_LIST = "GET_LABEL_LIST";
export const ADD_NEW_COLUMN = "ADD_NEW_COLUMN";
export const ADD_NEW_CARD = "ADD_NEW_CARD";
export const DELETE_COLUMN = "DELETE_COLUMN";
export const DELETE_TASK = "DELETE_TASK";
export const CLIENT_JOB_NAME_LIST = "CLIENT_JOB_NAME_LIST";
export const COMMENT_ON_TASK = "COMMENT_ON_TASK";
export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const ON_DELETE_TASK_ATTACHMENT = "ON_DELETE_TASK_ATTACHMENT";
export const ON_DELETE_TASK_COMMENT = "ON_DELETE_TASK_COMMENT";
export const ON_TASK_MOVE = "ON_TASK_MOVE";
export const SET_CLIENT_EDIT_ERROR = "SET_CLIENT_EDIT_ERROR";
export const ADD_NEW_LABEL = "ADD_NEW_LABEL";
export const SET_TOP_RATED_FREELANCER_LIST = "SET_TOP_RATED_FREELANCER_LIST";
export const SET_ROLE_CHANGE = "SET_ROLE_CHANGE";
export const SET_AGENCY_WORK_HISTORY = "SET_AGENCY_WORK_HISTORY";
export const SET_SAVE_RECENT_FREELANCER_LIST = "SET_SAVE_RECENT_FREELANCER_LIST";
export const SET_FREELANCER_DASHBOARD_DATA = "SET_FREELANCER_DASHBOARD_DATA";
export const FREELANCER_VACATION_MODE = "FREELANCER_VACATION_MODE";
export const SET_CANCEL_SUBSCRIPTION = "SET_CANCEL_SUBSCRIPTION";
export const SET_PROPOSAL_DECLINE = "SET_PROPOSAL_DECLINE";
export const SET_FREELANCER_WORK_HISTORY = "SET_FREELANCER_WORK_HISTORY";
export const QUICK_OVERVIEW_MAIL = "QUICK_OVERVIEW_MAIL";
export const FREELANCER_INVITE_DATA = "FREELANCER_INVITE_DATA";
export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION";
export const PROPOSAL_ORDER_LIST = "PROPOSAL_ORDER_LIST";
export const MY_STATS = "MY_STATS";
export const DIRECT_CONTRACT_DETAILS = "DIRECT_CONTRACT_DETAILS";
export const DIRECT_CONTRACT_INPUTS_DATA = "DIRECT_CONTRACT_INPUTS_DATA";
export const DIRECT_CONTRACT_CLICKED_TYPE = "DIRECT_CONTRACT_CLICKED_TYPE";
export const GET_DIRECT_CONTRACT_LIST = "GET_DIRECT_CONTRACT_LIST";
export const GET_DIRECT_CONTRACT_USER_DETAILS = "GET_DIRECT_CONTRACT_USER_DETAILS";
export const REMOVE_WITHDRAW_DIRECT_CONTRACT = "REMOVE_WITHDRAW_DIRECT_CONTRACT";
export const GET_WITHDRAWAL_DATA_LIST = "GET_WITHDRAWAL_DATA_LIST";
export const GET_WITHDRAWAL_DATA_DETAILS = "GET_WITHDRAWAL_DATA_DETAILS";
export const DECLINE_DIRECT_CONTRACT_PAYMENT = "DECLINE_DIRECT_CONTRACT_PAYMENT";
export const DIRECT_SUBMIT_WORK = "DIRECT_SUBMIT_WORK";
export const APPROVE_DIRECT_CONTRACT_WORK = "APPROVE_DIRECT_CONTRACT_WORK";
export const DIRECT_CONTRACT_PAYMENT_REQUEST = "DIRECT_CONTRACT_PAYMENT_REQUEST";
export const SET_BYO_HIRED_LIST = "SET_BYO_HIRED_LIST";
export const SET_BYO_HIRED_OFFER_LIST = "SET_BYO_HIRED_OFFER_LIST";
export const SET_DELETE_BYO_HIRED = "SET_DELETE_BYO_HIRED";
export const DIRECT_CONTRACT_INPUTS_EDIT_DATA = "DIRECT_CONTRACT_INPUTS_EDIT_DATA";
export const SET_BYO_TALENT_VALUES = "SET_BYO_TALENT_VALUES";
export const SET_MILESTONE_DATA = "SET_MILESTONE_DATA";
export const SET_TOOLTIP_OPEN = "SET_TOOLTIP_OPEN";
export const SET_TOOLTIP_DATA = "SET_TOOLTIP_DATA";
