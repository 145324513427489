import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectClientReducer = (state: RootState) => state.client;

const selectShowTooltip = createSelector(selectClientReducer, (data) => data.showTooltip);
const selectTooltipData = createSelector(selectClientReducer, (data) => data.tooltipData);

export {
  selectShowTooltip,
  selectTooltipData
};