import { FREELANCER_ACTION_TYPES } from "Redux/Types/freelancerTypes";
import {
  SET_JOB_BASED_FREELANCER_LIST,
  SET_INVITED_FREELANCER_LIST,
  SET_FREELANCER_INVITED,
  SET_SAVED_TALENT_LIST,
  SET_SAVED_TALENT,
  SET_REMOVE_SAVED_TALENT,
  SET_MAKE_PRIVATE_JOB,
  SET_ALL_JOB_PROPOSALS_LIST,
  SET_SAVE_PROPOSAL_IN_SHORTLIST,
  SET_REMOVE_PROPOSAL_IN_SHORTLIST,
  SET_PROPOSAL_SHORTLIST,
  SET_ARCHIVED_LIST,
  SET_SAVE_PROPOSAL_IN_ARCHIVED,
  SET_REMOVE_PROPOSAL_IN_ARCHIVED,
  SET_SINGLE_FREELANCER,
  SET_ADD_CARD,
  SET_PAYMENT_CARD_LIST,
  SET_DELETE_CARD,
  SET_CLIENT_DETAILS,
  SET_RECENT_FREELANCER_LIST,
  SET_JOB_DRAFT_FREELANCER_LIST,
  SET_JOB_POST_FREELANCER_LIST,
  SET_CLIENT_CONTRACTS_LIST,
  SET_HIRED_FREELANCER_LIST,
  SET_OFFERED_FREELANCER_LIST,
  SET_FREELANCER_LIST,
  SET_SELECTED_IDS,
  SET_CHANGE_APPROVE_WORK,
  SET_CLIENT_ADD_MILESTONE,
  SET_CLIENT_FUND_MILESTONE,
  SET_CLIENT_DECLINE_MILESTONE,
  SET_CLIENT_TRANSACTION_HISTORY,
  SET_CLIENT_OVERVIEW,
  GET_FREELANCER_TRANSACTION_HISTORY,
  SET_VERIFICATION_FILES,
  SET_EMPTY_VERIFICATION_FILES,
  SET_STRIPE_ACCOUNT_DATA,
  GET_PAID_REQUEST,
  GET_ALL_BANK_ACCOUNT_LIST,
  DELETE_BANK_ACCOUNT,
  CLIENT_JOB_NAME_LIST,
  SET_TOP_RATED_FREELANCER_LIST,
  SET_SAVE_RECENT_FREELANCER_LIST,
  SET_FREELANCER_DASHBOARD_DATA,
  FREELANCER_VACATION_MODE,
  SET_PROPOSAL_DECLINE,
  SET_FREELANCER_WORK_HISTORY,
  FREELANCER_INVITE_DATA,
  PROPOSAL_ORDER_LIST,
  MY_STATS,
  DIRECT_CONTRACT_DETAILS,
  GET_DIRECT_CONTRACT_LIST,
  GET_DIRECT_CONTRACT_USER_DETAILS,
  QUICK_OVERVIEW_MAIL,
  DIRECT_CONTRACT_INPUTS_DATA,
  REMOVE_WITHDRAW_DIRECT_CONTRACT,
  GET_WITHDRAWAL_DATA_LIST,
  GET_WITHDRAWAL_DATA_DETAILS,
  DIRECT_CONTRACT_CLICKED_TYPE,
  DECLINE_DIRECT_CONTRACT_PAYMENT,
  DIRECT_SUBMIT_WORK,
  DIRECT_CONTRACT_PAYMENT_REQUEST,
  APPROVE_DIRECT_CONTRACT_WORK,
  DIRECT_CONTRACT_INPUTS_EDIT_DATA,
} from "../Types";
import { LoadingType } from "./authReducer";

type FreelancerReducerType = {
  jobBasedFreelancerList: any;
  invitedFreelancerList: any;
  invitedFreelancer: any;
  getSavedTalentList: any;
  savedTalent: any;
  removeSavedTalent: any;
  privatePublicJob: any;
  jobBasedProposalsList: any;
  saveProposalInShortList: any;
  removeProposalInShortList: any;
  proposalShortlist: any;
  proposalArchievedlist: any;
  saveProposalInArchieved: any;
  removeProposalInArchieved: any;
  singleFreelancer: any;
  addedCard: any;
  getCardList: any;
  deleteCard: any;
  clientDetails: any;
  recentlyFreelancerList: any;
  jobDraftFreelancerList: any;
  jobPostFreelancerList: any;
  clientContractList: any;
  hiredFreelancerList: any;
  offeredFreelancerList: any;
  freelancerList: any;
  freelancerIdsList: any;
  reviewWorkAndChange: any;
  addMilestone: any;
  fundMilestone: any;
  declineMilestone: any;
  transactionHistory: any;
  overviewHistory: any;
  getTransactionHistory: any;
  verificationFiles: any;
  stripeAccountData: any;
  getPaidRequest: any;
  getAllBankAccountList: any;
  deleteBankAccount: any;
  getClientJobNameList: any;
  topRatedFreelancerList: any;
  saveRecentList: any;
  freelancerDashboardData: any;
  freelancerVacationMode: any;
  proposalDecline: any;
  freelancerWorkHistory: any;
  freelancerInviteData: any;
  proposalOrderList: any;
  myStatsList: any;
  directContractDetails: any;
  getDirectContractList: any;
  getDirectContractDetalis: any;
  getDirectContractInputsData: any;
  getDirectContractInputsEditData: any;
  directContactMail: any;
  removeWithdrawDirectContract: any;
  getWithdrawalDataList: any;
  getWithdrawalDataDetails: any;
  getClickedUserType: any;
  declinedDirectContractDetails: any;
  directWorkSubmit: any;
  approveDirectContractWork: any;
  getDirectPaidRequest: any;
  freelancerListStatus: LoadingType;
  singleFreelancerStatus: LoadingType;
};

const initialState: FreelancerReducerType = {
  verificationFiles: [],
  clientContractList: [],
  jobBasedFreelancerList: [],
  invitedFreelancerList: [],
  invitedFreelancer: [],
  getSavedTalentList: [],
  savedTalent: [],
  removeSavedTalent: [],
  privatePublicJob: [],
  jobBasedProposalsList: [],
  saveProposalInShortList: [],
  removeProposalInShortList: [],
  proposalShortlist: [],
  proposalArchievedlist: [],
  saveProposalInArchieved: [],
  removeProposalInArchieved: [],
  singleFreelancer: [],
  addedCard: [],
  getCardList: [],
  deleteCard: [],
  clientDetails: [],
  recentlyFreelancerList: [],
  jobDraftFreelancerList: [],
  jobPostFreelancerList: [],
  hiredFreelancerList: [],
  offeredFreelancerList: [],
  freelancerList: [],
  freelancerIdsList: [],
  reviewWorkAndChange: [],
  addMilestone: [],
  fundMilestone: [],
  declineMilestone: [],
  transactionHistory: [],
  overviewHistory: [],
  getTransactionHistory: [],
  stripeAccountData: [],
  getPaidRequest: [],
  getAllBankAccountList: [],
  deleteBankAccount: [],
  getClientJobNameList: [],
  topRatedFreelancerList: [],
  saveRecentList: [],
  freelancerDashboardData: [],
  freelancerVacationMode: [],
  proposalDecline: [],
  freelancerWorkHistory: [],
  freelancerInviteData: [],
  proposalOrderList: [],
  myStatsList: [],
  directContractDetails: [],
  getDirectContractList: [],
  getDirectContractDetalis: [],
  getDirectContractInputsData: [],
  getDirectContractInputsEditData: [],
  directContactMail: [],
  removeWithdrawDirectContract: [],
  getWithdrawalDataList: [],
  getWithdrawalDataDetails: [],
  getClickedUserType: [],
  declinedDirectContractDetails: [],
  directWorkSubmit: [],
  approveDirectContractWork: [],
  getDirectPaidRequest: [],
  freelancerListStatus: "idle",
  singleFreelancerStatus: "idle"
};

const freelancerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_JOB_BASED_FREELANCER_LIST:
      return {
        ...state,
        jobBasedFreelancerList: action.payload,
      };
    case SET_INVITED_FREELANCER_LIST:
      return {
        ...state,
        invitedFreelancerList: action.payload,
      };
    case SET_FREELANCER_INVITED:
      return {
        ...state,
        invitedFreelancer: action.payload,
      };
    case SET_SAVED_TALENT_LIST:
      return {
        ...state,
        getSavedTalentList: action.payload,
      };
    case SET_SAVED_TALENT:
      return {
        ...state,
        savedTalent: action.payload,
      };
    case SET_REMOVE_SAVED_TALENT:
      return {
        ...state,
        removeSavedTalent: action.payload,
      };
    case SET_MAKE_PRIVATE_JOB:
      return {
        ...state,
        privatePublicJob: action.payload,
      };
    case SET_ALL_JOB_PROPOSALS_LIST:
      return {
        ...state,
        jobBasedProposalsList: action.payload,
      };
    case SET_SAVE_PROPOSAL_IN_SHORTLIST:
      return {
        ...state,
        saveProposalInShortList: action.payload,
      };
    case SET_REMOVE_PROPOSAL_IN_SHORTLIST:
      return {
        ...state,
        removeProposalInShortList: action.payload,
      };
    case SET_PROPOSAL_SHORTLIST:
      return {
        ...state,
        proposalShortlist: action.payload,
      };
    case SET_ARCHIVED_LIST:
      return {
        ...state,
        proposalArchievedlist: action.payload,
      };
    case SET_SAVE_PROPOSAL_IN_ARCHIVED:
      return {
        ...state,
        saveProposalInArchieved: action.payload,
      };
    case SET_REMOVE_PROPOSAL_IN_ARCHIVED:
      return {
        ...state,
        removeProposalInArchieved: action.payload,
      };
    case SET_SINGLE_FREELANCER:
      return {
        ...state,
        singleFreelancer: action.payload,
      };
    case SET_ADD_CARD:
      return {
        ...state,
        addedCard: action.payload,
      };
    case SET_PAYMENT_CARD_LIST:
      return {
        ...state,
        getCardList: action.payload,
      };
    case SET_DELETE_CARD:
      return {
        ...state,
        deleteCard: action.payload,
      };
    case SET_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case SET_RECENT_FREELANCER_LIST:
      return {
        ...state,
        recentlyFreelancerList: action.payload,
      };
    case SET_JOB_DRAFT_FREELANCER_LIST:
      return {
        ...state,
        jobDraftFreelancerList: action.payload,
      };
    case SET_JOB_POST_FREELANCER_LIST:
      return {
        ...state,
        jobPostFreelancerList: action.payload,
      };
    case SET_CLIENT_CONTRACTS_LIST:
      return {
        ...state,
        clientContractList: action.payload,
      };
    case SET_HIRED_FREELANCER_LIST:
      return {
        ...state,
        hiredFreelancerList: action.payload,
      };
    case SET_OFFERED_FREELANCER_LIST:
      return {
        ...state,
        offeredFreelancerList: action.payload,
      };
    case SET_FREELANCER_LIST:
      return {
        ...state,
        freelancerList: action.payload,
      };
    case SET_SELECTED_IDS:
      return {
        ...state,
        freelancerIdsList: action.payload,
      };
    case SET_CHANGE_APPROVE_WORK:
      return {
        ...state,
        reviewWorkAndChange: action.payload,
      };
    case SET_CLIENT_ADD_MILESTONE:
      return {
        ...state,
        addMilestone: action.payload,
      };
    case SET_CLIENT_FUND_MILESTONE:
      return {
        ...state,
        fundMilestone: action.payload,
      };
    case SET_CLIENT_DECLINE_MILESTONE:
      return {
        ...state,
        declineMilestone: action.payload,
      };
    case SET_CLIENT_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.payload,
      };
    case SET_CLIENT_OVERVIEW:
      return {
        ...state,
        overviewHistory: action.payload,
      };
    case GET_FREELANCER_TRANSACTION_HISTORY:
      return {
        ...state,
        getTransactionHistory: action.payload,
      };

    case SET_VERIFICATION_FILES:
      return {
        ...state,
        verificationFiles: [...state.verificationFiles, action.payload],
      };
    case SET_EMPTY_VERIFICATION_FILES:
      return {
        ...state,
        verificationFiles: [],
      };
    case SET_STRIPE_ACCOUNT_DATA:
      return {
        ...state,
        stripeAccountData: action.payload,
      };
    case GET_PAID_REQUEST:
      return {
        ...state,
        getPaidRequest: action.payload,
      };
    case GET_ALL_BANK_ACCOUNT_LIST:
      return {
        ...state,
        getAllBankAccountList: action.payload,
      };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        deleteBankAccount: action.payload,
      };
    case CLIENT_JOB_NAME_LIST:
      return {
        ...state,
        getClientJobNameList: action.payload,
      };
    case SET_TOP_RATED_FREELANCER_LIST:
      return {
        ...state,
        topRatedFreelancerList: action.payload,
      };
    case SET_SAVE_RECENT_FREELANCER_LIST:
      return {
        ...state,
        saveRecentList: action.payload,
      };
    case SET_FREELANCER_DASHBOARD_DATA:
      return {
        ...state,
        freelancerDashboardData: action.payload,
      };
    case FREELANCER_VACATION_MODE:
      return {
        ...state,
        freelancerVacationMode: action.payload,
      };
    case SET_PROPOSAL_DECLINE:
      return {
        ...state,
        proposalDecline: action.payload,
      };
    case SET_FREELANCER_WORK_HISTORY:
      return {
        ...state,
        freelancerWorkHistory: action.payload,
      };
    case FREELANCER_INVITE_DATA:
      return {
        ...state,
        freelancerInviteData: action.payload,
      };
    case PROPOSAL_ORDER_LIST:
      return {
        ...state,
        proposalOrderList: action.payload,
      };
    case MY_STATS:
      return {
        ...state,
        myStatsList: action.payload,
      };
    case DIRECT_CONTRACT_DETAILS:
      return {
        ...state,
        directContractDetails: action.payload,
      };
    case GET_DIRECT_CONTRACT_LIST:
      return {
        ...state,
        getDirectContractList: action.payload,
      };
    case GET_DIRECT_CONTRACT_USER_DETAILS:
      return {
        ...state,
        getDirectContractDetalis: action.payload,
      };
    case DIRECT_CONTRACT_INPUTS_DATA:
      return {
        ...state,
        getDirectContractInputsData: action.payload,
      };
    case DIRECT_CONTRACT_INPUTS_EDIT_DATA:
      return {
        ...state,
        getDirectContractInputsEditData: action.payload,
      };
    case QUICK_OVERVIEW_MAIL:
      return {
        ...state,
        directContactMail: action.payload,
      };
    case REMOVE_WITHDRAW_DIRECT_CONTRACT:
      return {
        ...state,
        removeWithdrawDirectContract: action.payload,
      };
    case GET_WITHDRAWAL_DATA_LIST:
      return {
        ...state,
        getWithdrawalDataList: action.payload,
      };
    case GET_WITHDRAWAL_DATA_DETAILS:
      return {
        ...state,
        getWithdrawalDataDetails: action.payload,
      };
    case DIRECT_CONTRACT_CLICKED_TYPE:
      return {
        ...state,
        getClickedUserType: action.payload,
      };
    case DECLINE_DIRECT_CONTRACT_PAYMENT:
      return {
        ...state,
        declinedDirectContractDetails: action.payload,
      };
    case DIRECT_SUBMIT_WORK:
      return {
        ...state,
        directWorkSubmit: action.payload,
      };
    case APPROVE_DIRECT_CONTRACT_WORK:
      return {
        ...state,
        approveDirectContractWork: action.payload,
      };
    case DIRECT_CONTRACT_PAYMENT_REQUEST:
      return {
        ...state,
        getDirectPaidRequest: action.payload,
      };
    case FREELANCER_ACTION_TYPES.SET_FREELANCER_LIST_STATUS: {
      return {
        ...state,
        freelancerListStatus: action.payload,
      };
    }
    case FREELANCER_ACTION_TYPES.SET_SINGLE_FREELANCER_STATUS: {
      return {
        ...state,
        singleFreelancerStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

export default freelancerReducer;
