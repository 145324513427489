import { ReactNode } from "react";
import Spinner from "react-bootstrap/Spinner";

import { ButtonWrapper } from "./styled";

export type ButtonModifiers =
  | 'primary' // primary button with violet background and white text and small border radius
  | 'primary-rounded' // primary button with violet background and white text and large border radius
  | 'outline-dark' // just black text and no border
  | 'primary-outline' // user dropdown buttons first state
  | 'outline-transparent' // user dropdown buttons second state
  | 'primary-outline-rounded' // rounded button with violet border and violet text and white background
  | 'transparent-rounded' // transparent button for actions icons with custom opacity and border
  | 'primary-underline' // button for tabs with violet underline
  | 'pagination' // pagination buttons
  | 'icon-outline-primary' // round button for icon with violet border


export type ButtonProps = {
  onClick?: (e?: any) => void;
  modifier?: ButtonModifiers;
  className?: string;
  disabled?: boolean;
  blank?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  children?: ReactNode;
  active?: boolean;
  value?: string;
  imageWidth?: number;
  imageHeight?: number;
  imageOpacity?: number;
};

export const Button = ({
  onClick,
  children,
  modifier = 'primary',
  disabled,
  className,
  htmlType = 'submit',
  loading,
  active = false,
  value,
  imageWidth,
  imageHeight,
  imageOpacity
}: ButtonProps) => {
  return (
    <ButtonWrapper imageHeight={imageHeight} imageWidth={imageWidth} imageOpacity={imageOpacity}>
      <button
        type={htmlType}
        className={`${modifier} ${className} ${active ? 'active' : ''} color-${modifier}`}
        onClick={onClick}
        disabled={disabled || loading}
        value={value}
      >
        {!loading && children}
        {loading && <Spinner className='spinner' />}
      </button>
    </ButtonWrapper>
  );
};
