import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "./Components/Loader/LoadingSpinner";
import Footer from "./Components/Footer";
import LoginNavbar from "./Components/Navbar/LoginNavbar";
import AllRoutes from "./Routing/AllRoutes";
import HomeNavbar from "./Components/Navbar/HomeNavbar";
import ScrollToTop from "./Components/ScrollToTop";

import "font-awesome/css/font-awesome.min.css";
import "./Styles/index.scss";
import "./Styles/account-info.css";
import "./Styles/freelancer.css";
import "./Styles/chat.css";
import "./Styles/job.css";
import "./Styles/responsive.css";
import "./Popups/popup.css";

const App = () => {
  const path = useLocation();

  return (
    <>
      <ScrollToTop/>
      <Suspense fallback={<LoadingSpinner/>}>
        <div className="full-height-wrapper">
          {[
            "/login",
            "/signup",
            "/signup/learn-and-earn",
            "/signup/hire-for-project",
            "/verify-signup",
            "/forgot-password",
            "/verify-forgot-otp",
            "/reset-password",
            "/direct-contract-security-check",
            "/direct-declined-contract",
            "/direct-contract-work-status", // ?
            "/direct-contract-thank-you",
          ].includes(path?.pathname) ? null : localStorage.getItem("unify_token") !== null ?
            <LoginNavbar/> : <HomeNavbar/>
          }

          <AllRoutes/>

          {![
            "/login",
            "/signup",
            "/signup/learn-and-earn",
            "/signup/hire-for-project",
            "/verify-signup",
            "/forgot-password",
            "/verify-forgot-otp",
            "/reset-password",
            "/chat",
            "/direct-contract-security-check",
            "/direct-declined-contract",
            "/direct-contract-work-status",
            "/direct-contract-thank-you",
          ].includes(path?.pathname) && (<Footer/>)}
        </div>
      </Suspense>
    </>
  );
}

export default App;
