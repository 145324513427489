import { LoadingType } from "Redux/Reducers/authReducer";


export enum CHAT_ACTION_TYPES {
  ADD_USER_GROUP_LIST = 'CHAT::ADD_USER_GROUP_LIST',
  SET_DELETE_GROUP_USER_LIST = 'CHAT::SET_DELETE_GROUP_USER_LIST',
  SET_CHAT_GROUP_USER_LIST = 'CHAT::SET_CHAT_GROUP_USER_LIST',
  SEARCH_MSG_FOR_ALL_USERS = 'CHAT::SEARCH_MSG_FOR_ALL_USERS',
  SEARCH_FILTER_SINGLE_CHAT = 'CHAT::SEARCH_FILTER_SINGLE_CHAT',
  GENERATE_ZOOM_LINK = 'CHAT::GENERATE_ZOOM_LINK',
  GET_CHAT_HISTORY_FOR_ACTIVE_USER = 'CHAT::GET_CHAT_HISTORY_FOR_ACTIVE_USER',
  GET_ROOM_LIST = 'CHAT::GET_ROOM_LIST',
  CHAT_ATTACHMENT_UPLOAD = 'CHAT::CHAT_ATTACHMENT_UPLOAD',
  MESSAGES = 'CHAT::MESSAGES',
  SET_CHAT_HISTORY_STATUS = 'CHAT::SET_CHAT_HISTORY_STATUS',
  SET_ROOM_LIST_STATUS = 'CHAT::SET_ROOM_LIST_STATUS',
  SET_FILTERED_MESSAGES_STATUS = 'CHAT::SET_FILTERED_MESSAGES_STATUS',
}

export type ChatAction =
  | { type: CHAT_ACTION_TYPES.ADD_USER_GROUP_LIST; payload: any }
  | { type: CHAT_ACTION_TYPES.SET_DELETE_GROUP_USER_LIST; payload: any }
  | { type: CHAT_ACTION_TYPES.SET_CHAT_GROUP_USER_LIST; payload: any }
  | { type: CHAT_ACTION_TYPES.SEARCH_MSG_FOR_ALL_USERS; payload: any }
  | { type: CHAT_ACTION_TYPES.GENERATE_ZOOM_LINK; payload: any }
  | { type: CHAT_ACTION_TYPES.SEARCH_FILTER_SINGLE_CHAT; payload: any }
  | { type: CHAT_ACTION_TYPES.GET_CHAT_HISTORY_FOR_ACTIVE_USER; payload: any }
  | { type: CHAT_ACTION_TYPES.GET_ROOM_LIST; payload: any }
  | { type: CHAT_ACTION_TYPES.CHAT_ATTACHMENT_UPLOAD; payload: any }
  | { type: CHAT_ACTION_TYPES.MESSAGES; payload: any }
  | { type: CHAT_ACTION_TYPES.SET_CHAT_HISTORY_STATUS; payload: LoadingType }
  | { type: CHAT_ACTION_TYPES.SET_ROOM_LIST_STATUS; payload: LoadingType }
  | { type: CHAT_ACTION_TYPES.SET_FILTERED_MESSAGES_STATUS; payload: LoadingType }


  
