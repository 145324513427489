import { LoadingType } from "Redux/Reducers/authReducer";


export enum IDENTITY_ACTION_TYPES {
  SET_HAS_IDENTITY_VERIFICATION = 'IDENTITY::SET_HAS_IDENTITY_VERIFICATION',
  SET_IDENTITY_VERIFICATION_STATUS = 'IDENTITY::SET_IDENTITY_VERIFICATION_STATUS',
  SET_HAS_DOCUMENT_VERIFICATION = 'IDENTITY::SET_HAS_DOCUMENT_VERIFICATION',
  SET_DOCUMENT_VERIFICATION_STATUS = 'IDENTITY::SET_DOCUMENT_VERIFICATION_STATUS',
  SET_VISUAL_VERIFICATION = 'IDENTITY::SET_VISUAL_VERIFICATION',
  SET_VISUAL_VERIFICATION_STATUS = 'IDENTITY::SET_VISUAL_VERIFICATION_STATUS',
  SET_IDENTITY_VERIFICATION_INFO = 'IDENTITY::SET_IDENTITY_VERIFICATION_INFO',
  SET_CHECK_IDENTITY_VERIFICATION_INFO_STATUS = 'IDENTITY::SET_CHECK_IDENTITY_VERIFICATION_INFO_STATUS',
  SET_SEND_IDENTITY_VERIFICATION_PHOTO_STATUS = 'IDENTITY::SET_SEND_IDENTITY_VERIFICATION_PHOTO_STATUS',
  SET_SEND_IDENTITY_VERIFICATION_USER_PHOTO_STATUS = 'IDENTITY::SET_SEND_IDENTITY_VERIFICATION_USER_PHOTO_STATUS',
}

export type AuthAction =
  | { type: IDENTITY_ACTION_TYPES.SET_HAS_IDENTITY_VERIFICATION; payload: boolean }
  | { type: IDENTITY_ACTION_TYPES.SET_IDENTITY_VERIFICATION_STATUS; payload: LoadingType }
  | { type: IDENTITY_ACTION_TYPES.SET_HAS_DOCUMENT_VERIFICATION; payload: boolean }
  | { type: IDENTITY_ACTION_TYPES.SET_DOCUMENT_VERIFICATION_STATUS; payload: LoadingType }
  | { type: IDENTITY_ACTION_TYPES.SET_VISUAL_VERIFICATION; payload: boolean }
  | { type: IDENTITY_ACTION_TYPES.SET_VISUAL_VERIFICATION_STATUS; payload: LoadingType }
  | { type: IDENTITY_ACTION_TYPES.SET_IDENTITY_VERIFICATION_INFO; payload: any }
  | { type: IDENTITY_ACTION_TYPES.SET_CHECK_IDENTITY_VERIFICATION_INFO_STATUS; payload: LoadingType }
  | { type: IDENTITY_ACTION_TYPES.SET_SEND_IDENTITY_VERIFICATION_PHOTO_STATUS; payload: LoadingType }
  | { type: IDENTITY_ACTION_TYPES.SET_SEND_IDENTITY_VERIFICATION_USER_PHOTO_STATUS; payload: LoadingType }



