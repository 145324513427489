import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRouteGroup from "./PrivateRouteGroup";
import AuthRouteGroup from "./AuthRouteGroup";
import LearnRouteGroup from "./LearnRouteGroup";
import { domains } from "../Functions/url";

const PageNotFound           = lazy(() => import("../Pages/PageNotFound"));

const HomeFreelancer    = lazy(() => import("../Pages/HomeFreelancer"));
const HomeClient        = lazy(() => import("../Pages/HomeClient"));
const Home              = lazy(() => import("../Pages/Home"));
const LmsHome           = lazy(() => import("../Pages/Learn/Home"));
const LmsCourse         = lazy(() => import("../Pages/Learn/Course"));
const LmsCourses        = lazy(() => import("../Pages/Learn/Courses"));
const LmsCategories     = lazy(() => import("../Pages/Learn/Categories"));

const JobDetails =        lazy(() => import("../Pages/JobDetails"));
const TextPage =          lazy(() => import("../Pages/TextPage"));
const Subscription =      lazy(() => import("../Client/Subscription/Subscription"));
const HelpSupport =       lazy(() => import("../Client/HelpSupport/HelpSupport"));

const SignUpChat =        lazy(() => import("../Client/SignUpChat"));
const VerifyForgotOTP =   lazy(() => import("../Modules/Auth/VerifyForgotOTP"));
const ForgotPass =        lazy(() => import("../Pages/ForgotPassword"));
const ResetPass =         lazy(() => import("../Modules/Auth/ResetPassword"));
const VerifySignUp =      lazy(() => import("../Modules/Auth/VerifySignUp"));

const SignUp =            lazy(() => import("../Modules/Auth/SignUp"));
const Login =             lazy(() => import("../Modules/Auth/SignIn"));
const HowItWorks =        lazy(() => import("../Pages/HowItWorks"));
const BrowseJobs =        lazy(() => import("../Pages/BrowseJobs"));
const SkillsListCatWise = lazy(() => import("../Pages/SkillsListCatWise"));
const DevListCatWise =    lazy(() => import("../Pages/DevListCatWise"));

const DirectContract         = lazy(() => import("../Freelancer/Pages/DirectContract"));
const DirectContractDetails  = lazy(() => import("../Freelancer/Pages/DirectContractDetails"));
const DirectDeclinedContract = lazy(() => import("../Freelancer/Components/DirectDeclinedContract"));
const DirectReviewContract   = lazy(() => import("../Freelancer/Components/DirectReviewContract"));
const DirectContractThankYou = lazy(() => import("../Freelancer/Components/DirectContract/DirectContractThankYou"));

// Freelancer
const ProjectSearch =             lazy(() => import("../Freelancer/Pages/ProjectSearch/ProjectSearch"));
const ProjectDetail =             lazy(() => import("../Freelancer/Pages/ProjectDetails/ProjectDetails"));
const JobPortal =                 lazy(() => import("../Freelancer/Pages/JobPortal"));
const FreelancerAllContracts =    lazy(() => import("../Freelancer/Pages/AllContracts"));
const FreelancerMyContract =      lazy(() => import("../Freelancer/Pages/MyContract"));
const AllProposals =              lazy(() => import("../Freelancer/Pages/AllProposals"));
const FreelancerBoardManager =    lazy(() => import("../Freelancer/Pages/BoardManager"));
const FreelancerGoals =           lazy(() => import("../Freelancer/Pages/Goals"));
const FreelancerTransaction =     lazy(() => import("../Freelancer/Pages/Transactions"));
const FreelancerWorkDiary =       lazy(() => import("../Freelancer/Pages/WorkDiary"));
const FreelancerTimeSheets =      lazy(() => import("../Freelancer/Pages/TimeSheets"));
const FreelancerOverview =        lazy(() => import("../Freelancer/Pages/Overview"));
const UniCoinHistory =            lazy(() => import("../Freelancer/Pages/UniCoinHistory"));
const BuyUniCoins =               lazy(() => import("../Freelancer/Pages/BuyCoins"));
const FreelancerProgress =        lazy(() => import("../Freelancer/Pages/Progress"));
const FreelancerObjective =       lazy(() => import("../Freelancer/Pages/Objective"));
const FreelancerReports =         lazy(() => import("../Freelancer/Pages/MyReport"));
const FreelancerReportByClient =  lazy(() => import("../Freelancer/Pages/ReportByClient"));
const FreelancerMilestone =       lazy(() => import("../Freelancer/Pages/MileStoneEarning"));
const FreelancerContactInfo =     lazy(() => import("../Freelancer/Pages/Settings/ContactInfo"));
const FreelancerBilling =         lazy(() => import("../Freelancer/Pages/Settings/BillingTab"));
const FreelancerGetPaid =         lazy(() => import("../Freelancer/Pages/Settings/GetPaid"));
const FreelanceMyTeams =          lazy(() => import("../Freelancer/Pages/Settings/MyTeam"));
const FreelancePasswordSecurity = lazy(() => import("../Freelancer/Pages/Settings/Password"));
const FreelanceNotification =     lazy(() => import("../Freelancer/Pages/Settings/Notifications/Notifications"));
const FreelanceIdentity =         lazy(() => import("../Freelancer/Pages/Settings/IdentityVerification"));
const FreelanceMyStats =          lazy(() => import("../Freelancer/Pages/Settings/MyStats"));
const FreelanceBillingEarn =      lazy(() => import("../Freelancer/Pages/BillingEarning"));
const FreelanceProposal =         lazy(() => import("../Freelancer/Pages/SendProposal/SendProposal"));
const FreelancerChat =            lazy(() => import("../Freelancer/Pages/Chat/Chat"));
const FreelancerDashboard =       lazy(() => import("../Freelancer/Pages/Dashboard/Dashboard"));
const UserReports =               lazy(() => import("../Freelancer/Pages/UserReports"));
const SingleInvitation =          lazy(() => import("../Freelancer/Pages/SingleInvitation/SingleInvitation"));
const ProfileInto =               lazy(() => import("../Freelancer/Pages/ProfileIntro"));
const SingleActiveProposal =      lazy(() => import("../Freelancer/Pages/SingleActiveProposal/SingleActiveProposal"));
const SingleSubmittedProposal =   lazy(() => import("../Freelancer/Pages/SingleSubmittedProposal/SingleSubmittedProposal"));
const SingleOfferDetail =         lazy(() => import("../Freelancer/Pages/SingleOfferDetail/SingleOfferDetail"));
const SingleContract =            lazy(() => import("../Freelancer/Pages/SingleContract"));
const ViewOriginalOffer =         lazy(() => import("../Freelancer/Pages/SingleContract/ViewOriginalOffer"));
const ViewOriginalProposal =      lazy(() => import("../Freelancer/Pages/SingleContract/ViewOriginalProposal"));
const GetPaidNow =                lazy(() => import("../Freelancer/Pages/Overview/GetPaidNow"));

const AgencyDashboard =           lazy(() => import("../Agency/Pages/Dashboard"));
const AgencyProfile =             lazy(() => import("../Agency/Profile"));

// Client
const ProposalDetails  =      lazy(() => import("../Client/ProposalDetails"));
const GetStarted =            lazy(() => import("../Client/TalentBYO/GetStarted"));
const TalentDetails =         lazy(() => import("../Client/TalentBYO/TalentDetails"));
const OfferDetails =          lazy(() => import("../Client/TalentBYO/OfferDetails"));
const TalentBYOList =         lazy(() => import("../Client/TalentBYO/TalentBYOList"));
const CreateCategory =        lazy(() => import("../Client/CreateCategory"));
const SingleContracts =       lazy(() => import("../Client/SingleContract"));
const ProfileDashboard =      lazy(() => import("../Client/SettingDashboard"));
const Category =              lazy(() => import("../Client/Category"));
const Question =              lazy(() => import("../Client/Question"));
const QuestionTwo =           lazy(() => import("../Client/QuestionTwo"));
const BusinessSize =          lazy(() => import("../Client/BusinessSize"));
const ClientIntroStepTwo =    lazy(() => import("../Client/Welcome"));
const ExpandTeam =            lazy(() => import("../Client/ExpandTeam"));
const Notification =          lazy(() => import("../Client/Notification"));
const Description =           lazy(() => import("../Client/PostDescription"));
const JobDescription =        lazy(() => import("../Client/JobDescription"));
const ViewJob =               lazy(() => import("../Client/ViewJob"));
const HireFreelancer =        lazy(() => import("../Client/HireFreelancer/HireFreelancer"));
const MyJobs =                lazy(() => import("../Client/MyJobs"));
const AllContracts =          lazy(() => import("../Client/AllContracts/AllContracts"));
const TimeSheets =            lazy(() => import("../Client/TimeSheets/TimeSheets"));
const TalentDiscover =        lazy(() => import("../Client/TalentDiscover"));
const TalentYourHires =       lazy(() => import("../Client/YourHires"));
const TalentBYO =             lazy(() => import("../Client/TalentBYO/TalentBYO"));
const TalentRecent =          lazy(() => import("../Client/TalentRecent"));
const TalentSaved =           lazy(() => import("../Client/TalentSaved"));
const Search =                lazy(() => import("../Client/Search/Search"));
const Message =               lazy(() => import("../Client/Chat"));
const ManagementBoard =       lazy(() => import("../Client/ManagementBoard"));
const TransactionHistory =    lazy(() => import("../Client/TransactionHistory/TransactionHistory"));
const Dashboard =             lazy(() => import("../Client/Dashboard/Dashboard"));
const AddCompanyAddress =     lazy(() => import("../Client/AddCompAddress"));
const EditCompanyAddress =    lazy(() => import("../Client/EditCompanyAddress/EditCompanyAddress"));
const JobListScreen =         lazy(() => import("../Client/AllPosting"));
const Overview =              lazy(() => import("../Client/ClientOverview/ClientOverview"));
const DraftJobListScreen =    lazy(() => import("../Client/AllDraftPostings"));
const PostYourJobScreen =     lazy(() => import("../Client/PostYourJobNow/PostYourJobNow"));
const BoardManager =          lazy(() => import("../Client/BoardManager/BoardManager"));
const EditDraft =             lazy(() => import("../Components/EditDraft"));
const EditPosting =           lazy(() => import("../Components/EditPosting"));
const MakePrivate =           lazy(() => import("../Components/MakePrivate"));
const Testimonial =           lazy(() => import("../Components/Testimonial"));
const ReusePosting  =         lazy(() => import("../Components/ReusePosting"));
const AcceptInvitation      = lazy(() => import("../Components/AcceptInvitation"));

// TODO: check these routes
const FreelancerDetails = lazy(() => import("../Components/FreelancerDetails/FreelancerDetails"));
//const Subscription =        lazy(() => import("../client/Subscription"));
//const HelpSupport =         lazy(() => import("../client/HelpSupport/HelpSupport"));

const AllRoutes = () => {
  const user = JSON.parse(localStorage.getItem("unify_user") || "{}");
  const activeRole = user?.active_role;

  let [domain, subdomain] = domains();

  return (
    <Routes>
      {subdomain === "lms" ? (
        <Route element={<LearnRouteGroup />}>
          <Route path="/" element={<LmsHome/>}/>
          <Route path="/courses" element={<LmsCategories/>}/>
          <Route path="/courses/:category" element={<LmsCourses/>}/>
          <Route path="/courses/:category/:course" element={<LmsCourse/>}/>
        </Route>
      ) : (
        <Route path="/" element={<Home/>}/>
      )}

      <Route path="/learn" element={<LmsHome/>}/>
      <Route path="/learn/courses" element={<LmsCategories/>}/>
      <Route path="/learn/courses/:category" element={<LmsCourses/>}/>
      <Route path="/learn/courses/:category/:course" element={<LmsCourse/>}/>

      <Route path="/home-freelancer" element={<HomeFreelancer/>}/>
      <Route path="/home-client" element={<HomeClient/>}/>
      <Route path="/what-is-unify" element={<HowItWorks/>}/>
      <Route path="/browse-jobs" element={<BrowseJobs/>}/>
      <Route path="/cat-skills/:category" element={<SkillsListCatWise/>}/>
      <Route path="/skill-dev/:skill" element={<DevListCatWise/>}/>

      <Route path="/subscription" element={<Subscription/>}/>
      <Route path="/subscription/:change" element={<Subscription/>}/>
      <Route path="/pages/:pageName" element={<TextPage/>}/>
      <Route path="/help-support" element={<HelpSupport/>}/>
      <Route path="/job-details/:id" element={<JobDetails />} />
      <Route path="/verify-signup" element={<VerifySignUp />} />

      <Route element={<AuthRouteGroup/>}>
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/signup/:type" element={<SignUp/>}/>
        <Route path="/signup/:type/:token" element={<SignUp />} />
        <Route path="/chat-signup/:chat_token/:chat_email" element={<SignUpChat/>}/>
        <Route path="/forgot-password" element={<ForgotPass/>}/>
        <Route path="/verify-forgot-otp" element={<VerifyForgotOTP/>}/>
        <Route path="/reset-password" element={<ResetPass/>}/>
      </Route>

      {/* Common routes */}
      <Route element={<PrivateRouteGroup />}>
        <Route path="/freelancer-details/:freelancer_id" element={<FreelancerDetails />} />
        <Route path="/agency-details/:id" element={<AgencyProfile />} />
        {/* Add client details */}

        <Route path="/direct-declined-contract/:id" element={<DirectDeclinedContract/>}/>
        <Route path="/direct-contract-work-status/:token" element={<DirectContract/>}/>
        <Route path="/direct-contract-thank-you" element={<DirectContractThankYou/>}/>
        <Route path="/direct-contract-security-check" element={<DirectReviewContract/>}/>
        <Route path="/direct-contract-security-check/:token" element={<DirectContract/>}/>
      </Route>

      {(activeRole === "freelancer" || activeRole === "agency") &&
        <Route element={<PrivateRouteGroup />}>
          <Route path="/agency/dashboard" element={<AgencyDashboard/>}/>
          <Route path="/agency-profile" element={<AgencyProfile/>}/>

          <Route path="/freelancer/profile-intro/:tabName" element={<ProfileInto/>}/>

          <Route path="/dashboard" element={activeRole === "agency" ? <AgencyDashboard/> : <FreelancerDashboard/>}/>
          <Route path="/freelancer/project-detail/:id" element={<ProjectDetail/>}/>
          <Route path="/freelancer/project-search" element={<ProjectSearch/>}/>
          <Route path="/freelancer/project-search/:saved" element={<ProjectSearch />} />
          <Route path="/freelancer/job-portal" element={<JobPortal/>}/>
          <Route path="/freelancer/all-contracts" element={<FreelancerAllContracts/>}/>
          <Route path="/freelancer/my-contract" element={<FreelancerMyContract/>}/>
          <Route path="/freelancer/all-proposals" element={<AllProposals/>}/>
          <Route path="/freelancer/board-manager/:contract_id" element={<FreelancerBoardManager/>}/>
          <Route path="/freelancer/work-diary" element={<FreelancerWorkDiary/>}/>
          <Route path="/freelancer/time-sheets" element={<FreelancerTimeSheets/>}/>
          <Route path="/freelancer/goals" element={<FreelancerGoals/>}/>
          <Route path="/freelancer/overview" element={<FreelancerOverview/>}/>
          <Route path="/freelancer/transaction-history" element={<FreelancerTransaction/>}/>
          <Route path="/freelancer/unicoin-history" element={<UniCoinHistory/>}/>
          <Route path="/freelancer/buy-unicoins" element={<BuyUniCoins/>}/>
          <Route path="/freelancer/progress" element={<FreelancerProgress/>}/>
          <Route path="/freelancer/objective" element={<FreelancerObjective/>}/>
          <Route path="/freelancer/my-reports" element={<UserReports/>}/>
          <Route path="/freelancer/report-by-client" element={<FreelancerReportByClient/>}/>
          <Route path="/freelancer/weekly-report" element={<FreelancerReports/>}/>
          <Route path="/freelancer/milestone-earning" element={<FreelancerMilestone/>}/>
          <Route path="/settings/contact-info" element={<FreelancerContactInfo/>}/>
          <Route path="/settings/billing-payment" element={<FreelancerBilling/>}/>
          <Route path="/get-paid" element={<FreelancerGetPaid/>}/>
          <Route path="/settings/my-teams" element={<FreelanceMyTeams/>}/>
          <Route path="/settings/password-security" element={<FreelancePasswordSecurity/>}/>
          <Route path="/settings/notifications" element={<FreelanceNotification/>}/>
          <Route path="/settings/identity-verification" element={<FreelanceIdentity/>}/>
          <Route path="/settings/my-stats" element={<FreelanceMyStats/>}/>
          <Route path="/freelancer/billing-earning" element={<FreelanceBillingEarn/>}/>
          <Route path="/freelancer/send-proposal/:id" element={<FreelanceProposal/>}/>
          <Route path="/freelancer/single-invitation/:id" element={<SingleInvitation/>} />
          <Route path="/freelancer/single-offer-detail/:id" element={<SingleOfferDetail/>} />
          <Route path="/freelancer/single-contract/:id" element={<SingleContract/>}/>
          <Route path="/freelancer/single-contract/:id/:tab" element={<SingleContract/>}/>
          <Route path="/freelancer/single-active-proposal/:id" element={<SingleActiveProposal/>}/>
          <Route path="/freelancer/single-submitted-proposal/:id" element={<SingleSubmittedProposal/>}/>
          <Route path="/freelancer/view-original-offer" element={<ViewOriginalOffer/>}/>
          <Route path="/freelancer/view-original-proposal" element={<ViewOriginalProposal/>}/>
          <Route path="/freelancer/get-paid-now" element={<GetPaidNow/>}/>
          <Route path="/direct-contract" element={<DirectContract/>}/>
          <Route path="/notifications" element={<Notification/>}/>

          <Route path="/chat" element={<FreelancerChat/>}/>
          <Route path="/chat/:projectId/:receiverId/:senderId" element={<FreelancerChat/>}/>
          {/* <Route path="/freelancer/see-milestone" element={<SeeMilestone/>}/> */}
        </Route>
      }

      {activeRole === "client" &&
        <Route element={<PrivateRouteGroup />}>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/dashboard/:project_id" element={<Dashboard/>}/>

          <Route path="/welcome" element={<ClientIntroStepTwo/>}/>
          <Route path="/business-size/:update" element={<BusinessSize/>}/>
          <Route path="/lets-start" element={<Question/>}/>
          <Route path="/scope" element={<QuestionTwo/>}/>

          <Route path="/post-your-job" element={<PostYourJobScreen/>}/>
          <Route path="/job-description" element={<JobDescription/>}/>
          <Route path="/category" element={<Category/>}/>
          <Route path="/create-category" element={<CreateCategory/>}/>
          <Route path="/expand-team" element={<ExpandTeam/>}/>
          <Route path="/notifications" element={<Notification/>}/>
          <Route path="/view-job/:jobId/:tabs" element={<ViewJob/>}/>
          <Route path="/view-job/private" element={<MakePrivate/>}/>
          <Route path="/edit-posting/:editId" element={<EditPosting/>}/>
          <Route path="/edit-draft/:draftId" element={<EditDraft/>}/>
          <Route path="/reuse-posting/:reuseId" element={<ReusePosting/>}/>
          <Route path="/proposal-details/:proposal_id/:freelancer_id/:type" element={<ProposalDetails/>}/>
          <Route path="/hire-freelancer/:freelancer_id" element={<HireFreelancer/>}/>
          <Route path="/hire-freelancer/:project_id/:freelancer_id" element={<HireFreelancer/>}/>
          <Route path="/hire-freelancer/:project_id/:freelancer_id/:proposal_id/:type" element={<HireFreelancer/>}/>
          <Route path="/hire-freelancer/addAddress" element={<AddCompanyAddress/>}/>
          <Route path="/make-payment/:freelancer_id" element={<EditCompanyAddress/>}/>
          <Route path="/make-payment/:freelancer_id/:project_id" element={<EditCompanyAddress/>}/>
          <Route path="/make-payment/:freelancer_id/:project_id/:proposal_id/:type" element={<EditCompanyAddress/>}/>
          <Route path="/my-jobs" element={<MyJobs/>}/>
          <Route path="/all-contracts" element={<AllContracts/>}/>
          <Route path="/time-sheets" element={<TimeSheets/>}/>
          <Route path="/single-contracts/:buttonStatus/:contract_id" element={<SingleContracts/>}/>
          <Route path="/talent-discover" element={<TalentDiscover/>}/>
          <Route path="/talent-your-hires" element={<TalentYourHires/>}/>
          <Route path="/byo-talent-list" element={<TalentBYOList />} />
          <Route path="/byo-talent" element={<TalentBYO/>}/>
          <Route path="/byo-talent/get-started" element={<GetStarted/>}/>
          <Route path="/byo-talent/offer-details/:contract_type" element={<OfferDetails/>}/>
          <Route path="/byo-talent/talent-details" element={<TalentDetails/>}/>
          <Route path="/talent-recently-viewed" element={<TalentRecent/>}/>
          <Route path="/talent-saved" element={<TalentSaved/>}/>
          <Route path="/search" element={<Search/>}/>
          <Route path="/board-manager/:contract_id" element={<BoardManager/>}/>
          <Route path="/profile-setting" element={<ProfileDashboard/>}/>
          <Route path="/message" element={<Message/>}/>
          <Route path="/management-board" element={<ManagementBoard/>}/>
          <Route path="/transaction-history" element={<TransactionHistory/>}/>
          <Route path="/overview" element={<Overview/>}/>
          <Route path="/post-description" element={<Description/>}/>
          <Route path="/all-job-list" element={<JobListScreen/>}/>
          <Route path="/all-draft-job-list" element={<DraftJobListScreen/>}/>
          <Route path="/submit-testimonial/:id" element={<Testimonial/>}/>
          <Route path="/accept-invitation/:id" element={<AcceptInvitation/>}/>
          <Route path="/direct-contract" element={<DirectContract/>}/>
          <Route path="/direct-contract/:type/:id" element={<DirectContractDetails/>}/>
          <Route path="/chat" element={<FreelancerChat />} />
          <Route path="/chat/:projectId/:receiverId/:senderId" element={<FreelancerChat/>}/>

          {/*
          <Route path="/hire-freelancer/edit-address" element={<EditCompanyAddress />}/>
          <Route path="/help-support" element={<HelpSupport />} />
          */}
        </Route>
      }

      <Route path='*' element={<PageNotFound/>} />
    </Routes>
  );
};

export default AllRoutes;