import React from "react";
import { Navigate, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { url } from "../Functions/url";

const LearnRouteGroup = () => {
  let [searchParams] = useSearchParams();
  if (searchParams.has('token')) {
    //localStorage.setItem("unify_token", searchParams.get('token') || "");
    //window.location.href = url("/", "lms");
  }

  const auth = localStorage.getItem("unify_token");

  if (!auth) {
    //window.location.href = url("login?target=lms", "");
    window.location.href = url("login");
  }

  return <Outlet />
};

export default LearnRouteGroup;