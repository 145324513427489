import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectSubscriptionReducer = (state: RootState) => state.subscription;

const selectCancelSubscription = createSelector(selectSubscriptionReducer, (data) => data.cancelSubscription);
const selectChangeSubscription = createSelector(selectSubscriptionReducer, (data) => data.changeSubscription);
const selectSubscriptionList = createSelector(selectSubscriptionReducer, (data) => data.subscriptionList);

export {
  selectCancelSubscription,
  selectChangeSubscription,
  selectSubscriptionList
};