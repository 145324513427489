import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import jobReducer from "./jobReducer";
import subscriptionReducer from "./subscriptionReducer";
import freelancerReducer from "./freelancerReducer";
import managementReducer from "./managementReducer";
import agencyReducer from "./agencyReducer";
import notificationReducer from "./notificationReducer";
import chatReducer from "./chatReducer";
import clientReducer from "./clientReducer";
import identityReducer from "./identityReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  job: jobReducer,
  subscription: subscriptionReducer,
  freelancer: freelancerReducer,
  management: managementReducer,
  agency: agencyReducer,
  notification: notificationReducer,
  chat: chatReducer,
  client: clientReducer,
  identity: identityReducer
});

export default rootReducer;