import {
  SET_BYO_HIRED_LIST,
  SET_BYO_HIRED_OFFER_LIST,
  SET_BYO_TALENT_VALUES,
  SET_DELETE_BYO_HIRED,
  SET_MILESTONE_DATA,
  SET_TOOLTIP_DATA,
  SET_TOOLTIP_OPEN,
} from "../Types";

const initialState = { 
  showTooltip: 0,
  tooltipData: {},
  byoHiredList: {},
  deleteHiredTalent: {},
  getHiredTalentOffer: {},
  getByoValues: {},
  milestoneData: {},
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BYO_HIRED_LIST:
      return {
        ...state,
        byoHiredList: action.payload,
      };
    case SET_DELETE_BYO_HIRED:
      return {
        ...state,
        deleteHiredTalent: action.payload,
      };
    case SET_BYO_HIRED_OFFER_LIST:
      return {
        ...state,
        getHiredTalentOffer: action.payload,
      };
    case SET_BYO_TALENT_VALUES:
      return {
        ...state,
        getByoValues: action.payload,
      };
    case SET_MILESTONE_DATA:
      return {
        ...state,
        milestoneData: action.payload,
      };
    case SET_TOOLTIP_OPEN:
      return {
        ...state,
        showTooltip: action.payload,
      };
    case SET_TOOLTIP_DATA:
      return {
        ...state,
        tooltipData: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
