import { CHAT_ACTION_TYPES } from "../../Redux/Types/chatTypes";
import socket from "../../SocketConnection";
import ErrorPopup from "../../Freelancer/Components/Popups/ErrorPopup";
import { Axios } from "../Axios";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("unify_token")}`,
  },
};

export const setRoomsListStatus = ({
  status
}) => {
  return {
    payload: status,
    type: CHAT_ACTION_TYPES.SET_ROOM_LIST_STATUS,
  };
}


export const getRoomsList =
  (data, setPopup) => async (dispatch) => {
    if (!data.search) {
      dispatch(setRoomsListStatus({
        status: 'loading'
      }))
    }
    await Axios.get(`/chat-room-list`, {
      params: data,
      ...config,
    })
      .then((res) => {
        if (data.type === "users") {
          dispatch({
            type: CHAT_ACTION_TYPES.GET_ROOM_LIST,
            payload: res.data,
          });
        }
        if (data.type === "message") {
          dispatch({
            type: CHAT_ACTION_TYPES.SEARCH_MSG_FOR_ALL_USERS,
            payload: res.data.data,
          });
        }
        dispatch(setRoomsListStatus({
          status: 'idle'
        }))
      })
      .catch((err) => {
        dispatch(setRoomsListStatus({
          status: err.message
        }))
        if (setPopup) {
          setPopup(<ErrorPopup popup={setPopup} message={err.message} />);
        }
      });
  };

export const setChatHistoryStatus = ({
  status
}) => {
  return {
    payload: status,
    type: CHAT_ACTION_TYPES.SET_CHAT_HISTORY_STATUS,
  };
}

export const getChatHistoryForActiveUser =
  (data) => async (dispatch) => {
    dispatch(setChatHistoryStatus({
      status: 'loading'
    }));
    await Axios.post(`/get-all-messages`, data, config)
      .then((res) => {
        dispatch({
          type: CHAT_ACTION_TYPES.GET_CHAT_HISTORY_FOR_ACTIVE_USER,
          payload: res.data,
        });
        dispatch(setChatHistoryStatus({
          status: 'idle'
        }));
      })
      .catch((err) => {
        dispatch(setChatHistoryStatus({
          status: err.message
        }));
      });
  };

export const setFilteredMessagesStatus = ({
  status,
}) => {
  return {
    payload: status,
    type: CHAT_ACTION_TYPES.SET_FILTERED_MESSAGES_STATUS,
  }
}

export const getFilteredMessages =
  (data, setLoading, setPopup) => async (dispatch) => {
    dispatch(setFilteredMessagesStatus({
      status: 'loading'
    }))
    await Axios.post(`/get-all-messages`, data, config)
      .then((res) => {
        dispatch({
          type: CHAT_ACTION_TYPES.SEARCH_FILTER_SINGLE_CHAT,
          payload: res.data.data,
        });
        dispatch(setFilteredMessagesStatus({
          status: 'idle'
        }))
      })
      .catch((err) => {
        dispatch(setFilteredMessagesStatus({
          status: err.message
        }))
      });
  };

export const generateZoomLink =
  (data, setLoading, setPopup) => async (dispatch) => {
    await Axios.post(`/generate-zoom-meeting`, data, config)
      .then((res) => {
        if (res.data?.data?.start_url) {
          dispatch({
            type: CHAT_ACTION_TYPES.GENERATE_ZOOM_LINK,
            payload: res.data.data.start_url,
          });

          socket?.emit("sendMessageWeb", {
            message: JSON.stringify({
              to_sender: res.data.data.start_url,
              to_receiver: res.data.data.join_url,
            }),
            sender_id: data.user_id,
            type: "zoom_link",
            receiver_id: data?.receiver_id,
            project_id: data?.project_id,
            action_type: "new_msg",
            room_id: data?.room_id,
          });
          if (setLoading) {
            setLoading(false);
          }
        } else {
          setPopup(<ErrorPopup popup={setPopup} message={res.data.message} />);
        }
      })
      .catch((err) => {
        if (setLoading) {
          setLoading(false);
        }
        if (setPopup) {
          setPopup(<ErrorPopup popup={setPopup} message={err.message} />);
        }
      });
  };

export const onAddUserInGroup =
  (data, Popup, setLoading) => async (dispatch) => {
    await Axios.post(`/add-user-in-group`, data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: CHAT_ACTION_TYPES.ADD_USER_GROUP_LIST,
            payload: res.data,
          });
          Popup();

          if (setLoading) {
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        if (Popup) {
          Popup(
            <ErrorPopup popup={Popup} message={err.response.data.message} />
          );
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const getGroupUsersList = (data, setLoading) => async (dispatch) => {
  await Axios.post(`/get-group-users`, data, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CHAT_ACTION_TYPES.SET_CHAT_GROUP_USER_LIST,
          payload: res.data.data,
        });
        if (setLoading) {
          setLoading(false);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteGroupUsersList =
  (data, Popup, setLoading) => async (dispatch) => {
    await Axios.post(`/delete-group-user`, data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: CHAT_ACTION_TYPES.SET_DELETE_GROUP_USER_LIST,
            payload: res.data,
          });
          Popup();
          if (setLoading) {
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
