import { Link } from "react-router-dom";

const LmsAccessPopup = ({ Popup }) => {
  return (
    <>
      <div className="bg_wrapper_popup_new">
        <div className="popup_box_bpn paypopradouy cancleSubsPopupBox">
          <div className="popup_header subsPopup">
            <div className="p_header_hding addpay_header_hdin cancleSubHead addpayrhdin">
              Unlock the Learning Platform
            </div>
            <div className="cancleSubHead2 text-center">
              To unlock the Learning Platform, you’ll need to level up your subscription plan.
            </div>
          </div>
          <div className="theme_btns d-flex align-items-center justify-content-between subsCancelSubs">
            <button
              className="first_button subsCancelSubsbtn subsCancrightubsbtn"
              style={{ marginTop: "0px" }}
              onClick={() => Popup()}
            >
              Cancel
            </button>
            <Link to="/settings/billing-payment" onClick={() => Popup()}>
              <button
                className="second_button subsCancelSubsbtn"
                style={{ marginTop: "0px" }}
              >
                Upgrade
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default LmsAccessPopup;
