import React from "react";
import Modal from "react-responsive-modal";
import { Link, useLocation } from "react-router-dom";

const CheckPaymentBeforeJobPosted = ({ open, onCloseModal }) => {
  const location = useLocation();
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{ modal: "check_payment_popup" }}
        center
      >
        <div className="payment_check_cont">
          Hey there! Before you can unleash your awesome job post, we just need to make sure your payment method is good to go. Let's get this show on the road!
        </div>
        <div className="payment_method_btn">
          {location.pathname === "/dashboard" ? (
            <Link to="/post-your-job">
              <button className="close_payment_btn" onClick={onCloseModal}>
                Close
              </button>
            </Link>
          ) : (
            <button className="close_payment_btn" onClick={onCloseModal}>
              Close
            </button>
          )}
          <Link to="/profile-setting?activeTab=setting-billing">
            <button className="add_payment" onClick={() => onCloseModal()}>
              Add Card
            </button>
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default CheckPaymentBeforeJobPosted;
