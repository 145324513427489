import { CountryList, FooterData, HeaderData, HomePageData, LoadingType } from "Redux/Reducers/authReducer";

import { StateList } from "../Reducers/authReducer";

export enum AUTH_ACTION_TYPES {
  HOME_PAGE_DATA = 'AUTH::HOME_PAGE_DATA',
  HEADER_DATA = 'AUTH::HEADER_DATA',
  FOOTER_DATA = 'AUTH::FOOTER_DATA',
  CATEGORY_SKILL_DATA = 'AUTH::CATEGORY_SKILL_DATA',
  SKILLS_DEVELOPER_DATA = 'AUTH::SKILLS_DEVELOPER_DATA',
  SET_COUNTRY = 'AUTH::SET_COUNTRY',
  SET_STATE_LIST = 'AUTH::SET_STATE_LIST',
  APPLE_SIGN_IN_FAIL = 'AUTH::APPLE_SIGN_IN_FAIL',
  GOOGLE_SIGN_IN_FAIL = 'AUTH::GOOGLE_SIGN_IN_FAIL',
  TEXT_PAGE_DATA = 'AUTH::TEXT_PAGE_DATA',
  RESEND_OTP_SUCCESS = 'AUTH::RESEND_OTP_SUCCESS',
  INSTANT_LOGIN_EMAIL = 'AUTH::INSTANT_LOGIN_EMAIL',
  POST_JOB_LIKE_THIS = 'AUTH::POST_JOB_LIKE_THIS',
  CLIENT_BUSINESS_SIZE = 'AUTH::CLIENT_BUSINESS_SIZE',
  SET_ONLINE_STATUS = 'AUTH::SET_ONLINE_STATUS',
  SET_ALL_USER_DETAILS = 'AUTH::SET_ALL_USER_DETAILS',
  SET_LMS_CERTIFICATE = 'AUTH::SET_LMS_CERTIFICATE',
  SET_EMAIL_DETAILS = 'AUTH::SET_EMAIL_DETAILS',
  // loaders
  SET_HOME_PAGE_DATA_STATUS = 'AUTH::SET_HOME_PAGE_DATA_STATUS',
  SET_COUNTRY_STATUS = 'AUTH::SET_COUNTRY_STATUS',
  SET_STATE_STATUS = 'AUTH::SET_STATE_STATUS',
  LOGIN_STATUS = 'AUTH::LOGIN_STATUS',
  TEXT_PAGE_DATA_STATUS = 'AUTH::TEXT_PAGE_DATA_STATUS',
  SET_ONLINE_STATUS_CHANGE = 'AUTH::SET_ONLINE_STATUS_CHANGE',
  SET_ALL_USER_DETAILS_STATUS = 'AUTH::SET_ALL_USER_DETAILS_STATUS',
  USER_CHANGE_STATUS = 'AUTH::USER_CHANGE_STATUS',
}

export type AuthAction =
  | { type: AUTH_ACTION_TYPES.HOME_PAGE_DATA; payload: HomePageData }
  | { type: AUTH_ACTION_TYPES.HEADER_DATA; payload: HeaderData }
  | { type: AUTH_ACTION_TYPES.FOOTER_DATA; payload: FooterData }
  | { type: AUTH_ACTION_TYPES.CATEGORY_SKILL_DATA; payload: any }
  | { type: AUTH_ACTION_TYPES.SKILLS_DEVELOPER_DATA; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_COUNTRY; payload: CountryList }
  | { type: AUTH_ACTION_TYPES.SET_STATE_LIST; payload: StateList }
  | { type: AUTH_ACTION_TYPES.APPLE_SIGN_IN_FAIL; payload: any }
  | { type: AUTH_ACTION_TYPES.GOOGLE_SIGN_IN_FAIL; payload: any }
  | { type: AUTH_ACTION_TYPES.TEXT_PAGE_DATA; payload: any }
  | { type: AUTH_ACTION_TYPES.RESEND_OTP_SUCCESS; payload: any }
  | { type: AUTH_ACTION_TYPES.INSTANT_LOGIN_EMAIL; payload: any }
  | { type: AUTH_ACTION_TYPES.POST_JOB_LIKE_THIS; payload: any }
  | { type: AUTH_ACTION_TYPES.CLIENT_BUSINESS_SIZE; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_ONLINE_STATUS; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_LMS_CERTIFICATE; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_EMAIL_DETAILS; payload: any }
  | { type: AUTH_ACTION_TYPES.SET_HOME_PAGE_DATA_STATUS; payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.SET_COUNTRY_STATUS; payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.SET_STATE_STATUS; payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.LOGIN_STATUS; payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.TEXT_PAGE_DATA_STATUS, payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.SET_ONLINE_STATUS_CHANGE, payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS_STATUS, payload: LoadingType }
  | { type: AUTH_ACTION_TYPES.USER_CHANGE_STATUS, payload: LoadingType }