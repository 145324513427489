import {useEffect, useState, useRef} from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import socket from "../../SocketConnection";
import { useSelector } from "react-redux";
import { ReactComponent as DirectIcon } from "../../Assets/icons/direct.svg";

const ResponsiveNavbar = ({
  allUserDetails,
  isDownOpen,
  ToggleDown,
  handleLogout,
  handleSwitchAccount,
  agencyDetails,
  handleOnlineStatus,
  clientDetails,
  systemNotification,
  freelancerDetails,
}) => {
  const [totalUnseenMsg, setTotalUnseenMsg] = useState(0);
  const loginUserIdRefH = useRef(null);
  const [loginUserIdH, setLoginUserId] = useState(null);
  const allUserDetailsH = useSelector((state) => state.auth.allUserDetails);

  const chatRoomsList = useSelector(
    (state) => state?.chat?.chatRoomsList?.data
  );

  let total = 0;
  const calculateUnseenMessages = () => {
    chatRoomsList?.forEach((element) => {
      total = total = +element?.unseen_messages;
    });
    setTotalUnseenMsg(total);
  };

  useEffect(() => {
    calculateUnseenMessages();
  }, [chatRoomsList]);

  useEffect(() => {
    loginUserIdRefH.current = loginUserIdH;
  }, [loginUserIdH]);

  useEffect(() => {
    socket?.on("newMsgNotification", (newMsgNotification) => {
      if (newMsgNotification?.receiver_id === loginUserIdRefH?.current) {
        setTotalUnseenMsg(Number(totalUnseenMsg) + 1);
      }
    });
    return () => {
      socket?.on("newMsgNotification", (newMsgNotification) => {
        if (newMsgNotification?.receiver_id === loginUserIdRefH?.current) {
          setTotalUnseenMsg(Number(totalUnseenMsg) + 1);
        }
      });
    };
  }, [totalUnseenMsg]);

    // set login details in state
    useEffect(() => {
      if (allUserDetailsH) {
        const userDetails =
          allUserDetailsH?.active_role === "freelancer"
            ? allUserDetailsH?.basicInfo
            : allUserDetailsH?.active_role === "client"
              ? allUserDetailsH?.client
              : allUserDetailsH?.active_role === "agency"
                ? allUserDetailsH?.agency
                : null;
  
        const sender_id =
          allUserDetailsH?.active_role === "agency"
            ? userDetails?.agency_id
            : userDetails?.id;
        setLoginUserId(Number(sender_id));
      }
    }, [allUserDetailsH]);

  return (
    <AllNavigationToggleBtnHolder className="navv2verso_flxewd">
      <NotificationHolder>
        {allUserDetails?.active_role === "freelancer" && (
          <a
            href="/direct-contract"
            className="navbar_btn position-relative nav-icon"
          >
            <DirectIcon></DirectIcon>
          </a>
        )}
        <a
          href="/chat"
          className="navbar_btn position-relative nav-icon"
        >
          {totalUnseenMsg > 0 && (
            <span className="unseen_count message_unseen_count">
              {totalUnseenMsg}
            </span>
          )}
          <i className="bi bi-envelope font-size-20px"></i>
        </a>
        <a
          href="/notifications"
          className="navbar_btn position-relative nav-icon"
        >
          {systemNotification?.un_seen > 0 && (
            <span className="unseen_count notification_unseen_count">
              {systemNotification?.un_seen}
            </span>
          )}
          <i className="bi bi-bell font-size-20px"></i>
        </a>
      </NotificationHolder>
      <div className="nav_pro_node">
        <div className="nav_profile online_profile">
          {allUserDetails?.active_role === "client" ? (
            <img src={clientDetails?.profile_image} alt="" />
          ) : allUserDetails?.active_role === "freelancer" ? (
            <img src={freelancerDetails?.profile_image} alt="" />
          ) : allUserDetails?.active_role === "agency" ? (
            <img src={agencyDetails?.profile_image} alt="" />
          ) : null}
        </div>
        <NavDropdown
          className="text-center nav_dropdown_profile custom_nav_profile_drp"
          id="basic-nav-dropdown"
          title="">
          <div className="d-flex px-2">
            <div className="nav_p_d_profile">
              {allUserDetails?.active_role === "client" ? (
                <img src={clientDetails?.profile_image} alt="" />
              ) : allUserDetails?.active_role === "freelancer" ? (
                <img src={freelancerDetails?.profile_image} alt="" />
              ) : allUserDetails?.active_role === "agency" ? (
                <img src={agencyDetails?.profile_image} alt="" />
              ) : null}
            </div>
            <div>
              <div className="pro_name_drop_u">
                {allUserDetails?.active_role === "client" ? (
                  <>
                    {clientDetails?.first_name} {clientDetails?.last_name}
                  </>
                ) : allUserDetails?.active_role === "freelancer" ? (
                  <>
                    {freelancerDetails?.first_name}{" "}
                    {freelancerDetails?.last_name}
                  </>
                ) : allUserDetails?.active_role === "agency" ? (
                  <>{agencyDetails?.name}</>
                ) : null}
              </div>
              <div className="pro_o_nme">
                {allUserDetails?.active_role === "client"
                  ? "Client"
                  : allUserDetails?.active_role === "freelancer"
                    ? "Freelancer"
                    : allUserDetails?.active_role === "agency"
                      ? "agency"
                      : null}
              </div>
            </div>
          </div>
          <div className="drop_p_o_i pb-3 border-top-agencies">
            <button
              className={
                allUserDetails?.active_role === "client"
                  ? clientDetails?.online_status === "online"
                    ? "active_drop_poi"
                    : ""
                  : allUserDetails?.active_role === "freelancer"
                    ? freelancerDetails?.online_status === "online"
                      ? "active_drop_poi"
                      : ""
                    : allUserDetails?.active_role === "agency"
                      ? agencyDetails?.online_status === "online"
                        ? "active_drop_poi"
                        : ""
                      : null
              }
              value={"online"}
              onClick={handleOnlineStatus}
            >
              Online
            </button>
            <button
              className={
                allUserDetails?.active_role === "client"
                  ? clientDetails?.online_status === "invisible"
                    ? "active_drop_poi"
                    : ""
                  : allUserDetails?.active_role === "freelancer"
                    ? freelancerDetails?.online_status === "invisible"
                      ? "active_drop_poi"
                      : ""
                    : allUserDetails?.active_role === "agency"
                      ? agencyDetails?.online_status === "invisible"
                        ? "active_drop_poi"
                        : ""
                      : null
              }
              onClick={handleOnlineStatus}
              value={"invisible"}
            >
              Invisible
            </button>
          </div>

          {allUserDetails?.active_role === "freelancer" ? (
            <>
              {allUserDetails?.is_agency && (
                <div
                  className="d-flex px-2 py-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("agency")}
                >
                  <div className="nav_p_d_profile">
                    <img src={allUserDetails?.agency_profile?.profile_image} alt=""/>
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.agency_profile?.name}
                    </div>
                    <div className="pro_o_nme">Agency</div>
                  </div>
                </div>
              )}
              {allUserDetails?.is_client && (
                <div
                  className="d-flex mb-3 px-2 py-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("client")}
                >
                  <div className="nav_p_d_profile">
                    <img
                      src={allUserDetails?.client_profile?.profile_image}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.client_profile?.name}
                    </div>
                    <div className="pro_o_nme">Client</div>
                  </div>
                </div>
              )}
            </>
          ) : allUserDetails?.active_role === "client" ? (
            <>
              {allUserDetails?.is_freelancer && (
                <div
                  className="d-flex mb-3 px-2 py-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("freelancer")}
                >
                  <div className="nav_p_d_profile">
                    <img
                      src={allUserDetails?.freelancer_profile?.profile_image}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.freelancer_profile?.name}
                    </div>
                    <div className="pro_o_nme">Freelancer</div>
                  </div>
                </div>
              )}
              {allUserDetails?.is_agency && (
                <div
                  className="d-flex px-2 pb-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("agency")}
                >
                  <div className="nav_p_d_profile">
                    <img
                      src={allUserDetails?.agency_profile?.profile_image}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.agency_profile?.name}
                    </div>
                    <div className="pro_o_nme">Agency</div>
                  </div>
                </div>
              )}
            </>
          ) : allUserDetails?.active_role === "agency" ? (
            <>
              {allUserDetails?.is_client && (
                <div
                  className="d-flex mb-3 px-2 py-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("client")}
                >
                  <div className="nav_p_d_profile">
                    <img
                      src={allUserDetails?.client_profile?.profile_image}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.client_profile?.name}
                    </div>
                    <div className="pro_o_nme">Client</div>
                  </div>
                </div>
              )}
              {allUserDetails?.is_freelancer && (
                <div
                  className="d-flex mb-3 px-2 pb-3 border-top-agencies"
                  onClick={() => handleSwitchAccount("freelancer")}
                >
                  <div className="nav_p_d_profile">
                    <img
                      src={allUserDetails?.freelancer_profile?.profile_image}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="pro_name_drop_u">
                      {allUserDetails?.freelancer_profile?.name}
                    </div>
                    <div className="pro_o_nme">Freelancer</div>
                  </div>
                </div>
              )}
            </>
          ) : null}

          {allUserDetails?.is_profile_complete && (
            <Link
              className="drop_li_poi"
              to={
                allUserDetails?.active_role === "freelancer"
                  ? "/settings/contact-info"
                  : allUserDetails?.active_role === "client"
                    ? "/profile-setting"
                    : allUserDetails?.active_role === "agency"
                      ? "/settings/contact-info"
                      : null
              }
            >
              <i className="bi bi-gear font-size-20px"></i>
              <span>Settings</span>
            </Link>
          )}

          <div className="drop_li_poi">
            <i className="bi bi-box-arrow-right font-size-20px"></i>
            <span onClick={handleLogout}>Log Out</span>
          </div>
        </NavDropdown>
      </div>
      <NavigationToggleBtnHolder>
        {!allUserDetails?.is_profile_complete ? null : (
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={`${isDownOpen === true ? "close_iconsvgciweuw" : ""}`}
            onClick={() => ToggleDown()}
          />
        )}
      </NavigationToggleBtnHolder>
    </AllNavigationToggleBtnHolder>
  );
};
export default ResponsiveNavbar;

export const NavigationToggleBtnHolder = styled.div`
  margin-left: 10px;
`;

export const AllNavigationToggleBtnHolder = styled.div`
  position: relative;
  right: 0px;

  .nav_pro_node {
    margin-left: 10px;
  }
`;

export const NotificationHolder = styled.div`
  display: flex;
`;