import { FooterData} from "Redux/Reducers/authReducer";

export enum AGENCY_ACTION_TYPES {
  SET_AGENCY_PROFILE = 'AGENCY::SET_AGENCY_PROFILE',
  AGENCY_SUB_TEAM_DELETE = 'AGENCY::AGENCY_SUB_TEAM_DELETE',
  AGENCY_TEAM_LIST = 'AGENCY::AGENCY_TEAM_LIST',
  AGENCY_TEAM_MEMBERS_LIST = 'AGENCY::AGENCY_TEAM_MEMBERS_LIST',
  AGENCY_TEAM_MEMBER_ADD = 'AGENCY::AGENCY_TEAM_MEMBER_ADD',
  AGENCY_TEAM_MEMBER_DELETE = 'AGENCY::AGENCY_TEAM_MEMBER_DELETE',
  AGENCY_TITLE_AND_OVERVIEW = 'AGENCY::AGENCY_TITLE_AND_OVERVIEW',
  DELETE_AGENCY_PORTFOLIO = 'AGENCY::DELETE_AGENCY_PORTFOLIO',
  EDIT_AGENCY_DETAILS = 'AGENCY::EDIT_AGENCY_DETAILS',
  EDIT_AGENCY_FOCUS = 'AGENCY::EDIT_AGENCY_FOCUS',
  EDIT_AGENCY_FOUNDED_YEAR = 'AGENCY::EDIT_AGENCY_FOUNDED_YEAR',
  EDIT_AGENCY_PORTFOLIO = 'AGENCY::EDIT_AGENCY_PORTFOLIO',
  EDIT_AGENCY_SERVICES = 'AGENCY::EDIT_AGENCY_SERVICES',
  EDIT_AGENCY_SIZE = 'AGENCY::EDIT_AGENCY_SIZE',
  EDIT_AGENCY_TEAM_DETAILS = 'AGENCY::EDIT_AGENCY_TEAM_DETAILS',
  SET_AGENCY_WORK_HISTORY = 'AGENCY::SET_AGENCY_WORK_HISTORY',
  SET_EDIT_AGENCY_LANGUAGE = 'AGENCY::SET_EDIT_AGENCY_LANGUAGE',
  SET_EDIT_AGENCY_SKILLS = 'AGENCY::SET_EDIT_AGENCY_SKILLS',
  SET_PORTFOLIO_ERROR = 'AGENCY::SET_PORTFOLIO_ERROR',
}

export type AgencyAction =
  | { type: AGENCY_ACTION_TYPES.SET_AGENCY_PROFILE; payload: any }
  | { type: AGENCY_ACTION_TYPES.AGENCY_SUB_TEAM_DELETE; payload: any }
  | { type: AGENCY_ACTION_TYPES.AGENCY_TEAM_LIST; payload: FooterData }
  | { type: AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBERS_LIST; payload: any }
  | { type: AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBER_ADD; payload: any }
  | { type: AGENCY_ACTION_TYPES.AGENCY_TEAM_MEMBER_DELETE; payload: any }
  | { type: AGENCY_ACTION_TYPES.AGENCY_TITLE_AND_OVERVIEW; payload: any }
  | { type: AGENCY_ACTION_TYPES.DELETE_AGENCY_PORTFOLIO; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_DETAILS; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_FOCUS; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_FOUNDED_YEAR; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_PORTFOLIO; payload: any }

  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_SERVICES; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_SIZE; payload: any }
  | { type: AGENCY_ACTION_TYPES.EDIT_AGENCY_TEAM_DETAILS; payload: any }
  | { type: AGENCY_ACTION_TYPES.SET_AGENCY_WORK_HISTORY; payload: any }
  | { type: AGENCY_ACTION_TYPES.SET_EDIT_AGENCY_LANGUAGE; payload: any }
  | { type: AGENCY_ACTION_TYPES.SET_EDIT_AGENCY_SKILLS; payload: any }
  | { type: AGENCY_ACTION_TYPES.SET_PORTFOLIO_ERROR; payload: any }



  
