import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import styled from "styled-components";
import { COLORS } from "UI/colors";

import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";

const HomeNavbar = () => {
  const [navOpen, SetNavOpen] = useState(false);
  const [activeNav, SetActiveNav] = useState("");
  const [show, setShow] = useState(false);

  function ToggleNav() {
    SetNavOpen(!navOpen);
    if (!navOpen) {
      SetActiveNav("msm_active");
      document.querySelectorAll(".ri_twh")?.[0]?.classList?.add("novisible");
    } else {
      SetActiveNav("");
      document.querySelectorAll(".ri_twh")?.[0]?.classList?.remove("novisible");
    }
  }

  return (
    <>
      <WrapperHomeHeader>
        <Navbar
          collapseOnSelect
          expand="md"
          className={`primary-background external_nav_padding`}
        >
          <Container>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <Link to="/">
                <Navbar.Brand>
                  <img src="/assets/images/logo.svg" className="img-fluid rounded-top " alt="" />
                </Navbar.Brand>
              </Link>
              <div>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  onClick={() => ToggleNav()}
                  className="responsiveToggler"
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    <NavDropdown
                      className="navbar_btn b_job_l_sx"
                      title="What Is Unify"
                      id="navbarScrollingDropdown"
                    >
                      <Link className="dropdown-item" to="/home-freelancer">For Freelancers</Link>
                      <Link className="dropdown-item" to="/home-client">For Companies</Link>
                    </NavDropdown>
                    <Link to="/browse-jobs" className="nav-item-desktop">Browse Jobs</Link>
                    <Link to="/subscription" className="nav-item-desktop">Subscription</Link>
                    <Link to="/login" className="nav-item-desktop">Login</Link>
                    <Nav.Link
                      as={Link}
                      to="/signup/learn-and-earn"
                      className="active_btn"
                    >
                      Signup now
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </Container>
        </Navbar>
      </WrapperHomeHeader>
      <WrapperMobileNav>
        <div className={`mobile_slide_menu ${activeNav}`}>
          <div className="text-right">
            <button className="nav_close_btn" onClick={() => ToggleNav()}>
              <CloseIcon/>
            </button>
          </div>
          <MenuItemWrapper isShow={show}>
            <div className="item-main" onClick={() => setShow(!show)}> <div> What is unify? </div> <img src="/assets/images/white-arrow.svg" alt="" /> </div>
            {show && <>
              <div className="navabr-item">
                <Link to="/home-freelancer" className="ForFreelancersNav">
                  For Freelancers
                </Link>
              </div>
              <div className="navabr-item">
                <Link to="/home-client" className="ForFreelancersNav">
                  For Companies
                </Link>
              </div>
            </>}
          </MenuItemWrapper>

          <div className="navabr-item-one">
            <Link to="/browse-jobs">BROWSE JOBS</Link>
          </div>
          <div className="navabr-item-one">
            <Link to={`/subscription`}>Subscription</Link>
          </div>
          <div className="buttons-wrapper-login">
            <div className="logout_btn_mob">
              <Link to={`/login`}>
                <button>Login</button>
              </Link>
            </div>
            <div className="logout_btn_mob">
              <Link to={`/signup/learn-and-earn`}>
                <button>Sign Up</button>
              </Link>
            </div>
          </div>
        </div>
      </WrapperMobileNav>
    </>
  );
};

export default HomeNavbar;

export const WrapperMobileNav = styled.div`
  text-align: center;

  .buttons-wrapper-login {
    display: flex;
    margin-top: 10px;
  }

  .navabr-item-one {
    width: 96%;
    font-weight: 700;
    color: ${COLORS.whitePrimary};
    margin-left: 8px;
    margin-top: 15px;
    font-size: 21px;
    text-transform: uppercase;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    border-bottom: 1px solid ${COLORS.graySecondary};

    &:hover {
      color: ${COLORS.violetPrimary};
    }
  }
`;

export const MenuItemWrapper = styled.div<{ isShow: boolean }>`
  width: 96%;
  font-weight: 700;
  color: ${COLORS.whitePrimary};
  margin-left: 8px;
  margin-top: 20px;
  border-bottom: 1px solid ${COLORS.graySecondary};
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  .navabr-item {
    width: 96%;
    font-weight: 700;
    color: ${COLORS.whitePrimary};
    margin-left: 8px;
    margin-top: 15px;
    font-size: 21px;
    text-transform: uppercase;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;

    &:hover {
      color: ${COLORS.violetPrimary};
    }
  }

  .item-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      color: ${COLORS.whitePrimary};
      transform: ${({ isShow }) => isShow ? "rotate(180deg)" : "rotate(0deg)"};
    }

    &:hover {
      color: ${COLORS.violetPrimary};
    }
  }
`;

export const WrapperHomeHeader = styled.div`
  z-index: 1;
  
  .nav-item-desktop {
    font-weight: 500;
    color: ${COLORS.whitePrimary};
    margin: 0 8px;
    padding: 8px;
    font-size: 15px;

    &:hover {
      color: ${COLORS.blackPrimary};
    }
  }

  .active_btn {
    word-wrap: normal;
    white-space: nowrap;  
    padding: 10px 20px !important;
  }
`;