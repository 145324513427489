import { NOTIFICATIONS_ACTION_TYPES } from "Redux/Types/notificationsTypes";
import { NotificationsAction } from "../Types/notificationsTypes";
import { LoadingType } from "./authReducer";

type InitialStateType = {
  systemNotificationPing: any;
  deleteNotification: any;
  seenNotification: any;
  getNotificationSetting: any;
  updateNotificationSetting: any;
  getClientNotificationSetting: any;
  updateClientNotificationSetting: any;
  notificationStatus: LoadingType;
};

const initialState: InitialStateType = {
  systemNotificationPing: null,
  deleteNotification: null,
  seenNotification: null,
  getNotificationSetting: null,
  updateNotificationSetting: null,
  getClientNotificationSetting: null,
  updateClientNotificationSetting: null,
  notificationStatus: 'idle',
};

const notificationReducer = (state = initialState, action: NotificationsAction) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTION_TYPES.SET_SYSTEM_NOTIFICATION:
      return {
        ...state,
        systemNotificationPing: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.DELETE_SYSTEM_NOTIFICATION:
      return {
        ...state,
        deleteNotification: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.SEE_NOTIFICATION:
      return {
        ...state,
        seenNotification: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.SET_NOTIFICATION_SETTING:
      return {
        ...state,
        getNotificationSetting: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION_SETTING:
      return {
        ...state,
        updateNotificationSetting: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.SET_CLIENT_NOTIFICATION_SETTING:
      return {
        ...state,
        getClientNotificationSetting: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.UPDATE_CLIENT_NOTIFICATION_SETTING:
      return {
        ...state,
        updateClientNotificationSetting: action.payload,
      };
    case NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_STATUS:
      return {
        ...state,
        notificationStatus: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
