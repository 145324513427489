import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router";
import { HomePageData, LoadingType } from "Redux/Reducers/authReducer";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { AUTH_ACTION_TYPES } from "Redux/Types/authTypes";
import {
  appleProvider,
  auth,
  googleAuthProvider,
  signInWithPopup,
  signOut,
} from "../../Firebase/firebase";
import { Axios } from "../Axios";
import { afterLoginAction } from "../../Routing/AfterLoginAction";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("unify_token")}`,
  },
};

export const hireForProject = () => ({
  type: AUTH_ACTION_TYPES.POST_JOB_LIKE_THIS,
  payload: "hire-for-project",
})

export const learnAndEarn = () => ({
  type: AUTH_ACTION_TYPES.POST_JOB_LIKE_THIS,
  payload: "learn-and-earn",
})

export const instantLoginEmail = (data: any) => ({
  type: AUTH_ACTION_TYPES.INSTANT_LOGIN_EMAIL,
  payload: data,
});


export const getHeaderData = () => async (dispatch: any) => {
  await Axios.get(`/header`, config)
    .then((res) => {
      dispatch({
        type: AUTH_ACTION_TYPES.HEADER_DATA,
        payload: res.data.data,
      });
    });
};

export const getFooterData = () => async (dispatch: any) => {
  await Axios.get(`/footer`)
    .then((res) => {
      dispatch({
        type: AUTH_ACTION_TYPES.FOOTER_DATA,
        payload: res.data.data,
      });
    });
};

export const setHomePageDataStatus = (data: LoadingType) => ({
  type: AUTH_ACTION_TYPES.SET_HOME_PAGE_DATA_STATUS,
  payload: data,
});

export const getHomePageData = () => async (dispatch: (arg0: { type: string; payload: HomePageData | LoadingType; }) => void) => {
  dispatch(setHomePageDataStatus("loading"));
  await Axios.get(`/home-data`)
    .then((res) => {
      dispatch({
        type: AUTH_ACTION_TYPES.HOME_PAGE_DATA,
        payload: res.data.data,
      });
      dispatch(setHomePageDataStatus("idle"));
    })
    .catch((err) => {
      dispatch(setHomePageDataStatus(err?.response?.data?.message));
    });
};

export const getSkillsByCat = (data: { category_id: string | undefined; }, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  await Axios.post(`/category-skills`, data)
    .then((res) => {
      dispatch({
        type: AUTH_ACTION_TYPES.CATEGORY_SKILL_DATA,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getDevelopersBySkills = (data: { skill_id: string | undefined; }, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  await Axios.post(`/skill-freelancer`, data)
    .then((res) => {
      dispatch({
        type: AUTH_ACTION_TYPES.SKILLS_DEVELOPER_DATA,
        payload: res.data.data,
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onLoginStatus = (status: LoadingType) => ({
  type: AUTH_ACTION_TYPES.LOGIN_STATUS,
  payload: status,
})

export const onLogin =
  (data: { email: any; password: any; }) => async (dispatch: any) => {
    dispatch(onLoginStatus("loading"));
    await Axios.post(`/login`, data)
      .then((response) => {
        if (response.data.status) {
          dispatch(onLoginStatus("idle"));
          afterLoginAction(response.data);
          window.location.reload();
        }
      })
      .catch((err) => {
        dispatch(onLoginStatus(err?.response?.data?.message))
      });
  };

export const onRegister =
  (data: FormData, navigate: NavigateFunction, token: string | undefined, chat_token: string | null) =>
    async (dispatch: any) => {
      dispatch(onLoginStatus("loading"))
      await Axios.post(`/signup`, data)
        .then((res) => {
          if (res.data.status) {
            navigate("/verify-signup");
            if (token) {
              localStorage.setItem("byo-token", token);
            }
            if (chat_token) {
              localStorage.setItem("chat_token", chat_token);
            }
          }
          dispatch(onLoginStatus("idle"))
        })
        .catch((err) => {
          dispatch(onLoginStatus(err?.response?.data?.message))
        });
    };

export const onVerifySignup = (
  data: { email: string | null; otp: string; }) =>
    async (dispatch: any) => {
      dispatch(onLoginStatus("loading"));
      await Axios.post(`/verify-signup`, data)
        .then((response) => {
          if (response.data.status) {
            dispatch(onLoginStatus("idle"));
            afterLoginAction(response.data);
          }
        })
        .catch((err) => {
          dispatch(onLoginStatus(err?.response?.data?.message))
        });
    };

export const onResendOtp =
  (data: { email: any; }, setOtpSuccess: { (value: SetStateAction<undefined>): void; (value: SetStateAction<undefined>): void; (value: SetStateAction<undefined>): void; (arg0: any): void; }, setLoading: { (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void; (arg0: boolean): any; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    await Axios.post(`/resend-otp`, data)
      .then((res) => {
        dispatch({
          type: AUTH_ACTION_TYPES.RESEND_OTP_SUCCESS,
          payload: res.data.message,
        });
        setLoading && setLoading(false);

        setOtpSuccess(res.data.message);
      })
      .catch((err) => {
        setLoading && setLoading(false);
      });
  };

export const setCountry = (data: any) => ({
  type: AUTH_ACTION_TYPES.SET_COUNTRY,
  payload: data,
});

export const setCountryStatus = (data: LoadingType) => ({
  type: AUTH_ACTION_TYPES.SET_COUNTRY_STATUS,
  payload: data,
});

export const countryList = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch(setCountryStatus("loading"))
  await Axios.get(`/country-list`)
    .then((res) => {
      if (res.data.status) {
        dispatch(setCountry(res.data.data))
      }
      dispatch(setCountryStatus("idle"))
    })
    .catch((err) => {
      setCountryStatus(err?.response?.data?.message)
    });
};

export const stateList = (id: any, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; } | undefined) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoading && setLoading(true);
  await Axios.get(`/state-list`, { params: { country_id: id } })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: AUTH_ACTION_TYPES.SET_STATE_LIST,
          payload: res.data.data,
        });
        if (setLoading) {
          setLoading(false);
        }
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onForgotPassword =
  (data: { email: string; }, navigate: NavigateFunction, setForgotPassError: any, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => async (dispatch: any) => {
    await Axios.post(`/forget-password`, data)
      .then((res) => {
        if (res.data.status) {
          navigate("/verify-forgot-otp");
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setForgotPassError(err?.response?.data?.message);
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onVerifyForgot =
  (data: { email: string | null; otp: string; }, navigate: NavigateFunction, setPopup: { (value: SetStateAction<boolean>): void; (arg1: { setPopup: any; }, arg2: boolean): void; }, successPopup: boolean, setSuccessPopup: { (value: SetStateAction<boolean>): void; (): void; }, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) =>
    async (dispatch: any) => {
      await Axios.post(`/verify-forgot-otp`, data)
        .then((res) => {
          if (res.data.status) {
            setSuccessPopup(true);
            navigate("/reset-password");
          }
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setPopup(err?.response?.data?.message)
            setLoading(false);
          }
        });
    };

export const onResetPassword =
  (data: { email: string | null; password: any; confirm_password: any; }, successPopup: boolean, setSuccessPopup: Dispatch<SetStateAction<boolean>>, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }, navigate: NavigateFunction) =>
    async (dispatch: any) => {
      await Axios.post(`/reset-password`, data)
        .then((res) => {
          if (res.data.status) {
            navigate("/login");
            localStorage.removeItem("unify_email");
          }
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onPasswordChange =
  (data: undefined, setLoading: (arg0: boolean) => void, popup: () => void, successPopup: any, setSuccessPopup: (arg1: { setSuccessPopup: any; }, arg2: boolean) => void, afterSuccess: { (): void; (): void; }) =>
    async (dispatch: any) => {
      await Axios.post(`/change-password`, data, config)
        .then((res) => {
          if (res.data.status) {
            if (popup) {
              popup();
            }
            if (setLoading) {
              setLoading(false);
            }
            if (afterSuccess) {
              afterSuccess();
            }
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

const onOnlineStatusChange = (status: LoadingType) => ({
  type: AUTH_ACTION_TYPES.SET_ONLINE_STATUS_CHANGE,
  payload: status,
})

const setOnlineStatus = (data: any) => ({
  type: AUTH_ACTION_TYPES.SET_ONLINE_STATUS,
  payload: data,
});

export const onOnlineStatus = (data: { online_status: any; id: any; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch(onOnlineStatusChange("loading"))
  await Axios.post(`/online-status`, data, config)
    .then((res) => {
      if (res.data.status) {
        dispatch(setOnlineStatus(res.data));
      }
      dispatch(onOnlineStatusChange("idle"))
    })
    .catch((err) => {
      dispatch(onOnlineStatusChange(err?.response?.data?.message))
    });
};

export const googleSignInSuccess = (
  data: { provider: string; token: string | undefined; fcm_token: any; user_type: any; }) =>
    async (dispatch: any) => {
      dispatch(onLoginStatus("loading"));
      await Axios.post(`/social-login`, data)
        .then((response) => {
          if (response.data.status) {
            afterLoginAction(response.data);
            dispatch(onLoginStatus('idle'));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(onLoginStatus(err?.response?.data?.message))
        });
  };

export const googleSignInFail = (error: any) => ({
  type: AUTH_ACTION_TYPES.GOOGLE_SIGN_IN_FAIL,
  payload: error,
});

export const googleSignInInitiate = (data: { userType: any; fcmToken: any; }) => {
  return (dispatch: any) => {
    signOut(auth)
      .then(() => {
        dispatch(onLoginStatus("loading"))
        signInWithPopup(auth, googleAuthProvider)
          .then((res) => {
            const credential = GoogleAuthProvider.credentialFromResult(res);
            if (credential === null) {
              return;
            }
            const accessToken = credential.accessToken;
            dispatch(
              googleSignInSuccess(
                {
                  provider: "google",
                  token: accessToken,
                  fcm_token: data?.fcmToken,
                  user_type: data?.userType,
                },
              )
            );

            dispatch(onLoginStatus("idle"))
          })
          .catch((error) => {
            console.log("googleSignInInitiate ERROR");
            dispatch(googleSignInFail(error.message));
          });
      })
      .catch((error) => {
        dispatch(onLoginStatus(error?.response?.data?.message) as any)
      });
  };
};

export const appleSignInSuccess = (
  data: { provider: string; token: string | undefined; fcm_token: any; user_type: any; }) =>
    async (dispatch: any) => {
      dispatch(onLoginStatus("loading"));
      await Axios.post(`/social-login`, data, config)
        .then((response) => {
          if (response.data.status) {
            dispatch(onLoginStatus('idle'));
            afterLoginAction(response.data);
            window.location.reload();
          }
        })
        .catch((err) => {
          dispatch(onLoginStatus(err?.response?.data?.message))
          return;
        });
  };

export const appleSignInFail = (error: any) => ({
  type: AUTH_ACTION_TYPES.APPLE_SIGN_IN_FAIL,
  payload: error,
});

export const appleSignInInitiate = (data: { userType: any; fcmToken: any; }) => {
  return function (dispatch: (arg0: (dispatch: any) => Promise<void>) => void) {
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential === null) {
          return;
        }
        const accessToken = credential.idToken;
        dispatch(
          appleSignInSuccess(
            {
              provider: "apple",
              token: accessToken,
              fcm_token: data?.fcmToken,
              user_type: data?.userType,
            },
          )
        );
        dispatch(onLoginStatus('idle') as any)
      })
      .catch((error) => {
        appleSignInFail(error);
        dispatch(onLoginStatus(error?.response?.data?.message) as any)
      });
  };
};

export const footerDataStatus = (status: LoadingType) => ({
  type: AUTH_ACTION_TYPES.TEXT_PAGE_DATA_STATUS,
  payload: status,
})

export const setFooterData = (data: any) => ({
  type: AUTH_ACTION_TYPES.TEXT_PAGE_DATA,
  payload: data,
})

export const getTextPageData =
  (pagename: string | undefined) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    await Axios.get(`/page/${pagename}`)
      .then((res) => {
        dispatch(footerDataStatus('loading'))
        if (res.data.status) {
          dispatch(setFooterData(res.data.data));
        }
        dispatch(footerDataStatus('idle'))
      })
      .catch((err) => {
        dispatch(footerDataStatus(err?.response?.data?.message))
      });
  };

export const setClientCompnySize =
  (data: any, navigate: (arg0: string) => void, setLoading: (arg0: boolean) => void, update: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    await Axios.post("/business-size", data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: AUTH_ACTION_TYPES.CLIENT_BUSINESS_SIZE,
            payload: res.data.data,
          });

          if (update) {
            navigate(`/view-job/${update}/job-details`);
          } else {
            navigate(`/welcome`);
          }
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onSubmitProfile = (navigate: (arg0: string) => void, setLoading: (arg0: boolean) => void) => async (dispatch: any) => {
  await Axios.get(`/submit-profile`, config)
    .then((res) => {
      const item = localStorage.getItem("unify_user") || "{}";
      if (res.data.status) {
        if (
          JSON.parse(item).user_type ===
          "freelancer"
        ) {
          if (localStorage.getItem("byo-token")) {
            navigate(
              `/freelancer/single-invitation/${localStorage.getItem(
                "byo-token"
              )}`
            );
          } else if (localStorage.getItem("chat_token")) {
            navigate(`/chat`);
          } else {
            navigate("/dashboard");
          }
        } else if (
          JSON.parse(item).user_type === "client"
        ) {
          if (localStorage.getItem("chat_token")) {
            navigate(`/chat`);
          } else {
            navigate("/dashboard");
          }
        }
        window.location.reload();
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onSubmitCompanyDetails =
  (data: FormData, navigate: NavigateFunction, setLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }, setSuccessPopup: { (value: SetStateAction<boolean>): void; (arg1: { setSuccessPopup: any; }, arg2: boolean): void; }) => async (dispatch: (arg0: (dispatch: any) => Promise<void>) => void) => {
    await Axios.post("/client-profile-step", data, config)
      .then((res) => {
        if (res.data.status) {
          dispatch(onSubmitProfile(navigate, setLoading));
        }
      })
      .catch((err) => {
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const onUserChangeStatus = (status: LoadingType) => ({
  type: AUTH_ACTION_TYPES.USER_CHANGE_STATUS,
  payload: status,
})

export const onUserRoleChange =
  (role: string, navigate: NavigateFunction) => async (dispatch: any) => {
    dispatch(onUserChangeStatus("loading"))
    await Axios.post(`/save-role`, {role: role}, config)
      .then((res) => {
        if (res.data.status) {
          if (role === "freelancer") {
            navigate("/dashboard");
            window.location.reload();
          } else if (role === "agency") {
            navigate("/agency/dashboard");
            window.location.reload();
          } else if (role === "client") {
            navigate("/dashboard");
            window.location.reload();
          }
        }
        dispatch(onUserChangeStatus("idle"))
      })
      .catch((err) => {
        dispatch(onUserChangeStatus(err?.response?.data?.message))
      });
  };


const setAllUserDetails = (data: any) => ({
  type: AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS,
  payload: data,
});

const setAllUserDetailsStatus = (status: LoadingType) => ({
  type: AUTH_ACTION_TYPES.SET_ALL_USER_DETAILS_STATUS,
  payload: status,
});

export const getAllUserDetails = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setAllUserDetailsStatus("loading")
  await Axios.get(`/user-all-details`, config)
    .then((res) => {
      if (res.data.status) {
        dispatch(setAllUserDetails(res.data.data));
      }
      setAllUserDetailsStatus("idle")
    })
    .catch((err) => {
      setAllUserDetailsStatus(err?.response?.data?.message)
      if (err?.response?.data?.message === "Unauthenticated.") {
        localStorage.clear();
        window.location.reload();
      }
    });
};

export const onLmsCertificate = (data: { token: string | null; }, setLoading: { (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  await axios.post(`https://staging-lms.useunify.com/wp-json/unify_lms/lms/course_info`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: AUTH_ACTION_TYPES.SET_LMS_CERTIFICATE,
          payload: res.data,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const onVerifyChangePass =
  (data: any, setPopup: () => void, successPopup: any, setSuccessPopup: (arg0: undefined, arg1: { setSuccessPopup: any; } | undefined, arg2: boolean | undefined) => void, setLoading: (arg0: boolean) => void) =>
    async (dispatch: any) => {
      await Axios.post(`/verify-change-password`, data, config)
        .then((res) => {
          if (res.data.status) {
            if (setPopup) {
              setPopup();
            }
          }
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
        });
    };

export const onLogout = (navigate: NavigateFunction) => async (dispatch: any) => {
  dispatch(onLoginStatus("loading"))
  await Axios.post(`/logout`, {}, config)
    .then((res) => {
      if (res.data.status) {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      }
      dispatch(onLoginStatus("idle"))
    })
    .catch((err) => {
      dispatch(onLoginStatus(err?.response?.data?.message))
    });
};

export const getEmailDetails = (data: { invite_id: string | undefined; }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  await Axios.post(`/get-talent-details`, data, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: AUTH_ACTION_TYPES.SET_EMAIL_DETAILS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
    });
};

export const onChatSignup = (data: FormData, setLoading: (i: boolean) => void) => async (dispatch: any) => {
  await Axios.post(`/signup-for-chat`, data)
    .then((res) => {
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};