import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserDetails, getHeaderData,
  onLogout,
  onOnlineStatus,
  onUserRoleChange,
} from "../../Redux/Actions/authActions";
import LoadingSpinner from "../Loader/LoadingSpinner";
import MenuDown from "./MenuDown";
import { getSystemNotification } from "../../Redux/Actions/notificationAction";
import ResponsiveNavbar from "./ResponsiveNavbar";
import MenuItem from "./MenuItem";
import WebMenuDropdown from "./WebMenuDropdown";
import socket from "../../SocketConnection";
import ShowTooltip from "../ShowTooltip";
import LmsAccessPopup from "./LmsAccessPopup";
import {
  selectAllUserDetails,
  selectAllUserDetailsStatus, selectHeaderData,
  selectOnOnlineStatus,
} from "../../Redux/Selectors/authSelectors";
import ErrorPopup from "../../Freelancer/Components/Popups/ErrorPopup";
import { selectEditAgencyDetails } from "../../Redux/Selectors/agencySelectors";
import { selectEditClientProfileDetails } from "../../Redux/Selectors/profileSelector";
import { selectStripeAccountData, selectGetPaidRequest, selectAddedCard, selectDeleteCard } from "../../Redux/Selectors/freelancerSelectors";
import { selectShowTooltip, selectTooltipData } from "../../Redux/Selectors/clientSelectors";
import { selectSystemNotificationPing, selectDeleteNotification, selectSeenNotification } from "../../Redux/Selectors/notificationsSelectors";
import { selectCancelSubscription, selectChangeSubscription } from "../../Redux/Selectors/subscriptionSelectors";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { url } from "../../Functions/url";

const LoginNavbar = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isDownOpen, SetIsDownOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [learPopup, setLearnPopup] = useState(false);
  const bearerToken = `Bearer ${localStorage.getItem("unify_token")}`;

  const headerData = useSelector(selectHeaderData);
  const onlineStatus = useSelector(selectOnOnlineStatus);
  const allUserDetails = useSelector(selectAllUserDetails);
  const allUserDetailsStatus = useSelector(selectAllUserDetailsStatus);
  const isLoading = allUserDetailsStatus === "loading";
  const isError = allUserDetailsStatus !== "loading" && allUserDetailsStatus !== "idle";

  const clientDetails = useSelector(selectAllUserDetails).client_profile;
  const freelancerDetails = useSelector(selectAllUserDetails).freelancer_profile;
  const agencyDetails = useSelector(selectAllUserDetails).agency_profile;
  const editAgencyDetails = useSelector(selectEditAgencyDetails);
  const editClientProfile = useSelector(selectEditClientProfileDetails);
  const stripeAccountData = useSelector(selectStripeAccountData);
  const getPaidRequest = useSelector(selectGetPaidRequest);
  const showTooltip = useSelector(selectShowTooltip);
  const tooltipData = useSelector(selectTooltipData);
  const systemNotification = useSelector(selectSystemNotificationPing);
  const deleteNotification = useSelector(selectDeleteNotification);
  const seenNotification = useSelector(selectSeenNotification);
  const cancelSubscription = useSelector(selectCancelSubscription);
  const changeSubscription = useSelector(selectChangeSubscription);
  const addedCard = useSelector(selectAddedCard);
  const deleteCard = useSelector(selectDeleteCard);

  useEffect(() => {
    dispatch(getHeaderData())
  }, []);

  useEffect(() => {
    dispatch(getAllUserDetails());
  }, [
    onlineStatus,
    editClientProfile,
    stripeAccountData,
    getPaidRequest,
    cancelSubscription,
    editAgencyDetails,
    addedCard,
    deleteCard,
    changeSubscription,
    tooltipData,
  ]);

  useEffect(() => {
    if (allUserDetails?.active_role === "client") {
      if (clientDetails?.is_deleted) {
        localStorage.clear();
        window.location.reload();
      }
    } else if (allUserDetails?.active_role === "freelancer") {
      if (freelancerDetails?.is_deleted) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, [allUserDetails]);

  useEffect(() => {
    dispatch(getSystemNotification());
  }, [deleteNotification, seenNotification]);

  function ToggleDown() {
    SetIsDownOpen(!isDownOpen);
  }

  const handleOnlineStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      online_status: e.target.value,
      id: allUserDetails?.basic_info.id,
    };

    //socket?.emit("login", { uidfrom: Number(data?.id), bearerToken });
    dispatch(onOnlineStatus(data));
  };

  const handleSwitchAccount = (value: string) => {
    dispatch(onUserRoleChange(value, navigate));
  };

  const handleLearn = () => {
    if (freelancerDetails?.subscription_id === "6") {
      setLearnPopup(true);
    } else {
      window.location.href= url("learn");
    }
  };

  const handleLogout = () => {
    dispatch(onLogout(navigate));
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        className={`primary-background external_nav_padding responsive-navbar-mobile ${isDownOpen ? "minheifghtx_smmenu" : ""} navv2padding`}
      >
        <div className="container d-flex justify-content-between align-items-center newnav_v2_flxew">
          <div className="newnav_v2_wauto">
            {!allUserDetails?.is_profile_complete ? (
              <Link to={
                allUserDetails?.active_role === "freelancer" ? "/freelancer/profile-intro/question-one" : "/business-size"
              }>
                <Navbar.Brand>
                  <img src="/assets/images/logo.svg" className="img-fluid rounded-top" alt="" />
                </Navbar.Brand>
              </Link>
            ) : (
              <>
                <Link to={
                    allUserDetails?.active_role === "agency" ? "/agency/dashboard" : "/dashboard"
                }>
                  <Navbar.Brand>
                    <img src="/assets/images/logo.svg" className="img-fluid rounded-top" alt="" />
                  </Navbar.Brand>
                </Link>
              </>
            )}
            {
              allUserDetails?.is_tooltipon &&
              allUserDetails?.is_profile_complete &&
              allUserDetails?.active_role === "freelancer" && (
                <span className="no_verify_icon tooltip_hourly_div">
                  <i className="bi bi-question-circle-fill" aria-hidden="true"></i>
                  {allUserDetails?.is_tooltipon && (
                    <ShowTooltip
                      message="Click here at any time when you’re on the website to return to this page (your dashboard)"
                      showTooltip={showTooltip}
                      currentTooltip={1}
                    />
                  )}
                </span>
              )}
          </div>
          <div>
            <ResponsiveNavbar
              allUserDetails={allUserDetails}
              isDownOpen={isDownOpen}
              ToggleDown={ToggleDown}
              handleLogout={handleLogout}
              handleSwitchAccount={handleSwitchAccount}
              agencyDetails={agencyDetails}
              handleOnlineStatus={handleOnlineStatus}
              clientDetails={clientDetails}
              systemNotification={systemNotification}
              freelancerDetails={freelancerDetails}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                {allUserDetails?.is_profile_complete && (
                  <div className="navbar_btn b_job_l_sx nav-item">
                    {allUserDetails?.active_role !== "agency" && (
                      <>
                        <a className="nav-link learnNavbar" onClick={() => handleLearn()}>
                          Learn
                        </a>
                        {allUserDetails?.is_tooltipon &&
                          allUserDetails?.active_role === "freelancer" && (
                            <span className="no_verify_icon tooltip_hourly_div">
                              <i className="bi bi-question-circle-fill"
                                aria-hidden="true"></i>
                              <ShowTooltip
                                message="Here you’ll find our extensive library of courses"
                                showTooltip={showTooltip}
                                currentTooltip={2}
                              />
                            </span>
                          )}
                      </>
                    )}
                  </div>
                )}

                <MenuItem
                  allUserDetails={allUserDetails}
                  showTooltip={showTooltip}
                  systemNotification={systemNotification}
                />
                <WebMenuDropdown
                  headerData={headerData}
                  handleLogout={handleLogout}
                  handleSwitchAccount={handleSwitchAccount}
                  handleOnlineStatus={handleOnlineStatus}
                />
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
        {allUserDetails?.is_profile_complete && (
          <>
            {isDownOpen && (
              <MenuDown
                allUserDetails={allUserDetails}
                ToggleDown={ToggleDown}
                handleLearn={handleLearn}
              />
            )}
          </>
        )}
      </Navbar>
      {isError && <ErrorPopup message={allUserDetailsStatus} popup={() => setPopup(false)} afterError={() => { }} />}
      {learPopup && <LmsAccessPopup Popup={() => setLearnPopup(false)} />}
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default LoginNavbar;
