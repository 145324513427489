import { LoadingType } from "Redux/Reducers/authReducer";


export enum FREELANCER_ACTION_TYPES {
   SET_FREELANCER_LIST_STATUS = 'FREELANCER::SET_FREELANCER_LIST_STATUS',
   SET_SINGLE_FREELANCER_STATUS = 'FREELANCER::SET_SINGLE_FREELANCER_STATUS',
}

export type FreelancerAction =
   | { type: FREELANCER_ACTION_TYPES.SET_FREELANCER_LIST_STATUS; payload: LoadingType }
   | { type: FREELANCER_ACTION_TYPES.SET_SINGLE_FREELANCER_STATUS; payload: LoadingType }