import { redirect } from "../Functions/redirect";

export const afterLoginAction = (response: any) => {
  const user = response?.data?.user;

  localStorage.setItem("unify_user", JSON.stringify(user));
  localStorage.setItem("unify_token", response?.auth_token ?? '');

  afterLoginRedirect();
}

export const afterLoginRedirect = () => {
  let user = JSON.parse(localStorage.getItem("unify_user") || '{}');

  if (user?.status === "pending") {
    redirect("/verify-signup");
  } else {
    if (user?.is_profile_complete === true) {
      redirect("/dashboard");
    } else if (user?.user_type === "freelancer") {
      redirect("/freelancer/profile-intro/question-one");
    } else {
      redirect("/welcome");
    }
  }
}