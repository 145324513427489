import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SET_TOOLTIP_OPEN } from "../Redux/Types";
import { getTooltipData } from "../Redux/Actions/clientActions";

const ShowTooltip = ({ showTooltip, message, currentTooltip }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onNext = () => {
    dispatch({
      type: SET_TOOLTIP_OPEN,
      payload: showTooltip + 1,
    });
  };
  const onBack = () => {
    dispatch({
      type: SET_TOOLTIP_OPEN,
      payload: showTooltip - 1,
    });
  };

  const finishTooltip = () => {
    setLoading(true);
    dispatch(getTooltipData(setLoading));
  };

  return (
    <>
      <div className="tooltip_backdrop"></div>
      <span
        className={`tooltipHourlytextnext ${
          currentTooltip === showTooltip ? "d-block" : "d-none"
        } ${currentTooltip === 4 && "tooltipHourlytexttop"}`}
      >
        <div className="thought">
          <div className="tooltip_message">{message}</div>
          <div className="tooltip_btns">
            {currentTooltip === 1 ? (
              <div
                className="backbtnstooltip invisible"
                onClick={finishTooltip}
              >
                Skip
              </div>
            ) : (
              <div className="backbtnstooltip" onClick={onBack}>
                Back
              </div>
            )}

            {currentTooltip === 7 ? (
              <div className="nextbtnstooltip" onClick={finishTooltip}>
                Finish
              </div>
            ) : (
              <div className="nextbtnstooltip" onClick={onNext}>
                Next
              </div>
            )}
          </div>
        </div>
      </span>
      {loading}
    </>
  );
};

export default ShowTooltip;
