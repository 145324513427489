import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectFreelancerReducer = (state: RootState) => state.freelancer;

const selectClientContractListMeta = createSelector(selectFreelancerReducer, (data) => data?.clientContractList?.meta);
const selectClientContractListData = createSelector(selectFreelancerReducer, (data) => data?.clientContractList?.data);
const selectReviewWorkAndChange = createSelector(selectFreelancerReducer, (data) => data?.reviewWorkAndChange);
const selectAddMilestone = createSelector(selectFreelancerReducer, (data) => data?.addMilestone);
const selectFundMilestone = createSelector(selectFreelancerReducer, (data) => data?.fundMilestone);
const selectDeclineMilestone = createSelector(selectFreelancerReducer, (data) => data?.declineMilestone);
const selectOverviewHistory = createSelector(selectFreelancerReducer, (data) => data?.overviewHistory);
const selectTransactionHistoryData = createSelector(selectFreelancerReducer, (data) => data.transactionHistory.data);
const selectTransactionHistoryMeta = createSelector(selectFreelancerReducer, (data) => data.transactionHistory.meta);
const selectFreelancerIdsList = createSelector(selectFreelancerReducer, (data) => data?.freelancerIdsList);
const selectFreelancerListData = createSelector(selectFreelancerReducer, (data) => data?.freelancerList.data);
const selectFreelancerListMeta = createSelector(selectFreelancerReducer, (data) => data?.freelancerList.meta);
const selectRemoveSavedTalent = createSelector(selectFreelancerReducer, (data) => data?.removeSavedTalent);
const selectSavedTalent = createSelector(selectFreelancerReducer, (data) => data?.savedTalent);
const selectGetDirectContractInputsData = createSelector(selectFreelancerReducer, (data) => data?.getDirectContractInputsData);
const selectGetDirectContractInputsEditData = createSelector(selectFreelancerReducer, (data) => data?.getDirectContractInputsEditData);
const selectGetClickedUserType = createSelector(selectFreelancerReducer, (data) => data?.getClickedUserType.type);
const selectGetDirectPaidRequest = createSelector(selectFreelancerReducer, (data) => data?.getDirectPaidRequest);
const selectGetDirectContractListData = createSelector(selectFreelancerReducer, (data) => data?.getDirectContractList);
const selectDirectWorkSubmit = createSelector(selectFreelancerReducer, (data) => data?.directWorkSubmit);
const selectGetClickedUserTypeId = createSelector(selectFreelancerReducer, (data) => data?.getClickedUserType.id);
const selectDirectContactMail = createSelector(selectFreelancerReducer, (data) => data?.directContactMail);
const selectApproveDirectContractWork = createSelector(selectFreelancerReducer, (data) => data?.approveDirectContractWork);
const selectGetWithdrawalDataList = createSelector(selectFreelancerReducer, (data) => data?.getWithdrawalDataList);
const selectFreelancerListStatus = createSelector(selectFreelancerReducer, (data) => data?.freelancerListStatus);
const selectSingleFreelancer = createSelector(selectFreelancerReducer, (data) => data?.singleFreelancer);
const selectFreelancerWorkHistory = createSelector(selectFreelancerReducer, (data) => data?.freelancerWorkHistory);
const selectSingleFreelancerStatus = createSelector(selectFreelancerReducer, (data) => data?.singleFreelancerStatus);
const selectStripeAccountData = createSelector(selectFreelancerReducer, (data) => data?.stripeAccountData);
const selectGetPaidRequest = createSelector(selectFreelancerReducer, (data) => data?.getPaidRequest);
const selectAddedCard = createSelector(selectFreelancerReducer, (data) => data?.addedCard);
const selectDeleteCard = createSelector(selectFreelancerReducer, (data) => data?.deleteCard);
const selectFreelancerDashboardData = createSelector(selectFreelancerReducer, (data) => data?.freelancerDashboardData);
const selectFreelancerVacationMode = createSelector(selectFreelancerReducer, (data) => data?.freelancerVacationMode);

export {
  selectClientContractListMeta,
  selectClientContractListData,
  selectReviewWorkAndChange,
  selectAddMilestone,
  selectFundMilestone,
  selectDeclineMilestone,
  selectOverviewHistory,
  selectTransactionHistoryData,
  selectTransactionHistoryMeta,
  selectFreelancerIdsList,
  selectFreelancerListData,
  selectFreelancerListMeta,
  selectRemoveSavedTalent,
  selectSavedTalent,
  selectGetDirectContractInputsData,
  selectGetDirectContractInputsEditData,
  selectGetClickedUserType,
  selectGetDirectPaidRequest,
  selectGetDirectContractListData,
  selectDirectWorkSubmit,
  selectGetClickedUserTypeId,
  selectDirectContactMail,
  selectApproveDirectContractWork,
  selectGetWithdrawalDataList,
  selectFreelancerListStatus,
  selectSingleFreelancer,
  selectFreelancerWorkHistory,
  selectSingleFreelancerStatus,
  selectStripeAccountData,
  selectGetPaidRequest,
  selectAddedCard,
  selectDeleteCard,
  selectFreelancerDashboardData,
  selectFreelancerVacationMode
};