import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ProfileImageContainer, ProfileNameContainer, NavNodeWrapper } from "./styled";
import { Button } from "UI/Button";
import { useSelector } from "react-redux";
import { selectOnOnlineStatusChange } from "Redux/Selectors/authSelectors";
import styled from "styled-components";
import { HeaderData } from "../../Redux/Reducers/authReducer";
import { ucfirst } from "../../Functions/helpers";

type WebMenuDropdownProps = {
  headerData: HeaderData | {};
  handleOnlineStatus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLogout: () => void;
  handleSwitchAccount: (role: string) => void;
};

type AccountTypeItemProps = {
  handleSwitchAccount: any;
  profileImage: string;
  userName: string | null;
  userType: string;
};

const AccountTypeItem = ({
  userName,
  userType,
  profileImage,
  handleSwitchAccount,
}: AccountTypeItemProps) => {
  return (
    <AccountTypeItemWrapper
      onClick={() => handleSwitchAccount(userType)}
    >
      <div className="nav_p_d_profile">
        <img src={profileImage} alt="" />
      </div>
      <div>
        <div className="pro_name_drop_u">
          {userName}
        </div>
        <div className="pro_o_nme">{ucfirst(userType)}</div>
      </div>
    </AccountTypeItemWrapper>
  )
};

const WebMenuDropdown = ({
  headerData,
  handleLogout,
  handleSwitchAccount,
  handleOnlineStatus,
}: WebMenuDropdownProps) => {
  const navigate = useNavigate();
  const onlineStatusChange = useSelector(selectOnOnlineStatusChange);
  const isStatusChangeLoading = onlineStatusChange === "loading";
  const user = (headerData as HeaderData)?.active;
  const extraAccounts = (headerData as HeaderData)?.extra_accounts;

  let userRoute = `/freelancer-details/${user?.id}`;
  let userContactInfo = "/settings/contact-info";

  if (user?.role === "client") {
    userRoute = "/profile-setting";
    userContactInfo = "/profile-setting";
  } else if (user?.role === "agency") {
    userRoute = "/agency-profile";
  }

  return (
    <NavNodeWrapper className="nav_pro_node">
      <div className="nav_profile">
        <img src={user?.profile_image} alt="" />
      </div>
      <NavDropdown
        className="text-center nav_dropdown_profile custom_nav-profile_drp nenav_bddowniu b_job_l_sx profile_dropdown"
        id="basic-nav-dropdown"
        title=""
      >
        <ProfileImageContainer className="d-flex px-2">
          <div
            onClick={() => {
              navigate(userRoute || "/");
            }}
          >
            <img src={user?.profile_image} alt="" />
          </div>
          <ProfileNameContainer>
            <div className="pro_name_drop_u">
              {user?.name}
            </div>
            <div className="pro_o_nme">
              {ucfirst(user?.role)}
            </div>
          </ProfileNameContainer>
        </ProfileImageContainer>
        <div className="drop_p_o_i pb-3">
          <Button
            value={"online"}
            onClick={handleOnlineStatus}
            loading={isStatusChangeLoading}
            modifier={user?.online_status === "online" ? "primary-outline" : "outline-transparent"}
          >
            Online
          </Button>
          <Button
            value={"invisible"}
            onClick={handleOnlineStatus}
            loading={isStatusChangeLoading}
            modifier={user?.online_status === "invisible" ? "primary-outline" : "outline-transparent"}
          >
            Invisible
          </Button>
        </div>

        <>
          {extraAccounts?.map((item: any, index: number) => (
            <AccountTypeItem
              key={index}
              handleSwitchAccount={handleSwitchAccount}
              profileImage={item?.profile_image}
              userName={item?.name}
              userType={item?.role} />
          )
          )}
        </>

        <Link
          to={userContactInfo}
          className="drop_li_poi"
        >
          <i className="bi bi-gear font-size-20px"></i>
          <span>Settings</span>
        </Link>
        <div className="drop_li_poi" onClick={handleLogout}>
          <i className="bi bi-box-arrow-right font-size-20px"></i>
          <span>Log Out</span>
        </div>
      </NavDropdown>
    </NavNodeWrapper>
  );
};

export default WebMenuDropdown;


export const AccountTypeItemWrapper = styled.div`
  display: flex;
  padding: 1rem;
  transition: 0.2s;
  cursor: pointer;
  
  &:hover {
    background: #f1f1f1;
  }
`;