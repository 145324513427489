import ErrorPopup from "../../Freelancer/Components/Popups/ErrorPopup";
import { Axios } from "../Axios";
import {
  SET_BYO_HIRED_LIST,
  SET_BYO_HIRED_OFFER_LIST,
  SET_DELETE_BYO_HIRED,
  SET_TOOLTIP_DATA,
} from "../Types";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("unify_token")}`,
  },
};

export const sendByoTalentInvite =
  (data, navigate, setLoading, setErrorPopup) => async (dispatch) => {
    await Axios.post("/send-byo-talent-invite", data, config)
      .then((res) => {
        if (res.data.status) {
          navigate("/byo-talent");
          window.location.reload();
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (setErrorPopup) {
          setErrorPopup(
            <ErrorPopup
              popup={setErrorPopup}
              message={err.response.data.message}
            />
          );
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };

export const getByoHiredTalent = (setLoading) => async (dispatch) => {
  await Axios.get("/get-byo-talent", config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SET_BYO_HIRED_LIST,
          payload: res.data.data,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const deleteHiredTalent = (data, setLoading) => async (dispatch) => {
  await Axios.post("/delete-byo-talent", data, config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SET_DELETE_BYO_HIRED,
          payload: res.data,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getByoTalentOffer = (setLoading) => async (dispatch) => {
  await Axios.get("/get-byo-talent-offer", config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SET_BYO_HIRED_OFFER_LIST,
          payload: res.data.data,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getTooltipData = (setLoading) => async (dispatch) => {
  await Axios.get("/disable-tooltip", config)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SET_TOOLTIP_DATA,
          payload: res.data,
        });
        window.location.reload();
      }
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const setByoTalentDetails = () => async () => {

};