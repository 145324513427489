import { createSelector } from "reselect";
import { RootState } from "../Store";

const selectAgencyReducer = (state: RootState) => state.agency;

const selectEditAgencyDetails = createSelector(selectAgencyReducer, (data) => data.editAgencyDetails);
const selectGetAgencyWorkHistory = createSelector(selectAgencyReducer, (data) => data.getAgencyWorkHistory);
const selectGetAgencyProfile = createSelector(selectAgencyReducer, (data) => data.getAgencyProfile);
const selectAgencyTitleEdit = createSelector(selectAgencyReducer, (data) => data.agencyTitleEdit);
const selectAgencyPortfolioEdit = createSelector(selectAgencyReducer, (data) => data.agencyPortfolioEdit);
const selectAgencyPortfolioDeleted = createSelector(selectAgencyReducer, (data) => data.agencyPortfolioDeleted);
const selectAgencySkillEdit = createSelector(selectAgencyReducer, (data) => data.agencySkillEdit);
const selectAgencyTeamAdded = createSelector(selectAgencyReducer, (data) => data.agencyTeamAdded);
const selectAgencyTeamMemberAdded = createSelector(selectAgencyReducer, (data) => data.agencyTeamMemberAdded);
const selectAgencySizeEdit = createSelector(selectAgencyReducer, (data) => data.agencySizeEdit);
const selectEditAgencyFocus = createSelector(selectAgencyReducer, (data) => data.editAgencyFocus);
const selectAgencyServicesEdit = createSelector(selectAgencyReducer, (data) => data.editAgencyServices);
const selectAgencyTeamMemberDeleted = createSelector(selectAgencyReducer, (data) => data.agencyTeamMemberDeleted);
const selectEditAgencyFoundedYear = createSelector(selectAgencyReducer, (data) => data.editAgencyFoundedYear);
const selectAgencyLanguageEdit = createSelector(selectAgencyReducer, (data) => data.agencyLanguageEdit);
const selectAgencyTeamList = createSelector(selectAgencyReducer, (data) => data.agencyTeamList);

export {
  selectEditAgencyDetails,
  selectGetAgencyWorkHistory,
  selectGetAgencyProfile,
  selectAgencyTitleEdit,
  selectAgencyPortfolioEdit,
  selectAgencyPortfolioDeleted,
  selectAgencySkillEdit,
  selectAgencyTeamAdded,
  selectAgencyTeamMemberAdded,
  selectAgencySizeEdit,
  selectEditAgencyFocus,
  selectAgencyServicesEdit,
  selectAgencyTeamMemberDeleted,
  selectEditAgencyFoundedYear,
  selectAgencyLanguageEdit,
  selectAgencyTeamList
};