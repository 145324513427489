
export function ucfirst(input: string) {
  if (!input)
    return "";
  return input[0].toUpperCase() + input.slice(1);
}

export function isValidEmail(email: string) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export function empty(value: any) {
  return value === "" ||
    value === 0 ||
    value === null ||
    value === undefined ||
    value.length === 0
}

export function d(value: any, _default: any) {
  return empty(value) ? _default : value;
}